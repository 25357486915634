import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { FaAngleRight } from "react-icons/fa";
import { setActiveRadio, setRadioStations } from "../../../redux/actions";
import { Services } from "../../../services/Services";
import "./RadioSlider.css";

const RadioSlider = ({
  data,
  id,
  lang,
  history,
  setActiveRadio,
  setRadioStations,
}) => {
  useEffect(() => {
    if (localStorage.jwtToken) {
      Services.getRadios({ category: id }).then((res) =>
        setRadioStations(res.data.radios)
      );
    } else {
      Services.getDemoRadios({ category: id }).then((res) =>
        setRadioStations(res.data.radios)
      );
    }
  }, [id, setRadioStations]);

  const goToRadioPage = (item) => {
    setActiveRadio(item);
    history.push("/channels/radios");
  };

  return (
    <div className="radio_slider">
      <div className="row">
        <h2 className="title">{lang.home && lang.home.radio_slider.title}</h2>
        <Link to="/channels/radios">
          {lang.home && lang.home.radio_slider.btn}
          <FaAngleRight />
        </Link>
      </div>
      <div className="radio_slider_wrapper">
        {data.length > 0 &&
          data.slice(0, 6).map((item, index) => (
            <div
              key={index}
              className="radio_slider_item"
              onClick={() => goToRadioPage(item)}
            >
              <img src={item.stream_icon} alt={item.name} />
            </div>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.radioStations,
  id: state.radioCategoryID,
  lang: state.selectedLanguage,
});

export default connect(mapStateToProps, { setActiveRadio, setRadioStations })(
  withRouter(RadioSlider)
);
