import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaUser  } from "react-icons/fa";
// import { FiHelpCircle } from 'react-icons/fi'
import { MdPhone } from "react-icons/md";
import { IoIosLock , IoMdHelp } from "react-icons/io";
import { ReactComponent as IconSvg } from './icon.svg';
import './SettingsMenu.css';
import '../Settings.css';

const SettingsMenu = ({ lang }) => {
  const isActive = path => {
    if (window.location.pathname === `/settings/${path}`) return 'active_set_link';
  };

  return (
    <div className='settings_menu min_height'>
      <Link to='/settings/account' className={isActive('account')}>
        <span>
          <FaUser />
        </span>
        {lang.settings.submenu.account}
      </Link>
      <Link to='/settings/changepincode' className={isActive('changepincode')}>
        <span>
          <IoIosLock />
        </span>
        {lang.settings.submenu.parental}
      </Link>
      <Link to='/settings/contact' className={isActive('contact')}>
        <span>
          <MdPhone />
        </span>
        {lang.settings.submenu.contact}
      </Link>
      <Link to='/settings/languages' className={isActive('languages')}>
        <span className='lang_span'>
          <IconSvg />
        </span>
        {lang.settings.submenu.language}
      </Link>
      {/* <Link to='/settings/summary' className={isActive('summary')}>
        <span>
          <FaListUl />
        </span>
        {lang.settings.submenu.summary}
      </Link> */}
      <Link to='/settings/help' className={isActive('help')}>
        <span>
          <IoMdHelp />
        </span>
        {lang.settings.submenu.help}
      </Link>
  
     
     
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage
});

export default connect(mapStateToProps)(SettingsMenu);
