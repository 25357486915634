export const defaultSetup = (
  channels,
  setTVchannelActiveChannel,
  setActiveEpgs,
  activeItem,
  setActiveItem
) => {
  if (channels.length) {
    let isLocked = [];
    let notLocked = [];
    channels.forEach((channel) => {
      if (channel.locked) {
        isLocked.push(channel);
      } else {
        notLocked.push(channel);
      }
    });
    if (activeItem) {
      setTVchannelActiveChannel(activeItem);
    } else {
      if (notLocked.length || isLocked.length) {
        if (notLocked.length) {
          setTVchannelActiveChannel(notLocked[0]);
          setActiveItem(notLocked[0]);
        } else {
          setTVchannelActiveChannel([]);
          setActiveEpgs([]);
        }
      } else {
        // if (channels[0].epgs) {
        if (channels[0].current_epg) {
          setTVchannelActiveChannel(channels[0]);
          setActiveItem(channels[0]);
        } else {
          setTVchannelActiveChannel([]);
          setActiveEpgs([]);
        }
        // }
      }
    }
  }
};
