import React from 'react';

const Modal = ({ data, closeModal }) => {
  const bg = () => {
    if(data.icon !== null) return { background: `url(${data.icon})`};
    else return { background: '#222'};
  };

  return (
    <div className='info_popup_wrapper' onClick={closeModal}>
      <div className='info_popup_holder' onClick={e => e.stopPropagation()}>
      <div className='info_popup_img_holder' style={bg()}>
        {data.icon === null && <img src={data.logo} alt='' />}
        <div className='times_icon' onClick={closeModal} />
        <h2>{data.title}</h2>
      </div>
        <div className='info_popup_body'>
          <div className='info_popup_body_img_holder'>
            <p>{data.date}</p>
            <img src={data.logo} alt='' />
          </div>
          <div className='info_popup_text_btn'>
            <p>{data.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
