import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { Services } from "../../../services/Services";

// import { setGuidePosition } from "../../../redux/actions";

const ByCountryM3U8 = ({ language, countries, visible, toggle }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (countries.length) setActiveItem(countries[0]);
  }, [countries]);

  useEffect(() => {
    setActiveItem(countries[currentIndex]);
  }, [countries, currentIndex]);

  const fetchChannels = useCallback(() => {
    Services.m3u8FreeLists(activeItem.id).then((res) => {
      setResults(res.data);
      // setAdded(res.data.selected_channels_ids);
    });
  }, [activeItem]);

  useEffect(() => {
    if (activeItem) fetchChannels();
  }, [activeItem, fetchChannels]);

  const slideUp = (e) => {
    e.stopPropagation();
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
    else setCurrentIndex(countries.length - 1);
  };

  const slideDown = (e) => {
    e.stopPropagation();
    if (currentIndex < countries.length - 1) setCurrentIndex(currentIndex + 1);
    else setCurrentIndex(0);
  };

  const handleClick = (item, index) => {
    setActiveItem(item);
    setCurrentIndex(index);
    toggle(!visible);
  };

  const toggleClick = (item) => {
    let paramsUpload = { path: item.path, m3u_list_id: item.id };
    let paramsRemove = { list_id: item.id };
    if (item.is_choosen)
      Services.m3u8remove(paramsRemove).then(() => fetchChannels());
    else Services.m3u8upload(paramsUpload).then(() => fetchChannels());
  };

  // const addChannel = (id) => {
  //   let params = { channels_ids: [id] };
  //   if (!added.includes(id))
  //     Services.internetChannelsAdd(params).then(() => fetchChannels());
  // };

  // const deactiveGuideTootltips = () => {
  //   setGuidePosition(0);
  //   localStorage.setItem("showed_internettv_info", true);
  // };

  return (
    <>
      <label>{language.search_by_country_label}</label>
      <div className="select scroll">
        <div onClick={() => toggle(!visible)} className="top">
          {activeItem && activeItem.name}
          <div className="arrows">
            <IoMdArrowDropup onClick={slideUp} />
            <IoMdArrowDropdown onClick={slideDown} />
          </div>
        </div>
        {visible ? (
          <div className="options">
            {countries.length
              ? countries.map((item, index) => (
                  <p
                    key={index}
                    onClick={() => handleClick(item, index)}
                    className={item.id === activeItem.id ? "active" : ""}
                  >
                    {item.name}
                  </p>
                ))
              : null}
          </div>
        ) : null}
      </div>
      <div className="byName_items">
        {results && results.length ? (
          results.map((item, index) => (
            <div className="item" key={item.id}>
              {/* <div
                className="img"
                style={{ background: `url(${item.stream_icon})` }}
              /> */}
              <p title={item.name}>
                {item.name.length > 20
                  ? item.name.substring(0, 20) + "..."
                  : item.name}
              </p>

              <div className="checkbox" onClick={() => toggleClick(item)}>
                {/* {added.find((el) => el === item.id) ? <FaCheck /> : null} */}
                {item.is_choosen ? <FaCheck /> : null}
                {/* {index === 0 && guidePosition === 6 ? (
                  <div className="tooltip third">
                    <div className="triangle"></div>
                    <p>
                      {
                        language.guide.internet_channels.add_channels
                          .select_channel_info
                      }
                    </p>
                    <div className="buttons">
                      <button
                        className="skip"
                        onClick={() => deactiveGuideTootltips()}
                      >
                        {lang.guide.internet_channels.skip_btn}
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          addChannel(item.id);
                          setGuidePosition(7);
                        }}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                ) : null} */}
              </div>
            </div>
          ))
        ) : (
          <p>No Result!</p>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: state.countries,
    guidePosition: state.guidePosition,
    language: state.selectedLanguage,
  };
};

// const mapDispatchToProps = { setGuidePosition };

export default connect(mapStateToProps)(ByCountryM3U8);
