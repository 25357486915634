import React, { useState, useEffect, useCallback } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { FaRegStar, FaStar, FaAngleDown } from "react-icons/fa";
import { MdPlayCircleOutline, MdPauseCircleOutline } from "react-icons/md";
import Track from "..//Track/Track";
import RadioPlayer from "../../../components/RadioPlayer/RadioPlayer";
import {
  setActiveRadio,
  setFavoritesRadios,
  setRadioSliderData,
  setLoader,
  setRadioStations,
} from "../../../redux/actions";
import { Services } from "../../../services/Services";
import { radioFavorite } from "../../../helpers/Favorite";
import Expandimg from "../../../images/expand.png";
import "./Radio.css";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { setSelectedLanguage } from "../../../redux/actions";

const Radio = ({
  radios,
  favorites,
  home_radio,
  id,
  lang,
  setRadioStations,
  setActiveRadio,
  setRadioSliderData,
  setLoader,
  setFavoritesRadios,
}) => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState("");
  const [fullscreen, setFullscreen] = useState(true);
  const [activeItem, setActiveItem] = useState("");
  const [isHover, setIsHover] = useState(false);
  const [sort, setSort] = useState("");
  const [sortActive, setSortActive] = useState(0);
  const [sortStatus, setSortStatus] = useState(false);
  let body = document.querySelector("body");

  useEffect(() => {
    if (lg.length === 0 && !localStorage.jwtToken) {
      Services.getDemoLanguage().then((res) =>
        dispatch(setSelectedLanguage(res.data.language))
      );
    }
    // eslint-disable-next-line
  }, []);

  const lg = useSelector((state) => state.selectedLanguage);

  const fetchAPI = useCallback(
    (loader) => {
      if (localStorage.jwtToken) {
        Services.getRadios({ category: id }).then((res) => {
          // console.log(res.data.radios)
          const radiosSecured = res.data.radios.map((radio) => {
            if (radio.stream_icon.includes("http:")) {
              return {
                ...radio,
                stream_icon: radio.stream_icon.replace("http:", "https:"),
              };
            }
            return radio;
          });
          console.log(radiosSecured);
          setRadioStations(radiosSecured);
          if (loader) setLoader(false);
        });
        Services.getFavorites({ favorite: "" }).then((res) =>
          setFavoritesRadios(res.data.radios)
        );
      } else {
        Services.getDemoRadios({ category: id }).then((res) => {
          // console.log(res.data.radios)
          const radiosSecured = res.data.radios.map((radio) => {
            if (radio.stream_icon.includes("http:")) {
              return {
                ...radio,
                stream_icon: radio.stream_icon.replace("http:", "https:"),
              };
            }
            return radio;
          });
          console.log(radiosSecured);
          setRadioStations(radiosSecured);
          if (loader) setLoader(false);
        });
      }
    },
    [setRadioStations, setFavoritesRadios, setLoader, id]
  );

  useEffect(() => {
    fetchAPI();

    return () => {
      pause();
      setActiveRadio([]);
    };
  }, [fetchAPI, id, setActiveRadio]);

  useEffect(() => {
    setSort(lang.sort.placeholder);
    if (home_radio) play(home_radio);
  }, [lang, home_radio]);

  const play = (item) => {
    setActiveIndex(item.id);
    setActiveItem(item);
  };

  const pause = () => {
    setActiveIndex("");
    setActiveItem("");
  };

  const openFullScreen = (item) => {
    pause();
    setFullscreen(false);
    setActiveItem(item);
    setActiveIndex(item.id);
    body.style.overflow = "hidden";
    setRadioSliderData(radios);
  };

  const closeFullScreen = () => {
    setActiveIndex(activeIndex);
    setFullscreen(true);
    body.removeAttribute("style");
  };

  const toggleFavorite = (type, radio_id) =>
    radioFavorite(type, setLoader, fetchAPI, pause, radio_id, activeIndex);

  const setIndex = (id) => setActiveIndex(id);

  const setActive = (item) => setActiveItem(item);

  const closeDropdown = () => {
    if (sortStatus) setSortStatus(false);
  };

  const handleDropdown = (e, num) => {
    setSort(e.target.innerHTML);
    setSortActive(num);
    setSortStatus(false);
  };

  const classes = (id) => (sortActive === id ? "ligth_blue" : "");

  const content = localStorage.jwtToken ? (
    <div className="radio_component" onClick={closeDropdown}>
      <Track />
      <div className="radio_wrapper">
        <div className="container">
          <div className="row">
            <h2 className="title">
              {lang.channels.radio.title}
              <span>{radios.length}</span>
            </h2>
            <div
              className="dropdown"
              onClick={() => setSortStatus(!sortStatus)}
            >
              <p>{sort}</p>
              <FaAngleDown className={sortStatus ? "rotateArrow" : ""} />
              {sortStatus ? (
                <div className="dropdown_options">
                  <p
                    onClick={(e) => handleDropdown(e, 0)}
                    className={classes(0)}
                  >
                    {lang.sort.asc.name}
                  </p>
                  <p
                    onClick={(e) => handleDropdown(e, 1)}
                    className={classes(1)}
                  >
                    {lang.sort.desc.name}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="radio_stations">
            {radios.length && lang.sort ? (
              radios
                .sort(
                  sortActive === 0
                    ? (a, b) => b.name.localeCompare(a.name)
                    : (a, b) => a.name.localeCompare(b.name)
                )
                .map((item) => (
                  <div
                    className={
                      activeIndex === item.id
                        ? "radio_stations_item active_border"
                        : "radio_stations_item"
                    }
                    key={item.id}
                  >
                    <img
                      src={item.stream_icon}
                      alt={item.name}
                      className="radio_poster"
                    />
                    {activeIndex !== item.id ? (
                      <div
                        className="radio_station_item_abs"
                        onClick={() => play(item)}
                      >
                        <MdPlayCircleOutline className="radio_stations_item_play" />
                      </div>
                    ) : (
                      <div className="radio_station_item_abs" onClick={pause}>
                        {isHover ? (
                          <MdPauseCircleOutline
                            className="radio_stations_item_stop"
                            onMouseLeave={() => setIsHover(false)}
                          />
                        ) : (
                          <div
                            className="equalizer"
                            onMouseEnter={() => setIsHover(true)}
                          >
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                          </div>
                        )}
                      </div>
                    )}

                    <img
                      src={Expandimg}
                      alt=""
                      className="expandimg"
                      onClick={() => openFullScreen(item)}
                    />
                    {localStorage.jwtToken &&
                      (favorites.find((el) => el.id === item.id) ? (
                        <FaStar
                          className="radio_stations_item_favicon yellow"
                          onClick={() =>
                            toggleFavorite("removeFavorite", item.id)
                          }
                        />
                      ) : (
                        <FaRegStar
                          className="radio_stations_item_favicon"
                          onClick={() => toggleFavorite("addFavorite", item.id)}
                        />
                      ))}
                  </div>
                ))
            ) : (
              <div className="loading">
                <p>Don't have radio stations !</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <RadioPlayer
        isActive={fullscreen}
        data={activeItem}
        closeFullScreen={closeFullScreen}
        setIndex={setIndex}
        setActive={setActive}
      />
    </div>
  ) : (
    <>
      <div className="content">
        <Navbar />
        <div className="radio_component" onClick={closeDropdown}>
          <Track />
          <div className="radio_wrapper">
            <div className="container">
              <div className="row">
                <h2 className="title">
                  {lang.channels && lang.channels.radio.title}
                  <span>{radios.length}</span>
                </h2>
                <div
                  className="dropdown"
                  onClick={() => setSortStatus(!sortStatus)}
                >
                  <p>{sort}</p>
                  <FaAngleDown className={sortStatus ? "rotateArrow" : ""} />
                  {sortStatus ? (
                    <div className="dropdown_options">
                      <p
                        onClick={(e) => handleDropdown(e, 0)}
                        className={classes(0)}
                      >
                        {lang.sort.asc.name}
                      </p>
                      <p
                        onClick={(e) => handleDropdown(e, 1)}
                        className={classes(1)}
                      >
                        {lang.sort.desc.name}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="radio_stations">
                {radios.length && lang.sort ? (
                  radios
                    .sort(
                      sortActive === 0
                        ? (a, b) => b.name.localeCompare(a.name)
                        : (a, b) => a.name.localeCompare(b.name)
                    )
                    .map((item) => (
                      <div
                        className={
                          activeIndex === item.id
                            ? "radio_stations_item active_border"
                            : "radio_stations_item"
                        }
                        key={item.id}
                      >
                        <img
                          src={item.stream_icon}
                          alt={item.name}
                          className="radio_poster"
                        />
                        {activeIndex !== item.id ? (
                          <div
                            className="radio_station_item_abs"
                            onClick={() => play(item)}
                          >
                            <MdPlayCircleOutline className="radio_stations_item_play" />
                          </div>
                        ) : (
                          <div
                            className="radio_station_item_abs"
                            onClick={pause}
                          >
                            {isHover ? (
                              <MdPauseCircleOutline
                                className="radio_stations_item_stop"
                                onMouseLeave={() => setIsHover(false)}
                              />
                            ) : (
                              <div
                                className="equalizer"
                                onMouseEnter={() => setIsHover(true)}
                              >
                                <div />
                                <div />
                                <div />
                                <div />
                                <div />
                              </div>
                            )}
                          </div>
                        )}

                        <img
                          src={Expandimg}
                          alt=""
                          className="expandimg"
                          onClick={() => openFullScreen(item)}
                        />
                        {localStorage.jwtToken &&
                          (favorites.find((el) => el.id === item.id) ? (
                            <FaStar
                              className="radio_stations_item_favicon yellow"
                              onClick={() =>
                                toggleFavorite("removeFavorite", item.id)
                              }
                            />
                          ) : (
                            <FaRegStar
                              className="radio_stations_item_favicon"
                              onClick={() =>
                                toggleFavorite("addFavorite", item.id)
                              }
                            />
                          ))}
                      </div>
                    ))
                ) : (
                  <div className="loading">
                    <p>Don't have radio stations !</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <RadioPlayer
            isActive={fullscreen}
            data={activeItem}
            closeFullScreen={closeFullScreen}
            setIndex={setIndex}
            setActive={setActive}
          />
        </div>
        <Footer />
      </div>
    </>
  );

  return content;
};

const mapStateToProps = (state) => ({
  radios: state.radioStations,
  favorites: state.favoritesRadios,
  id: state.radioCategoryID,
  lang: state.selectedLanguage,
  home_radio: state.activeRadio,
});

export default connect(mapStateToProps, {
  setActiveRadio,
  setFavoritesRadios,
  setRadioSliderData,
  setRadioStations,
  setLoader,
})(Radio);
