// import dependencies
import React, { useState, useEffect } from "react";
import { FaTv, FaMicrophone, FaRss } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

// import services
import { Services } from "../../../../services/Services";

// import actions
import { setCategories, setRadioCategories } from "../../../../redux/actions";

// import assets
import arrowUpPrimary from "../../../../images/arrowUpPrimary.png";

// import css
import "./BottomNav.css";

const BottomNav = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const history = useHistory();
  const [categories, setCategoriesLocal] = useState([]);

  const changeCategory = (event) => {
    const value = event.target.value;
    props.setActiveCategory(value);
  };

  const lang = useSelector((state) => state.selectedLanguage);

  useEffect(() => {
    if (window.location.pathname === "/tv") {
      Services.getDemoCategories({
        filter: "",
        category: "",
        sort: "",
        favorite: "",
      }).then((res) => {
        dispatch(setCategories(res.data));
        const temp = [...res.data];
        temp.push({ id: -1, category_name: "All categories" });
        props.setActiveCategory(-1);
        setCategoriesLocal(temp);
      });
    } 
    if (window.location.pathname === "/radio") {
      Services.getDemoRadioCategories({
        category: "",
        favorite: "",
      }).then((res) => {
        dispatch(setRadioCategories(res.data.categories));
        setCategoriesLocal(res.data.categories);
        const temp = [...res.data.categories];
        temp.push({ id: -1, category_name: "All categories" });
        props.setActiveCategory(-1);
        setCategoriesLocal(temp);
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={props.hidden ? "Hidden" : "BottomNav"}>
      {window.location.pathname === "/tv" || window.location.pathname === "/radio"? (
        <div className="bottom-select-container">
          <div className="category-select-container">
            <select
              className="category-select"
              onChange={changeCategory}
              value={props.activeCategory}
            >
              {categories &&
                categories.map((elem) => (
                  <option key={elem.id} value={elem.id}>
                    {elem.category_name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      ) : (
        <div className="bottom-select-container" />
      )}

      <div className="tab-icons">
        <Link
          to="/tv"
          className={"icon" + (location.pathname === "/tv" ? " active" : "")}
        >
          <FaTv />
          <p className="tabName">{lang.menu && lang.menu.tv_channels}</p>
          {location.pathname === "/tv" && (
            <img src={arrowUpPrimary} alt="Arrow" />
          )}
        </Link>

        <Link
          to="/radio"
          className={"icon" + (location.pathname === "/radio" ? " active" : "")}
        >
          <FaMicrophone />
          <p className="tabName">{lang.menu && lang.menu.radio}</p>
          {location.pathname === "/radio" && (
            <img src={arrowUpPrimary} alt="Arrow" />
          )}
        </Link>

        <Link
          to="/news"
          className={"icon" + (location.pathname === "/news" ? " active" : "")}
        >
          <FaRss />
          <p className="tabName">{lang.menu && lang.menu.news}</p>
          {location.pathname === "/news" && (
            <img src={arrowUpPrimary} alt="Arrow" />
          )}
        </Link>

        <Link
          to="/more"
          className={
            "icon" + (location.pathname === "/more" ? " active" : "")
          }
        >
          <GiHamburgerMenu />
          <p className="tabName">More</p>
          {location.pathname === "/more" && (
            <img src={arrowUpPrimary} alt="Arrow" />
          )}
        </Link>
      </div>
    </div>
  );
};

export default BottomNav;
