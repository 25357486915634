import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { FaStar, FaLock } from "react-icons/fa";
import { makeGrid } from "../../helpers/makeGrid";
import { utility } from "../../helpers/Utility";
import { Services } from "../../services/Services";
import {
  setFavoritesChannels,
  setPlayerVisible,
  setPinCodeModal,
  setEpgs,
  setChannels,
} from "../../redux/actions";
import Track from "./Track";
import FavoIcon from "../../images/favorites.png";
import FavoritePopup from "../../views/FavoriteScreen/FavoritePopup";
import PlayImg from "../../images/play.png";
import "./ChannelsFavorites.css";
import "./Grid.css";

const moment = require("moment-timezone");
const timezone = moment.tz.guess();

const ChannelsFavorites = ({
  lang,
  channels,
  pincode,
  locked,
  setFavoritesChannels,
  setChannels,
  setPlayerVisible,
  setPinCodeModal,
  setEpgs,
}) => {
  const [infoPopup, setInfoPopup] = useState(null);
  const [lockedChannel, setLockedChannel] = useState(null);

  const removeFavorite = (e, channel) => {
    e.stopPropagation();
    let obj = {};
    if (channel.m3u_list_id) obj = { m3u_channel_id: channel.id };
    else obj = { channel_id: channel.id };
    Services.removeFavorite(obj).then(() => {
      Services.getFavorites({ favorite: "" }).then((res) =>
        setFavoritesChannels(res.data.channels)
      );
      let param = { filter: "", category: "", sort: "", favorite: "", epgs: 1 };
      Services.getChannels(param).then((res) => setChannels(res.data));
    });
  };

  const fetchEpgs = useCallback(
    (channel) => {
      Services.getEpgs({ channel_id: channel.id }).then((res) =>
        setEpgs(res.data)
      );
    },
    [setEpgs]
  );

  const openPlayer = useCallback(
    (channel) => {
      setPlayerVisible({ channels, channel, epg: channel.current_epg });
      fetchEpgs(channel);
    },
    [channels, fetchEpgs, setPlayerVisible]
  );

  useEffect(() => {
    if (pincode === "success" && infoPopup === null) openPlayer(lockedChannel);
  }, [pincode, openPlayer, lockedChannel, infoPopup]);

  const handleClick = (e, channel) => {
    e.stopPropagation();
    let now = moment.tz(new Date(), utility.timezone).tz(timezone).format();
    let limitTime = moment
      .tz(localStorage.is_locked_time, utility.timezone)
      .add(1, "hour")
      .tz(timezone)
      .format();
    if (localStorage.is_locked_time && limitTime < now) {
      localStorage.removeItem("is_locked_time");
      localStorage.setItem("all_locked", false);
      isLocked(channel);
    } else {
      if (localStorage.all_locked === "true") openPlayer(channel);
      else isLocked(channel);
    }
  };

  const isLocked = (channel) => {
    if (channel.locked) {
      setLockedChannel(channel);
      setPinCodeModal("opened");
    } else openPlayer(channel);
  };

  const openModal = (channel) => {
    fetchEpgs(channel);
    setInfoPopup({ channels, channel, epg: channel.current_epg });
  };

  useEffect(() => {
    Services.getFavorites({ favorite: "" }).then((res) =>
      setFavoritesChannels(res.data.channels)
    );
  }, [setFavoritesChannels]);

  const components = channels.map((channel, index) => (
    <div className="grid_item channel_grid_item" key={index}>
      <div className="grid_item_top" onClick={() => openModal(channel)}>
        <img src={PlayImg} alt="" onClick={(e) => handleClick(e, channel)} />
        <FaStar
          className="fa-star-fav"
          onClick={(e) => removeFavorite(e, channel)}
        />
        {locked && locked.find((el) => channel.category_id === el) && (
          <FaLock className="lock" />
        )}
      </div>
      <div className="grid_item_bottom channel_grid">
        <h2 className="epg_title">{channel.name}</h2>
        {channel.stream_icon && (
          <img src={channel.stream_icon} alt="" className="stream_icon" />
        )}
      </div>
    </div>
  ));

  return (
    <>
      <div className="min_height white favorites favorites_channels">
        <Track />
        <div className="container">
          <h2 className="title">
            {lang.favorites.channels.title}
            <span>{channels.length}</span>
          </h2>
          {channels.length > 0 ? (
            <div className="grid_items_wrapper">{makeGrid(components)}</div>
          ) : (
            <div className="nofavorites">
              <img src={FavoIcon} alt="favicon" />
              <p>{lang.favorites.no_tv_channels}</p>
            </div>
          )}
        </div>
      </div>
      {infoPopup && (
        <FavoritePopup data={infoPopup} close={() => setInfoPopup(false)} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  channels: state.favoritesChannels,
  locked: state.lockedCategories,
  pincode: state.pinCodeModal,
});

export default connect(mapStateToProps, {
  setFavoritesChannels,
  setPlayerVisible,
  setPinCodeModal,
  setEpgs,
  setChannels,
})(ChannelsFavorites);
