import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { Services } from "../../../services/Services";
import { setGuidePosition } from "../../../redux/actions";

const ByGenre = ({
  language,
  selectedLanguage,
  genres,
  visible,
  toggle,
  guidePosition,
  setGuidePosition,
}) => {
  const [activeItem, setActiveItem] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [results, setResults] = useState([]);
  const [added, setAdded] = useState([]);

  useEffect(() => {
    if (genres.length) setActiveItem(genres[0]);
  }, [genres]);

  useEffect(() => {
    setActiveItem(genres[currentIndex]);
  }, [genres, currentIndex]);

  const fetchChannels = useCallback(() => {
    Services.searchInternetChannels({ category_id: activeItem.id }).then(
      (res) => {
        setResults(res.data.channels);
        setAdded(res.data.selected_channels_ids);
      }
    );
  }, [activeItem]);

  useEffect(() => {
    if (activeItem) fetchChannels();
  }, [activeItem, fetchChannels]);

  const slideUp = (e) => {
    e.stopPropagation();
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
    else setCurrentIndex(genres.length - 1);
  };

  const slideDown = (e) => {
    e.stopPropagation();
    if (currentIndex < genres.length - 1) setCurrentIndex(currentIndex + 1);
    else setCurrentIndex(0);
  };

  const handleClick = (item, index) => {
    setActiveItem(item);
    setCurrentIndex(index);
    if (guidePosition === 5) {
      setGuidePosition(6);
    }
  };

  const toggleClick = (id) => {
    let params = { channels_ids: [id] };
    if (added.includes(id))
      Services.internetChannelsRemove(params).then(() => fetchChannels());
    else Services.internetChannelsAdd(params).then(() => fetchChannels());
  };

  const addChannel = (id) => {
    let params = { channels_ids: [id] };
    if (!added.includes(id))
      Services.internetChannelsAdd(params).then(() => fetchChannels());
  };

  const deactiveGuideTootltips = () => {
    setGuidePosition(0);
    localStorage.setItem("showed_internettv_info", true);
  };

  return (
    <>
      <label>{language.search_by_genre_label}</label>
      <div className="select scroll">
        <div onClick={() => toggle(!visible)} className="top">
          {activeItem && activeItem.category_name}
          <div className="arrows">
            <IoMdArrowDropup onClick={slideUp} />
            <IoMdArrowDropdown onClick={slideDown} />
          </div>
        </div>
        {visible ? (
          <div className="options">
            {genres.length
              ? genres.map((item, index) => (
                  <p
                    key={index}
                    onClick={() => handleClick(item, index)}
                    className={item.id === activeItem.id ? "active" : ""}
                  >
                    {item.category_name}
                  </p>
                ))
              : null}
          </div>
        ) : null}
      </div>
      <div className="byName_items">
        {results.length ? (
          results.map((item, index) => (
            <div className="item" key={item.id}>
              <div
                className="img"
                style={{ background: `url(${item.stream_icon})` }}
              />
              <p title={item.name}>
                {item.name.length > 20
                  ? item.name.substring(0, 20) + "..."
                  : item.name}
              </p>
              <div className="checkbox" onClick={() => toggleClick(item.id)}>
                {added.find((el) => el === item.id) ? <FaCheck /> : null}
                {index === 0 && guidePosition === 6 ? (
                  <div className="tooltip third">
                    <div className="triangle"></div>
                    <p>
                      {
                        selectedLanguage.guide.internet_channels.add_channels
                          .select_channel_info
                      }
                    </p>
                    <div className="buttons">
                      <button
                        className="skip"
                        onClick={() => deactiveGuideTootltips()}
                      >
                        {selectedLanguage.guide.internet_channels.skip_btn}
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          addChannel(item.id);
                          setGuidePosition(7);
                        }}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ))
        ) : (
          <p>No Result!</p>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, { setGuidePosition })(ByGenre);
