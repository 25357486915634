// import dependencies
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { connect, useSelector } from "react-redux";
import { FiSearch } from "react-icons/fi";
import { FaRegPlayCircle } from "react-icons/fa";

// import components
import Nav from "../../../../components/Unauthorized/Mobile/Nav/Nav";
import BottomNav from "../../../../components/Unauthorized/Mobile/BottomNav/BottomNav";

// import assets and constants
import arrowUpPrimary from "../../../../images/arrowUpPrimary.png";
import arrowDownPrimary from "../../../../images/arrowDownPrimary.png";

// import helpers
import { filterList } from "../../../../helpers/filterList";

// import css
import "./Radio.css";

const Radio = ({ radioStations }) => {
  // state
  const [searchText, setSearchText] = useState("");
  const [renderList, setRenderList] = useState(radioStations);
  const [playing, setPlaying] = useState(false);
  const [radioUrl, setRadioUrl] = useState("");
  const [activeRadio, setActiveRadio] = useState(null);
  const [activeCategory, setActiveCategory] = useState({});

  useEffect(() => {
    setRenderList(radioStations);
  }, [radioStations]);

  // handle methodes
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);

    const radioFilter = filterList(radioStations, value);
    setRenderList(radioFilter);
  };

  const playStopRadioStation = (item) => () => {
    if (activeRadio && item.id === activeRadio.id) {
      setActiveRadio(null);
      setRadioUrl("");
      setPlaying(false);
    } else {
      setActiveRadio(item);
      setRadioUrl(item.stream_source);
      setPlaying(true);
    }
  };

  const lang = useSelector((state) => state.selectedLanguage);

  return (
    <div className="Radio">
      <Nav />

      <div className="radio-container">
        {activeRadio ? (
          <ReactPlayer
            url={radioUrl}
            playing={playing}
            // onReady={this.successState}
            // onStart={this.onStart}
            // onPause={this.onPause}
            // volume={volume}
          />
        ) : null}
      </div>

      <div className="fixed">
        <div className="searchMobile">
          <input
            onChange={handleSearch}
            name="search"
            defaultValue={searchText}
            type="search"
            placeholder={lang.search && lang.search.placeholder}
          />
          <div className="searchBtn">
            <FiSearch color="#fff" />
          </div>
        </div>

        <div className="activeChannel radioChannel">
          <div className="radioInfo">
            {activeRadio ? (
              <>
                <img src={activeRadio.stream_icon} alt="channelInfo" />
                <p>{activeRadio.name}</p>
              </>
            ) : null}
          </div>

          {activeRadio ? <FaRegPlayCircle /> : null}
        </div>
      </div>

      <div className="arrowRadio up">
        <img src={arrowUpPrimary} className="arrowUp" alt="arrow" />
      </div>

      <div className="radioChannels">
        {renderList.map(
          (item, index) =>
            (item.category_id === parseInt(activeCategory) ||
              parseInt(activeCategory) === -1) && (
              <div
                key={index.toString()}
                onClick={playStopRadioStation(item)}
                className="radioChannel"
              >
                <div className="radioInfo">
                  <img src={item.stream_icon} alt="channelInfo" />
                  <p>{item.name}</p>
                </div>

                {activeRadio && activeRadio.name === item.name ? (
                  <FaRegPlayCircle />
                ) : null}
              </div>
            )
        )}
      </div>

      <div className="arrowRadio down">
        <img src={arrowDownPrimary} className="arrowDown" alt="arrow" />
      </div>

      <BottomNav
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  radioStations: state.radioStations,
});

export default connect(mapStateToProps)(Radio);
