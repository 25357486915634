import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaLock, FaRegStar, FaStar, FaTimes } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { Services } from "../../../services/Services";
import {
  // setInternetChannels,
  setFavoritesChannels,
  setPlayerVisible,
  setM3u8ActiveChannel,
  setEpgs,
  setChannels,
  setGuidePosition,
  setInternetTvPage,
  setInternetTvChannels,
  setPinCodeModal,
} from "../../../redux/actions";
import Track from "../Track/Track";
import "./InternetTvChannels.css";
import Loader from "../../../components/PaginationLoader/PaginationLoader";
import PlayerLoader from "../../../components/VideoPlayer/Player/Loader";

import { Link } from "react-router-dom";
import { paginationOnEndReach } from "../../../helpers/paginationOnEndReach";

let per_page = 50;

const InternetTvChannels = ({
  lang,
  // channels,
  activeChannel,
  favorite,
  // setInternetChannels,
  setEpgs,
  setChannels,
  setM3u8ActiveChannel,
  setFavoritesChannels,
  setPlayerVisible,
  guidePosition,
  setGuidePosition,
  setPinCodeModal,
  pincode,
}) => {
  const dispatch = useDispatch();

  const channels = useSelector((state) => state.internetTvChannels);
  const page = useSelector((state) => state.internettvPage);
  const wrapperRef = useRef();

  // to ignore first render when we already have channels fetched in store
  const [isMount, setIsMount] = useState(true);

  const [lastPage, setLastPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [firstLoader, setFirstLoader] = useState(false);

  const [localFavorites, setLocalFavorites] = useState(favorite);
  const [chosenChannel, setChosenChannel] = useState();
  const [addedCategories, setAddedCategories] = useState([]);

  const [searchedChannels, setSearchedChannels] = useState([]);
  const [searchActive, setSearchAcitve] = useState(false);

  useEffect(() => {
    if (guidePosition === 8 || guidePosition === 9) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [guidePosition]);

  const setInternetChannels = (data) => {
    dispatch(setInternetTvChannels(data));
  };

  const fetchAPI = () => {
    if (page === 1) {
      setFirstLoader(true);
    }
    setLoading(true);
    Services.m3u8channelsPagination(per_page, page).then((res) => {
      setInternetChannels(channels.concat(res.data.data));
      setLastPage(res.data.meta.last_page);
      setLoading(false);
      setFirstLoader(false);
    });
    Services.getFavorites({ favorite: "" }).then((res) => {
      setFavoritesChannels(favorite.concat(res.data.channels));
    });
  };

  const getMoreChannels = () => {
    if (page + 1 <= lastPage && !loading) {
      dispatch(setInternetTvPage(page + 1));
    }
  };

  useEffect(() => {
    if (channels.length === 0) {
      setIsMount(false);
    } else if (isMount) {
      setIsMount(false);
      return;
    }
    fetchAPI();
    // eslint-disable-next-line
  }, [page]);

  const handleSearch = (e) => {
    if (e.target.value.length > 2) {
      setSearchAcitve(true);
      const channelName = e.target.value.toLowerCase();
      Services.m3u8channelsSearch(channelName).then((res) => {
        setSearchedChannels(res.data.channels);
      });
    } else {
      setSearchAcitve(false);
    }
  };

  const addToFavorite = (e, item) => {
    e.stopPropagation();
    let obj = {};
    if (item.stream_path) {
      obj = { m3u_channel_id: item.id };
    } else {
      obj = { channel_id: item.stream_id };
    }
    Services.addFavorite(obj).then(() => {
      setLocalFavorites((p) => [...p, item]);
    });
  };

  const removeToFavorite = (e, item) => {
    e.stopPropagation();
    let obj = {};
    if (item.stream_path) {
      obj = { m3u_channel_id: item.id };
    } else {
      obj = { channel_id: item.stream_id };
    }
    Services.removeFavorite(obj).then(() => {
      const arr = localFavorites.filter((elem) => elem.id !== item.id);
      setLocalFavorites(arr);
    });
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    Services.internetChannelsRemove({ channels_ids: [id] }).then(() => {
      const arr = channels.filter((elem) => elem.id !== id);
      setInternetChannels(arr);
    });
  };

  const handleOpenCategories = (e, channel) => {
    e.stopPropagation();
    setChosenChannel(channel);
    setM3u8ActiveChannel(channel);
    Services.getChannelCategories(channel.id).then((res) => {
      setAddedCategories(res.data);
    });
  };

  const addCategory = (category_id) => {
    Services.addCategory({ channel_id: chosenChannel.id, category_id }).then(
      () => {
        Services.getChannelCategories(chosenChannel.id).then((res) => {
          setAddedCategories(res.data);
        });
      }
    );
  };

  const removeCategory = (category_id) => {
    Services.removeCategory({ channel_id: chosenChannel.id, category_id }).then(
      () => {
        Services.getChannelCategories(chosenChannel.id).then((res) => {
          setAddedCategories(res.data);
        });
      }
    );
  };

  const handleCategoryClick = (item) => {
    if (item.is_added) {
      removeCategory(item.id);
    } else {
      addCategory(item.id);
    }
  };

  const handleClick = (channel, play) => {
    const allLocked = localStorage.getItem("all_locked");
    if (channel.locked && !play && allLocked === "false") {
      setPinCodeModal("opened");
      setM3u8ActiveChannel(channel);
    } else {
      setEpgs([]);
      setPlayerVisible({ channels, channel, epg: null, fromInternetTv: true });
      setM3u8ActiveChannel(channel);
    }
  };

  useEffect(() => {
    if (pincode === "success") {
      handleClick(activeChannel, true);
      localStorage.setItem("all_locked", true);
    }
    // eslint-disable-next-line
  }, [pincode]);

  const activeClass = (item) =>
    activeChannel === item ? "item active" : "item";

  const deactiveGuideTootltips = () => {
    setGuidePosition(0);
    localStorage.setItem("showed_internettv_info", true);
  };

  const langauge = lang.internet_channels.submenu;

  return (
    <div className="internet_channels light_bg relative">
      <Track />
      {firstLoader ? (
        <PlayerLoader />
      ) : channels.length > 0 ? (
        <>
          <div className="flex internet_channels_holder">
            <div className="channels_menu wide">
              <div className="top_labels">
                <h3>{langauge.channels}</h3>
                <h3>{lang.internet_channels.channels.add_to_category}</h3>
              </div>
              <div className="channels_menu_holder">
                <div className="input_holder">
                  <input
                    type="text"
                    placeholder={lang.search.placeholder}
                    onChange={handleSearch}
                  />
                </div>
                <div
                  className="items"
                  style={{
                    overflowY:
                      guidePosition === 8 || guidePosition === 9
                        ? "visible"
                        : "scroll",
                    paddingBottom: 60,
                  }}
                  ref={wrapperRef}
                  onScroll={(e) =>
                    !searchActive && paginationOnEndReach(e, getMoreChannels)
                  }
                >
                  {searchActive
                    ? searchedChannels.map((item, index) => (
                        <div
                          key={item.id}
                          className={activeClass(item)}
                          onClick={() => handleClick(item)}
                        >
                          <div className="left">
                            <div className="channel_info">
                              {item.stream_icon ? (
                                <img src={item.stream_icon} alt="img" />
                              ) : null}
                              <p>
                                {item.name && item.name.length > 18
                                  ? item.name.substring(0, 18) + "..."
                                  : item.name}
                              </p>
                            </div>
                            <div className="channel_icons">
                              {item.locked && <FaLock className="lock" />}
                              {localFavorites.find(
                                (el) => el.id === item.id
                              ) ? (
                                <FaStar
                                  className="yellow"
                                  onClick={(e) => removeToFavorite(e, item)}
                                />
                              ) : (
                                <FaRegStar
                                  onClick={(e) => addToFavorite(e, item)}
                                />
                              )}
                            </div>
                          </div>
                          {index === 0 && guidePosition === 9 ? (
                            <div className="tooltip eight">
                              <div className="triangle"></div>
                              <p>
                                {
                                  lang.guide.internet_channels.channels
                                    .remove_info
                                }
                              </p>
                              <div className="buttons">
                                <button
                                  className="skip"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deactiveGuideTootltips();
                                  }}
                                >
                                  {lang.guide.internet_channels.skip_btn}
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    item.internet_channel === 1 &&
                                      handleDelete(e, item.id);
                                    setGuidePosition(10);
                                  }}
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                          ) : null}
                          {index === 0 && guidePosition === 8 ? (
                            <div className="tooltip seven">
                              <div className="triangle"></div>
                              <p>
                                {
                                  lang.guide.internet_channels.channels
                                    .favorite_info
                                }
                              </p>
                              <div className="buttons">
                                <button
                                  className="skip"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deactiveGuideTootltips();
                                  }}
                                >
                                  {lang.guide.internet_channels.skip_btn}
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      favorite.find((el) => el.id === item.id)
                                    ) {
                                      removeToFavorite(e, item);
                                    } else addToFavorite(e, item);
                                    setGuidePosition(9);
                                  }}
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                          ) : null}
                          <div className="right">
                            <div
                              className="add_to_category_btn"
                              onClick={(e) => handleOpenCategories(e, item)}
                            >
                              <MdAddCircle />
                            </div>
                            {item.internet_channel === 1 && (
                              <FaTimes
                                onClick={(e) => handleDelete(e, item.id)}
                                className="red"
                              />
                            )}
                          </div>
                        </div>
                      ))
                    : channels.map((item, index) => (
                        <div
                          key={item.id}
                          className={activeClass(item)}
                          onClick={() => handleClick(item)}
                        >
                          <div className="left">
                            <div className="channel_info">
                              {item.stream_icon ? (
                                <img src={item.stream_icon} alt="img" />
                              ) : null}
                              <p>
                                {item.name && item.name.length > 18
                                  ? item.name.substring(0, 18) + "..."
                                  : item.name}
                              </p>
                            </div>
                            <div className="channel_icons">
                              {item.locked && <FaLock className="lock" />}
                              {localFavorites.find(
                                (el) => el.id === item.id
                              ) ? (
                                <FaStar
                                  className="yellow"
                                  onClick={(e) => removeToFavorite(e, item)}
                                />
                              ) : (
                                <FaRegStar
                                  onClick={(e) => addToFavorite(e, item)}
                                />
                              )}
                            </div>
                          </div>
                          {index === 0 && guidePosition === 9 ? (
                            <div className="tooltip eight">
                              <div className="triangle"></div>
                              <p>
                                {
                                  lang.guide.internet_channels.channels
                                    .remove_info
                                }
                              </p>
                              <div className="buttons">
                                <button
                                  className="skip"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deactiveGuideTootltips();
                                  }}
                                >
                                  {lang.guide.internet_channels.skip_btn}
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    item.internet_channel === 1 &&
                                      handleDelete(e, item.id);
                                    setGuidePosition(10);
                                  }}
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                          ) : null}
                          {index === 0 && guidePosition === 8 ? (
                            <div className="tooltip seven">
                              <div className="triangle"></div>
                              <p>
                                {
                                  lang.guide.internet_channels.channels
                                    .favorite_info
                                }
                              </p>
                              <div className="buttons">
                                <button
                                  className="skip"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deactiveGuideTootltips();
                                  }}
                                >
                                  {lang.guide.internet_channels.skip_btn}
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      favorite.find((el) => el.id === item.id)
                                    ) {
                                      removeToFavorite(e, item);
                                    } else addToFavorite(e, item);
                                    setGuidePosition(9);
                                  }}
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                          ) : null}
                          <div className="right">
                            <div
                              className="add_to_category_btn"
                              onClick={(e) => handleOpenCategories(e, item)}
                            >
                              <MdAddCircle />
                            </div>
                            {item.internet_channel === 1 && (
                              <FaTimes
                                onClick={(e) => handleDelete(e, item.id)}
                                className="red"
                              />
                            )}
                          </div>
                        </div>
                      ))}
                  {loading && (
                    <div style={{ position: "relative", height: 60 }}>
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {chosenChannel && (
              <div className="add_to_category">
                <div className="category_top">
                  <img
                    src={chosenChannel.stream_icon}
                    alt="Channel"
                    className="channelIcon"
                  />
                  <FaTimes
                    onClick={() => {
                      setChosenChannel(null);
                      setM3u8ActiveChannel(null);
                    }}
                    className="red"
                  />
                </div>
                <div className="categories">
                  {addedCategories.map((item) => (
                    <div
                      className="single_category"
                      key={item.id}
                      onClick={() => handleCategoryClick(item)}
                    >
                      <img
                        src={
                          item.is_added
                            ? item.icon_src_favorite
                            : item.icon_src_active
                        }
                        alt="Category"
                      />
                      <p className={item.is_added ? "active" : ""}>
                        {item.category_name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="no_added_internet_channels">
          <AiOutlineFileExcel />
          <p>{lang.internet_channels.channels.no_channels_msg}</p>
          <Link to="/internetTV/addTVchannels">add a channel</Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  // channels: state.internetChannels,
  favorite: state.favoritesChannels,
  active_channel: state.m3u8ActiveChannel,
  activeChannel: state.m3u8ActiveChannel,
  guidePosition: state.guidePosition,
  pincode: state.pinCodeModal,
});

export default connect(mapStateToProps, {
  // setInternetChannels,
  setChannels,
  setEpgs,
  setM3u8ActiveChannel,
  setFavoritesChannels,
  setPlayerVisible,
  setGuidePosition,
  setPinCodeModal,
})(InternetTvChannels);
