import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMovies,
  setMoviesCategories,
  setLoader,
  setPlayerVisible,
} from "../../redux/actions";
import { Services } from "../../services/Services";
import MoviesTrack from "./MoviesTrack/MoviesTrack";
import SingleMovie from "./SingleMovie/SingleMovie";
import MoviesSection from "../../components/MoviesSection/MoviesSection";

let per_page = 48;

const Movies = () => {
  const dispatch = useDispatch();
  const [chosenCategory, setChosenCategory] = useState();
  const lang = useSelector((state) => state.selectedLanguage);
  const [sortValue, setSortValue] = useState(
    lang.sort && lang.sort.placeholder
  );
  const [sortNum, setSortNum] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const movies = useSelector((state) => state.movies);

  const [result, setResult] = useState([]);
  const [chosenMovie, setChosenMovie] = useState();

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(10);

  useEffect(() => {
    Services.getMoviesCategories()
      .then((res) => {
        dispatch(setMoviesCategories(res.data));
      })
      .catch((err) => console.log("ERR MOVIES CATEGORIES", err));
    // eslint-disable-next-line
  }, []);

  const handleCategoryChange = (item) => {
    setChosenCategory(item);
    setPage(1);
  };
  const handleReset = () => {
    setChosenCategory(null);
    setPage(1);
  };

  useEffect(() => {
    fetchAPI();
    // eslint-disable-next-line
  }, [page, sortNum, chosenCategory]);

  const fetchAPI = () => {
    dispatch(setLoader(true));
    setSearchValue("");
    Services.getMoviesPagination(
      per_page,
      page,
      chosenCategory ? chosenCategory.id : "",
      sortNum
    )
      .then((res) => {
        dispatch(
          setMovies(page === 1 ? res.data.data : movies.concat(res.data.data))
        );
        setResult(page === 1 ? res.data.data : movies.concat(res.data.data));
        setLastPage(res.data.meta.last_page);
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log("GET MOVIES ERR", err.response);
        dispatch(setLoader(false));
      });
  };

  const getMoreMovies = () => {
    if (page + 1 <= lastPage) {
      setPage((p) => p + 1);
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length === 0) {
      setResult(movies);
    } else {
      const filteredMovies = movies.filter((elem) =>
        elem.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setResult(filteredMovies);
    }
  };

  const sortMovies = (val) => {
    setPage(1);
    if (val === -1 && sortValue !== lang.sort.placeholder) {
      setSortNum("");
      setSortValue(lang.sort.placeholder);
    } else if (val === 0 && sortValue !== lang.sort.asc.name) {
      setSortNum(0);
      setSortValue(lang.sort.asc.name);
    } else if (val === 1 && sortValue !== lang.sort.desc.name) {
      setSortNum(1);
      setSortValue(lang.sort.desc.name);
    }
  };

  const handleClick = (src, poster) => {
    const channel = {};
    channel.tutorial = src;
    channel.stream_icon = poster;
    channel.is_movie = true;
    const channels = [];
    const epgs = [];
    dispatch(setPlayerVisible({ channel, channels, epgs }));
  };

  return (
    <div className="Movies">
      {chosenMovie && (
        <SingleMovie
          item={chosenMovie}
          close={() => setChosenMovie(null)}
          handleClick={handleClick}
          watchedLabel={lang?.movies?.watched_label}
          favLabel={lang?.internet_channels?.channels.favorite}
          addToFavLabel={lang?.internet_channels?.channels.add_to_favorite}
          movies={movies}
        />
      )}
      <MoviesTrack
        chosenCategory={chosenCategory}
        handleCategoryChange={handleCategoryChange}
        handleReset={handleReset}
        sortValue={sortValue}
        setSortValue={setSortValue}
        handleSearch={handleSearch}
        searchValue={searchValue}
        sortMovies={sortMovies}
      />
      <MoviesSection
        handleClick={handleClick}
        setChosenMovie={setChosenMovie}
        result={result}
      />
      {page + 1 <= lastPage && (
        <div className="get_more_movies">
          <button onClick={getMoreMovies}>{lang?.movies?.see_more_btn}</button>
        </div>
      )}
    </div>
  );
};

export default Movies;
