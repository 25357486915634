// import dependencies
import React, { useState, useEffect } from "react";

// import services
import { Services } from "../../../services/Services";

// import css
import "./AdBanner.css";

// AdBanner
const AdBanner = () => {
  // state
  const [ads, setAds] = useState([]);
  const [adIndex, setAdIndex] = useState(0);

  useEffect(() => {
    if (!localStorage.jwtToken) {
      Services.getAds().then((res) => {
        if (res.data && res.data.images) {
          setAds(res.data.images);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (ads.length > 1) {
      const interval = setInterval(() => {
        const temp = adIndex + 1;
        setAdIndex(temp % ads.length);
      }, 8000);

      return () => clearInterval(interval);
    }
  }, [ads, adIndex]);

  return (
    <>
      {!localStorage.jwtToken && ads.length > 0 ? (
        <img src={ads[adIndex].url} className="AdImage" alt="Ad" />
      ) : null}
    </>
  );
};

export default AdBanner;