import { combineReducers } from "redux";

// Import action types
import {
  SET_LOADER,
  SET_RADIOS_STATIONS,
  SET_CHANNELS,
  SET_EPGS,
  SET_TODAY_EPGS,
  SET_PREV_EPGS,
  SET_NEXT_EPGS,
  SET_FAVORITES_CHANNELS,
  SET_FAVORITES_RADIOS,
  SET_ACTIVE_RADIO,
  SET_SEARCH_VALUE,
  SET_RADIO_CATEGORIES,
  SET_CATEGORIES,
  SET_ALL_LANGUAGES,
  SET_SELECTED_LANGUAGE,
  SET_SEARCHED_EPGS,
  SET_CHANNELS_PARAMS,
  SET_INTERNET_CHANNELS,
  SET_M3U8_LISTS,
  SET_CATEGORY_NAME,
  SET_CATEGORY_RADIO_NAME,
  SET_SORT_EPGS,
  SET_PREV_3_HOURS,
  SET_NEXT_3_HOURS,
  SET_ACTIVE_STREAM,
  SET_IS_LOCKED,
  SET_NET_CHANNELS,
  SET_CATCH_UP_STREAM,
  SET_LEFT_SLIDER_DATA,
  SET_LEFT_SLIDER_ACTIVE_CHANNEL,
  SET_BOTTOM_SLIDER_ACTIVE_EPG,
  SET_BOTTOM_SLIDER_DATA,
  SET_BOTTOM_SLIDER_ACTIVE_INDEX,
  SET_SELECTED_CHANNELS,
  SET_TV_CHANNELS_ACTIVE_CHANNEL,
  SET_INTERNET_TV_ACTIVE_CHANNEL,
  SET_M3U8_ACTIVE_CHANNEL,
  SET_CAROUSEL_DATA,
  SET_RADIO_SLIDER_DATA,
  SET_RADIO_CATEGORY_ID,
  SET_SOMETHING,
  LOCKED_CATEGORIES,
  PIN_CODE,
  SET_INFO_POPUP,
  SET_PIN_CODE_MODAL,
  SET_LEFT_SLIDER_PIN,
  SET_DOBULE_CLICK_MENU,
  SET_PLAYER_EXIT,
  SET_COUNTRIES_FILTERS,
  SET_GENRES_FILTERS,
  DROPDOWNS_VISIBLE,
  INFO_MODAL,
  PLAYER_VISIBLE,
  MENU_SELECTED_CHANNEL,
  ACTIVE_CHANNEL,
  NEWS_FEED,
  SET_NEWS_ITEM,
  CHANGE_INFO_SHOW,
  SET_GUIDE_POSITION,
  SET_MODAL_VISIBLE,
  SET_LANG_MODAL_VISIBLE,
  SET_ADS,
  SET_TOC,
  SET_INTERNETTV_PAGE,
  SET_INTERNETTV_CHANNELS,
  SET_CHANNELS_COUNTRIES,
  SET_MOVIES,
  SET_MOVIES_CATEGORIES,
  SET_FAVORITE_MOVIES,
  SET_ADDED_MOVIES,
  SET_ADDED_MOVIES_PAGE,
} from "./actions";

// Write functions
function loader(state = true, action) {
  switch (action.type) {
    case SET_LOADER:
      return action.payload;
    default:
      return state;
  }
}

function searchValue(state = "", action) {
  switch (action.type) {
    case SET_SEARCH_VALUE:
      return action.value;
    default:
      return state;
  }
}

function radioStations(state = [], action) {
  switch (action.type) {
    case SET_RADIOS_STATIONS:
      return action.stations;
    default:
      return state;
  }
}

function channels(state = [], action) {
  switch (action.type) {
    case SET_CHANNELS:
      return action.channels;
    default:
      return state;
  }
}

function epgs(state = [], action) {
  switch (action.type) {
    case SET_EPGS:
      return action.epg;
    default:
      return state;
  }
}

function todayEpgs(state = [], action) {
  switch (action.type) {
    case SET_TODAY_EPGS:
      return action.epg;
    default:
      return state;
  }
}

function prevEpgs(state = [], action) {
  switch (action.type) {
    case SET_PREV_EPGS:
      return action.epg;
    default:
      return state;
  }
}

function nextEpgs(state = [], action) {
  switch (action.type) {
    case SET_NEXT_EPGS:
      return action.epg;
    default:
      return state;
  }
}

function favoritesChannels(state = [], action) {
  switch (action.type) {
    case SET_FAVORITES_CHANNELS:
      return action.favorite;
    default:
      return state;
  }
}

function favoritesRadios(state = [], action) {
  switch (action.type) {
    case SET_FAVORITES_RADIOS:
      return action.favorite;
    default:
      return state;
  }
}

function activeRadio(state = [], action) {
  switch (action.type) {
    case SET_ACTIVE_RADIO:
      return action.item;
    default:
      return state;
  }
}

function categories(state = [], action) {
  switch (action.type) {
    case SET_CATEGORIES:
      return action.category;
    default:
      return state;
  }
}

function radioCategories(state = [], action) {
  switch (action.type) {
    case SET_RADIO_CATEGORIES:
      return action.category;
    default:
      return state;
  }
}

function allLanguages(state = [], action) {
  switch (action.type) {
    case SET_ALL_LANGUAGES:
      return action.languages;
    default:
      return state;
  }
}

function selectedLanguage(state = [], action) {
  switch (action.type) {
    case SET_SELECTED_LANGUAGE:
      return action.lang;
    default:
      return state;
  }
}

function searchedEpgs(state = [], action) {
  switch (action.type) {
    case SET_SEARCHED_EPGS:
      return action.term;
    default:
      return state;
  }
}

function internetChannels(state = [], action) {
  switch (action.type) {
    case SET_INTERNET_CHANNELS:
      return action.channel;
    default:
      return state;
  }
}

function m3u8Lists(state = [], action) {
  switch (action.type) {
    case SET_M3U8_LISTS:
      return action.list;
    default:
      return state;
  }
}

function categoryName(state = "All", action) {
  switch (action.type) {
    case SET_CATEGORY_NAME:
      return action.name;
    default:
      return state;
  }
}

function categoryRadioName(state = "All", action) {
  switch (action.type) {
    case SET_CATEGORY_RADIO_NAME:
      return action.name;
    default:
      return state;
  }
}

function channelsParams(
  state = {
    filter: "",
    category: "",
    sort: "",
    favorite: "",
    epgs: 1,
  },
  action
) {
  switch (action.type) {
    case SET_CHANNELS_PARAMS:
      return action.params;
    default:
      return state;
  }
}

function sorteredEpgs(state = [], action) {
  switch (action.type) {
    case SET_SORT_EPGS:
      return action.epg;
    default:
      return state;
  }
}

function prev3Hours(state = [], action) {
  switch (action.type) {
    case SET_PREV_3_HOURS:
      return action.epg;
    default:
      return state;
  }
}

function next3Hours(state = [], action) {
  switch (action.type) {
    case SET_NEXT_3_HOURS:
      return action.epg;
    default:
      return state;
  }
}

function activeStream(state = "", action) {
  switch (action.type) {
    case SET_ACTIVE_STREAM:
      return action.stream;
    default:
      return state;
  }
}

function isLocked(state = true, action) {
  switch (action.type) {
    case SET_IS_LOCKED:
      return action.lock;
    default:
      return state;
  }
}

function netChannels(state = [], action) {
  switch (action.type) {
    case SET_NET_CHANNELS:
      return action.channel;
    default:
      return state;
  }
}

function catchUpStream(state = "", action) {
  switch (action.type) {
    case SET_CATCH_UP_STREAM:
      return action.stream;
    default:
      return state;
  }
}

function leftSliderData(state = [], action) {
  switch (action.type) {
    case SET_LEFT_SLIDER_DATA:
      return action.data;
    default:
      return state;
  }
}

function leftSliderActiveChannel(state = [], action) {
  switch (action.type) {
    case SET_LEFT_SLIDER_ACTIVE_CHANNEL:
      return action.data;
    default:
      return state;
  }
}

function bottomSliderActiveEpg(state = [], action) {
  switch (action.type) {
    case SET_BOTTOM_SLIDER_ACTIVE_EPG:
      return action.data;
    default:
      return state;
  }
}

function bottomSliderData(state = [], action) {
  switch (action.type) {
    case SET_BOTTOM_SLIDER_DATA:
      return action.data;
    default:
      return state;
  }
}

function bottomSliderActiveIndex(state = 0, action) {
  switch (action.type) {
    case SET_BOTTOM_SLIDER_ACTIVE_INDEX:
      return action.data;
    default:
      return state;
  }
}

function selectedChannel(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_CHANNELS:
      return action.channel;
    default:
      return state;
  }
}

function tvChannelsActiveChannel(state = [], action) {
  switch (action.type) {
    case SET_TV_CHANNELS_ACTIVE_CHANNEL:
      return action.channel;
    default:
      return state;
  }
}

function internetTVactiveChannel(state = [], action) {
  switch (action.type) {
    case SET_INTERNET_TV_ACTIVE_CHANNEL:
      return action.channel;
    default:
      return state;
  }
}

function m3u8ActiveChannel(state = [], action) {
  switch (action.type) {
    case SET_M3U8_ACTIVE_CHANNEL:
      return action.channel;
    default:
      return state;
  }
}

function carouselData(state = [], action) {
  switch (action.type) {
    case SET_CAROUSEL_DATA:
      return action.data;
    default:
      return state;
  }
}

function radioSliderData(state = [], action) {
  switch (action.type) {
    case SET_RADIO_SLIDER_DATA:
      return action.payload;
    default:
      return state;
  }
}

function radioCategoryID(state = "", action) {
  switch (action.type) {
    case SET_RADIO_CATEGORY_ID:
      return action.id;
    default:
      return state;
  }
}

function something(state = "", action) {
  switch (action.type) {
    case SET_SOMETHING:
      return action.payload;
    default:
      return state;
  }
}

function lockedCategories(state = [], action) {
  switch (action.type) {
    case LOCKED_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
}

function pincode(state = "", action) {
  switch (action.type) {
    case PIN_CODE:
      return action.payload;
    default:
      return state;
  }
}

function infoPopupStatus(state = null, action) {
  switch (action.type) {
    case SET_INFO_POPUP:
      return action.payload;
    default:
      return state;
  }
}

function pinCodeModal(state = null, action) {
  switch (action.type) {
    case SET_PIN_CODE_MODAL:
      return action.payload;
    default:
      return state;
  }
}

function leftSliderPin(state = null, action) {
  switch (action.type) {
    case SET_LEFT_SLIDER_PIN:
      return action.payload;
    default:
      return state;
  }
}

function dobuleClickMenu(state = false, action) {
  switch (action.type) {
    case SET_DOBULE_CLICK_MENU:
      return action.payload;
    default:
      return state;
  }
}

function playerExit(state = false, action) {
  switch (action.type) {
    case SET_PLAYER_EXIT:
      return action.payload;
    default:
      return state;
  }
}

function countries(state = [], action) {
  switch (action.type) {
    case SET_COUNTRIES_FILTERS:
      return action.payload;
    default:
      return state;
  }
}

function genres(state = [], action) {
  switch (action.type) {
    case SET_GENRES_FILTERS:
      return action.payload;
    default:
      return state;
  }
}

function info_modal(state = null, action) {
  switch (action.type) {
    case INFO_MODAL:
      return action.payload;
    default:
      return state;
  }
}

function playerVisible(state = null, action) {
  switch (action.type) {
    case PLAYER_VISIBLE:
      return action.payload;
    default:
      return state;
  }
}

function dropdowns_visible(state = { select: false, sort: false }, action) {
  switch (action.type) {
    case DROPDOWNS_VISIBLE:
      return action.payload;
    default:
      return state;
  }
}

function menuSelectedChannel(state = null, action) {
  switch (action.type) {
    case MENU_SELECTED_CHANNEL:
      return action.payload;
    default:
      return state;
  }
}

function activeChannel(state = null, action) {
  switch (action.type) {
    case ACTIVE_CHANNEL:
      return action.payload;
    default:
      return state;
  }
}

function newsFeed(state = [], action) {
  switch (action.type) {
    case NEWS_FEED:
      return action.payload;
    default:
      return state;
  }
}

function newsItem(state = null, action) {
  switch (action.type) {
    case SET_NEWS_ITEM:
      return action.payload;
    default:
      return state;
  }
}

function infoShow(state = localStorage.getItem("show_info_box"), action) {
  switch (action.type) {
    case CHANGE_INFO_SHOW:
      return action.payload;
    default:
      return state;
  }
}

function guidePosition(state = 1, action) {
  switch (action.type) {
    case SET_GUIDE_POSITION:
      return action.payload;
    default:
      return state;
  }
}

function modalVisible(state = null, action) {
  switch (action.type) {
    case SET_MODAL_VISIBLE:
      return action.payload;
    default:
      return state;
  }
}

function langModalVisible(state = false, action) {
  switch (action.type) {
    case SET_LANG_MODAL_VISIBLE:
      return action.payload;
    default:
      return state;
  }
}

function ads(state = [], action) {
  switch (action.type) {
    case SET_ADS:
      return action.payload;
    default:
      return state;
  }
}

function toc(state = [], action) {
  switch (action.type) {
    case SET_TOC:
      return action.payload;
    default:
      return state;
  }
}

function internettvPage(state = 1, action) {
  switch (action.type) {
    case SET_INTERNETTV_PAGE:
      return action.payload;
    default:
      return state;
  }
}

function internetTvChannels(state = [], action) {
  switch (action.type) {
    case SET_INTERNETTV_CHANNELS:
      return action.payload;
    default:
      return state;
  }
}

function channelsCountries(state = [], action) {
  switch (action.type) {
    case SET_CHANNELS_COUNTRIES:
      return action.payload;
    default:
      return state;
  }
}

function movies(state = [], action) {
  switch (action.type) {
    case SET_MOVIES:
      return action.payload;
    default:
      return state;
  }
}

function moviesCategories(state = [], action) {
  switch (action.type) {
    case SET_MOVIES_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
}

function moviesFavorites(state = [], action) {
  switch (action.type) {
    case SET_FAVORITE_MOVIES:
      return action.payload;
    default:
      return state;
  }
}

function addedMovies(state = [], action) {
  switch (action.type) {
    case SET_ADDED_MOVIES:
      return action.payload;
    default:
      return state;
  }
}

function addedMoviesPage(state = 1, action) {
  switch (action.type) {
    case SET_ADDED_MOVIES_PAGE:
      return action.payload;
    default:
      return state;
  }
}

// Combine Reducers

const rootReducer = combineReducers({
  infoPopupStatus,
  radioStations,
  channels,
  activeChannel,
  dropdowns_visible,
  playerVisible,
  epgs,
  todayEpgs,
  prevEpgs,
  info_modal,
  nextEpgs,
  countries,
  genres,
  favoritesChannels,
  favoritesRadios,
  newsFeed,
  newsItem,
  activeRadio,
  searchValue,
  categories,
  radioCategories,
  allLanguages,
  selectedLanguage,
  searchedEpgs,
  channelsParams,
  internetChannels,
  menuSelectedChannel,
  m3u8Lists,
  categoryName,
  categoryRadioName,
  sorteredEpgs,
  prev3Hours,
  next3Hours,
  activeStream,
  isLocked,
  netChannels,
  catchUpStream,
  leftSliderData,
  leftSliderActiveChannel,
  bottomSliderActiveEpg,
  bottomSliderData,
  bottomSliderActiveIndex,
  selectedChannel,
  tvChannelsActiveChannel,
  internetTVactiveChannel,
  m3u8ActiveChannel,
  carouselData,
  radioSliderData,
  radioCategoryID,
  something,
  lockedCategories,
  pincode,
  pinCodeModal,
  leftSliderPin,
  dobuleClickMenu,
  playerExit,
  loader,
  infoShow,
  guidePosition,
  modalVisible,
  langModalVisible,
  ads,
  toc,
  internettvPage,
  internetTvChannels,
  channelsCountries,
  movies,
  moviesCategories,
  moviesFavorites,
  addedMovies,
  addedMoviesPage,
});

export default rootReducer;
