import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import "./AddTVchannels.css";
import { Services } from "../../../services/Services";
import {
  setCountries,
  setGenres,
  setGuidePosition,
} from "../../../redux/actions";
import Track from "../Track/Track";
import BySearch from "./BySearch";
import ByGenre from "./ByGenre";
import ByCountry from "./ByCountry";

const AddTVchannels = ({
  guidePosition,
  selectedLanguage,
  setCountries,
  setGenres,
  setGuidePosition,
}) => {
  const [language, setLanguage] = useState(null);
  const [selectVisible, setSelectVisible] = useState(false);
  const [selectVisibleCountry, setSelectVisibleCountry] = useState(false);
  const [selectVisibleGenre, setSelectVisibleGenre] = useState(false);
  const [items, setItems] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let lang = selectedLanguage.internet_channels;
    if (lang && lang !== null && lang.add_channels) {
      setLanguage(lang.add_channels);
      setActiveItem({ id: 1, name: lang.add_channels.country_option });
      setItems([
        { id: 1, name: lang.add_channels.country_option },
        { id: 2, name: lang.add_channels.genre_option },
        { id: 3, name: lang.add_channels.name_option },
      ]);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    setActiveItem(items[currentIndex]);
  }, [items, currentIndex]);

  useEffect(() => {
    Services.getInternetChannelsFilters().then((res) => {
      setCountries(res.data.countries);
      setGenres(res.data.categories);
    });
  }, [setCountries, setGenres]);

  useEffect(() => {
    if (guidePosition === 5) {
      setSelectVisibleCountry(true);
      setSelectVisibleGenre(true);
    }
  }, [guidePosition]);

  const renderComponents = () => {
    if (language && activeItem) {
      if (activeItem.name === language.name_option) {
        return (
          <React.Fragment>
            <BySearch language={language} />
            {guidePosition === 5 ? (
              <div className="tooltip six-third">
                <div className="triangle"></div>
                <p>
                  {
                    selectedLanguage.guide.internet_channels.add_channels
                      .by_name_info
                  }
                </p>
                <div className="buttons">
                  <button
                    className="skip"
                    onClick={() => deactiveGuideTootltips()}
                  >
                    {selectedLanguage.guide.internet_channels.skip_btn}
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setGuidePosition(6);
                    }}
                  >
                    Pretrazi
                  </button>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        );
      } else if (activeItem.name === language.genre_option) {
        return (
          <React.Fragment>
            <ByGenre
              language={language}
              visible={selectVisibleGenre}
              toggle={setSelectVisibleGenre}
            />
            {guidePosition === 5 ? (
              <div className="tooltip six-second">
                <div className="triangle"></div>
                <p>
                  {
                    selectedLanguage.guide.internet_channels.add_channels
                      .by_genre_info
                  }
                </p>
                <div className="buttons">
                  <button
                    className="skip"
                    onClick={() => deactiveGuideTootltips()}
                  >
                    {selectedLanguage.guide.internet_channels.skip_btn}
                  </button>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <ByCountry
              language={language}
              visible={selectVisibleCountry}
              toggle={setSelectVisibleCountry}
            />
            {guidePosition === 5 ? (
              <div className="tooltip six">
                <div className="triangle"></div>
                <p>
                  {
                    selectedLanguage.guide.internet_channels.add_channels
                      .by_country_info
                  }
                </p>
                <div className="buttons">
                  <button
                    className="skip"
                    onClick={() => deactiveGuideTootltips()}
                  >
                    {selectedLanguage.guide.internet_channels.skip_btn}
                  </button>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        );
      }
    }
  };

  const slideUp = (e) => {
    e.stopPropagation();
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
    else setCurrentIndex(items.length - 1);
  };

  const slideDown = (e) => {
    e.stopPropagation();
    if (currentIndex < items.length - 1) setCurrentIndex(currentIndex + 1);
    else setCurrentIndex(0);
  };

  const handleClick = (item, index) => {
    setActiveItem(item);
    setCurrentIndex(index);
    if (guidePosition === 4) {
      setGuidePosition(5);
    }
  };

  const closeDropdown = () => {
    if (selectVisible) setSelectVisible(false);
    if (selectVisibleCountry) setSelectVisibleCountry(false);
    if (selectVisibleGenre) setSelectVisibleGenre(false);
  };

  const deactiveGuideTootltips = () => {
    setGuidePosition(0);
    localStorage.setItem("showed_internettv_info", true);
  };

  return (
    <div className="addTVchannels_wrapper">
      <Track />
      <div className="addTVchannels_holder" onClick={closeDropdown}>
        <h2>{language && language.heading}</h2>
        <div className="input_field">
          <label>{language && language.search_by_label}</label>
          <div className="select">
            <div
              onClick={() => setSelectVisible(!selectVisible)}
              className="top"
            >
              {activeItem && activeItem.name}
              <div className="arrows">
                <IoMdArrowDropup onClick={slideUp} />
                <IoMdArrowDropdown onClick={slideDown} />
              </div>
            </div>
            {selectVisible ? (
              <div className="options">
                {items.length
                  ? items.map((item, index) => (
                      <React.Fragment>
                        <p
                          key={index}
                          onClick={() => handleClick(item, index)}
                          className={item.id === activeItem.id ? "active" : ""}
                        >
                          {item.name}
                          {index === 0 && guidePosition === 4 ? (
                            <div className="tooltip fifth">
                              <div className="triangle"></div>
                              <p>
                                {
                                  selectedLanguage.guide.internet_channels
                                    .add_channels.by_genre_info
                                }
                              </p>
                              <div className="buttons">
                                <button
                                  className="skip"
                                  onClick={() => deactiveGuideTootltips()}
                                >
                                  {
                                    selectedLanguage.guide.internet_channels
                                      .skip_btn
                                  }
                                </button>
                                {/* <button onClick={(e) => {setSelectVisible(true); setGuidePosition(5)}}>
                            {selectedLanguage.channels.now_on_tv.filter.next}
                            </button> */}
                              </div>
                            </div>
                          ) : null}
                        </p>
                      </React.Fragment>
                    ))
                  : null}
              </div>
            ) : null}
          </div>
          {guidePosition === 3 ? (
            <div className="tooltip fourth">
              <div className="triangle"></div>
              <p>
                {
                  selectedLanguage.guide.internet_channels.add_channels
                    .criteria_info
                }
              </p>
              <div className="buttons">
                <button
                  className="skip"
                  onClick={() => deactiveGuideTootltips()}
                >
                  {selectedLanguage.guide.internet_channels.skip_btn}
                </button>
                <button
                  onClick={(e) => {
                    setSelectVisible(true);
                    setGuidePosition(4);
                  }}
                >
                  {selectedLanguage.channels.now_on_tv.filter.next}
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="input_field">{renderComponents()}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, {
  setCountries,
  setGenres,
  setGuidePosition,
})(AddTVchannels);
