import React, { useState, useEffect } from "react";
import { deviceDetect } from "react-device-detect";
import { connect, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { FaAngleDown, FaEye } from "react-icons/fa";
import axios from "axios";
import {
  setLockedCategories,
  setPinCode,
  setSelectedLanguage,
} from "../redux/actions";
import Logo from "../images/login_logo.png";
import { utility } from "../helpers/Utility";
import { Services } from "../services/Services";
import "./Login.css";
import ReactCodeInput from "react-verification-code-input";
import { isMobile } from "../helpers/deviceDetect";
import verificationCode from "../images/verificationCode.png";
import { demo, baseUrlProd } from "../reskin/reskin";
import { MdPhone } from "react-icons/md";
import TermsContitionsModal from "../components/TermsContitionsModal/TermsContitionsModal";

const Login = ({ setLockedCategories, setPinCode }) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [codeSent, setCodeSent] = useState(null);
  const [selectStatus, setSelectStatus] = useState(false);
  const [preview, setPreview] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [active_lang, setActive_lang] = useState({});
  const history = useHistory();

  const [verification, setVerification] = useState(false);
  const [verificationErr, setVerificationErr] = useState(null);

  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  useEffect(() => {
    Services.getTermsConditionsInfo()
    .then((res) => {
      setTermsAndConditions(res.data);
    })
    .catch((err) => {
      console.log("error ", err);
    });
  }, [active_lang])

  useEffect(() => {
    Services.allLanguages().then((res) => {
      setLanguages(res.data.languages);
      setActive_lang(res.data.languages[0]);
      localStorage.setItem("locale", res.data.languages[0].id);
      dispatch(setSelectedLanguage(res.data.languages[0]));
    });
    // eslint-disable-next-line
  }, []);

  const handleChange = (id) => {
    let current = languages.filter((item) => item.id === id);
    setActive_lang(current[0]);
    dispatch(setSelectedLanguage(current[0]));
    setSelectStatus(false);
    localStorage.setItem("locale", current[0].id);
    // if (!localStorage.jwtToken) {
    // }
    // console.log(current[0].id);
    // Services.changeLanguage({ language_id: current[0].id }).then(() =>
    //   Services.getDemoLanguage().then((res) =>
    //     setSelectedLanguage(res.data.language)
    //   )
    // );
  };

  const login = () => {
    axios
      .post(`${utility.baseUrl}login`, {
        username,
        password,
        mac: deviceDetect().userAgent,
        device_type: "Web"
      })
      .then((res) => {
        setError(null);
        let customer = res.data.customer;
        localStorage.setItem("jwtToken", res.data.access_token);
        localStorage.setItem("stream_token", res.data.stream_token);
        localStorage.setItem("username", customer.username);
        localStorage.setItem("email", customer.email);
        localStorage.setItem("expire_date", customer.expire_date);
        localStorage.setItem("locale", active_lang.id);
        localStorage.setItem("pincode", customer.pincode);
        localStorage.setItem("all_locked", false);
        localStorage.setItem(
          "used_devices",
          res.data.customer.max_connections_kozmetika
        );
        localStorage.setItem("show_info_box", true);
        localStorage.setItem("show_guide_banner", false);
        localStorage.setItem("showed_general_banner", false);
        localStorage.setItem("showed_internettv_info", false);
        localStorage.setItem("package", customer.package_name);
        setPinCode(customer.pincode);
        setLockedCategories(customer.locked_categories);

        setIsTermsModalOpen(true);
        // console.log(customer)
      })
      .catch((err) => {
        if (err.response.data.error.includes("Unverified")) {
          setVerification(true);
        } else setError(err.response.data.error);
      });
  };


  let verification_code = null;

  const handleCode = (resetForm) => {
    // console.log(verification_code);
    axios
      .post(`${utility.baseUrl}verify`, {
        verification_code: verification_code,
      })
      .then(() => {
        setVerification(false);
        setVerificationErr(null);
      })
      .catch((err) => {
        setVerificationErr("Pogrešan verifikacioni kod");
      });
  };

  const handleResend = () => {
    axios
      .post(`${utility.baseUrl}resend/verification-code`, {
        username: username,
      })
      .then(() => {
        setCodeSent("Kod poslat");
      })
      .catch((err) => {});
  };

  const getIp = () => {
    axios.get("https://api.ipify.org/?format=json").then((res) => {
      localStorage.setItem("ip", res.data.ip);
      window.location.href = "/";
    });
  };

  const closeSelect = () => {
    if (selectStatus) setSelectStatus(false);
  };

  return (
    <>
      {isTermsModalOpen && (
        <TermsContitionsModal
          termsAndConditions={termsAndConditions}
          getIp={getIp}
          activeLang={{news: active_lang.news, guide: active_lang.guide}}
          confirmButton={true}
          logout={() => Services.logout()}
        />
      )}

      {verification ? (
        <div
          className="verificationCode"
          onClick={() => setVerificationErr(false)}
        >
          <div className="bg" onClick={() => setVerification(false)} />
          <div className="vCodeWrapper">
            <img src={verificationCode} alt="Code" />
            <h1>
              {active_lang.login &&
                active_lang.login.verification_code_popup.title}
            </h1>
            <p>
              {active_lang.login &&
                active_lang.login.verification_code_popup.description}
            </p>
            <ReactCodeInput
              type="number"
              fields={4}
              placeholder={["*", "*", "*", "*"]}
              claseName="unos-kod"
              onChange={(e) => (verification_code = e)}
            />
            {verificationErr && (
              <p className="verification-err">{verificationErr}</p>
            )}
            {codeSent && <p className="code-sent">{codeSent}</p>}
            <button onClick={handleCode}>
              {active_lang.login &&
                active_lang.login.verification_code_popup.confirm_btn}
            </button>
            <div className="sendAgain">
              <p>
                {active_lang.login &&
                  active_lang.login.verification_code_popup.code_not_sent}
              </p>
              <span onClick={handleResend}>
                {active_lang.login &&
                  active_lang.login.verification_code_popup.send_code_btn}
              </span>
            </div>
          </div>
        </div>
      ) : null}
      {active_lang.login ? (
        <div className="login_wrapper" onClick={closeSelect}>
          <div className={"login_content" + (!demo ? " not-demo" : "")}>
            <img src={Logo} alt="logo" />

            <form>
              <label>
                {active_lang.settings && active_lang.settings.submenu.language}
              </label>
              <div className="custom_select">
                <div
                  className={selectStatus ? "capital rotate" : "capital"}
                  onClick={() => setSelectStatus(!selectStatus)}
                >
                  <p>{active_lang.name && active_lang.name}</p> <FaAngleDown />
                </div>
                {selectStatus ? (
                  <div className="options">
                    {languages.map((item) => (
                      <p key={item.id} onClick={() => handleChange(item.id)}>
                        {item.name}
                      </p>
                    ))}
                  </div>
                ) : null}
              </div>
              <label>
                {active_lang.login && active_lang.login.username_placeholder}
              </label>
              <input
                type="text"
                onChange={(e) => setUsername(e.target.value)}
              />

              <label>
                {active_lang.login && active_lang.login.password_placeholder}
              </label>
              <div className="input_holder">
                <input
                  type={preview ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FaEye onClick={() => setPreview(!preview)} />
              </div>

              {error ? <p className="error-message">{error}</p> : null}

              <input
                className="register_submit"
                type="button"
                value={active_lang.login && active_lang.login.btn}
                onClick={() => login()}
              />
            </form>
            <div className="dont_acc">
              {active_lang.login && active_lang.login.no_account}
              <Link to="signup">
                {active_lang.login && active_lang.login.sign_up}
              </Link>
            </div>
            {demo && (
              <button
                className="demoBtn"
                onClick={() => {
                  localStorage.setItem("locale", active_lang.id);
                  history.push(isMobile ? "/tv" : "/channels/epgs");
                }}
              >
                {active_lang.login && active_lang.login.try_free_version}
              </button>
            )}
            <div className="contact-bottom">
              <p>{active_lang?.language?.customer_center_description}</p>
              <a
                href={
                  "https://" +
                  active_lang.settings.contact.webiste_title +
                  (baseUrlProd === "https://t-adria.com/api/" ||
                  baseUrlProd === "https://iptvxtim.com/api/"
                    ? "/kontakt/"
                    : "/contact/")
                }
                target="_blanko"
              >
                <MdPhone /> {active_lang.settings.submenu.contact}
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default connect(null, {
  setLockedCategories,
  setPinCode,
  setSelectedLanguage,
})(Login);
