import React, { useState } from 'react'
import { connect } from 'react-redux'
import SettingsMenu from '../SettingsMenu/SettingsMenu'
// import { useEffect } from 'react'
import { GoCheck } from 'react-icons/go'
import { FaPlay } from 'react-icons/fa'

import {
  changeInfoShow,
  setGuidePosition,
  setPlayerVisible,
} from '../../../redux/actions'
import { useEffect } from 'react'
import { Services } from '../../../services/Services'

const Help = ({ lang, changeInfoShow, setGuidePosition, setPlayerVisible }) => {
  const [showInfoBox, setShowInfoBox] = useState(
    localStorage.getItem('show_info_box'),
  )
  const [showGuideBanner, setShowGuideBanner] = useState(
    localStorage.getItem('show_guide_banner'),
  )
  const [videoTutorials, setVideoTutorials] = useState([])
  const [videos, setVideos] = useState([])

  useEffect(() => {
    Services.getVideoTutorials().then((res) => {
      setVideoTutorials(res.data)
    })
    Services.getVideoTutorialsCategory().then((res) => {
      setVideos(res.data)
    })
  }, [])

  const doShowInfoBox = () => {
    if (showInfoBox === 'false') {
      localStorage.setItem('show_info_box', true)
      setShowInfoBox('true')
      setGuidePosition(1)
    }
  }

  const dontShowInfoBox = () => {
    if (showInfoBox === 'true') {
      localStorage.setItem('show_info_box', false)
      setShowInfoBox('false')
      setGuidePosition(0)
    }
  }

  const doShowGuideBanner = () => {
    if (showGuideBanner === 'false') {
      localStorage.setItem('show_guide_banner', true)
      setShowGuideBanner('true')
    }
  }

  const dontShowGuideBanner = () => {
    if (showGuideBanner === 'true') {
      localStorage.setItem('show_guide_banner', false)
      setShowGuideBanner('false')
    }
  }

  const handleClick = (src) => {
    const channel = {}
    channel.tutorial = src
    const channels = []
    const epgs = []
    setPlayerVisible({ channel, channels, epgs })
  }

  // const getVideos = (id) => {
  //   let video;
  //   Services.getVideoTutorialsCategory({ category_id: id }).then(
  //     (res) => {

  //      video = res.data.map((video) => (
  //         <div className="row" key={video.id}>
  //           <p>{video.name}</p>
  //           <button className="video" onClick={() => handleClick(video.src)}>
  //             <FaPlay />
  //           </button>
  //         </div>
  //       ))

  //       console.log(video)
  //     },
  //   )
  // }

  let videoTutorialsRow = null

  videoTutorialsRow = videoTutorials.map((tutorial) => {
    return (
      <React.Fragment key={tutorial.id}>
        <div className="video">
          <img src={tutorial.icon_src_active} alt="Help" />
          <p className="video_header">{tutorial.category_name}</p>
        </div>
        {videos.map((video) => {
          if (tutorial.id === video.category_id) {
            return (
              <div className="row" key={video.id}>
                <p>{video.name}</p>
                <button
                  className="video"
                  onClick={() => handleClick(video.src)}
                >
                  <FaPlay />
                </button>
              </div>
            )
          } else {
            return null;
          }
        })}
      </React.Fragment>
    )
  })

  return (
    <div className="min_height flex white">
      <SettingsMenu />
      <div className="settings_content help">
        <h3>{lang.settings.submenu.help}</h3>
        <div className="row_between">
          <h4>{lang.settings.help.info_box_label}</h4>
          <div className="row">
            <p>{lang.settings.help.info_box_yes}</p>
            <button
              className={showInfoBox === 'true' ? 'active' : null}
              onClick={() => {
                doShowInfoBox()
                changeInfoShow(true)
              }}
            >
              <GoCheck />
            </button>
          </div>
          <div className="row">
            <p>{lang.settings.help.info_box_no}</p>
            <button
              className={showInfoBox === 'false' ? 'active' : null}
              onClick={() => {
                dontShowInfoBox()
                changeInfoShow(false)
              }}
            >
              <GoCheck />
            </button>
          </div>
        </div>
        <div className="row_between">
          <h4>{lang.settings.help.guide_label}</h4>
          <div className="row">
            <p>{lang.settings.help.guide_yes_label}</p>
            <button
              className={showGuideBanner === 'true' ? 'active' : null}
              onClick={doShowGuideBanner}
            >
              <GoCheck />
            </button>
          </div>
          <div className="row">
            <p>{lang.settings.help.guide_no_label}</p>
            <button
              className={showGuideBanner === 'false' ? 'active' : null}
              onClick={dontShowGuideBanner}
            >
              <GoCheck />
            </button>
          </div>
        </div>
        <div className="row_between">
          <h4>{lang.settings.help.video_tutorials_label}</h4>
          {videoTutorialsRow}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
})

export default connect(mapStateToProps, {
  changeInfoShow,
  setGuidePosition,
  setPlayerVisible,
})(Help)
