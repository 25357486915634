import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Services } from "../../../services/Services";
import SettingsMenu from "../SettingsMenu/SettingsMenu";

const Contact = ({ lang }) => {
  const [callCenters, setCallCenters] = useState([]);

  useEffect(() => {
    Services.getAppInfo().then((res) => {
      setCallCenters(res.data.call_center_phones);
    });
  }, []);

  return (
    <div className="min_height flex white">
      <SettingsMenu />
      <div className="settings_content contact">
        <h3>{lang.settings.submenu.contact}</h3>
        <div className="row_between">
          <p>{lang.settings.contact.description}</p>
          <a
            target="_blanko"
            href={"https://" + lang.settings.contact.webiste_title}
          >
            {lang.settings.contact.webiste_title}
          </a>
          <br />
          <br />
          <a href={"mailto:" + lang.settings.contact.support_title}>
            {lang.settings.contact.support_title}
          </a>
        </div>
        {/* <div className="row_between">
          <p>Podrska</p>
          <a href={"mailto:" + lang.settings.contact.support_title}>
            {lang.settings.contact.support_title}
          </a>
        </div> */}
        <div className="row_between">
          <p>{lang.login.call_center_screen.heading}</p>
          {/* <a href="#!">{lang.settings.contact.phones_title}</a> */}
          {callCenters.map((item) => (
            <div className="call_center">
              <img src={item.country.flag} alt="Flag" />
              <h4>{item.country.name}</h4>
              <a href={`tel:${item.phone_number}`}>{item.phone_number}</a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
});

export default connect(mapStateToProps)(Contact);
