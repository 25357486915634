import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FaAngleDown } from "react-icons/fa";
import { AiOutlineLoading3Quarters, AiOutlineFileExcel } from "react-icons/ai";
import { Services } from "../../../services/Services";
import { makeGrid } from "../../../helpers/makeGrid";
import { setLoader } from "../../../redux/actions";
import Track from "../Track/Track";
import Grid from "../../../components/Grid/Grid";
import "./NowOnTV.css";

const NowOnTV = ({ lang, loader, setLoader, param }) => {
  const [data, setData] = useState();
  const [periodVisible, setPeriodVisible] = useState(false);
  const [period, setPeriod] = useState("now");
  const [periodText, setPeriodText] = useState("");
  const [sortVisible, setSortVisible] = useState(false);
  const [sort, setSort] = useState("date");
  const [sortText, setSortText] = useState("");
  const language = lang.channels.now_on_tv.filter;
  const elements = data || [];
  const [lockedChannel, setLockedChannel] = useState(null);
  const [openModalTry, setOpenModalTry] = useState(false);
  const components = elements.map((item, index) => (
    <Grid
      key={index}
      item={item}
      lockedChannel={lockedChannel}
			setLockedChannel={setLockedChannel}
			openModalTry={openModalTry}
			setOpenModalTry={setOpenModalTry}
    />
  ));

  useEffect(() => {
    setPeriodText(language.now);
    setSortText(lang.sort.placeholder);
  }, [language, lang]);

  useEffect(() => {
    setLoader(true);
    Services.onTV(period, sort, param.category).then((res) => {
      let arr = [];
      res.data.data.map((item) =>
        arr.push({ channel: item.channel, epg: item })
      );
      setData(arr);
      setLoader(false);
    });
  }, [period, sort, setLoader, param]);

  const closeDropdowns = () => {
    if (periodVisible) setPeriodVisible(false);
    if (sortVisible) setSortVisible(false);
  };

  const handleClick = (e, val, who) => {
    let text = e.target.textContent;
    if (who === 1) {
      setPeriodText(text);
      setPeriod(val);
    } else {
      setSortText(text);
      setSort(val);
    }
  };

  return (
    <div className="min_height white now_on_tv_screen" onClick={closeDropdowns}>
      <Track />
      <div className="container">
        <div className="row">
          <h2 className="title">{periodText}</h2>
          <div>
            <div
              className="dropdown"
              onClick={() => setPeriodVisible(!periodVisible)}
            >
              <p>{periodText}</p>
              <FaAngleDown className={periodVisible && "rotate"} />
              {periodVisible && (
                <div className="dropdown_options">
                  <p onClick={(e) => handleClick(e, "prev", 1)}>
                    {language.previous}
                  </p>
                  <p onClick={(e) => handleClick(e, "now", 1)}>
                    {language.now}
                  </p>
                  <p onClick={(e) => handleClick(e, "next", 1)}>
                    {language.next}
                  </p>
                </div>
              )}
            </div>
            <div
              className="dropdown"
              onClick={() => setSortVisible(!sortVisible)}
            >
              <p>{sortText}</p>
              <FaAngleDown className={sortVisible && "rotate"} />
              {sortVisible && (
                <div className="dropdown_options">
                  <p onClick={(e) => handleClick(e, "asc", 2)}>
                    {lang.sort.asc.name}
                  </p>
                  <p onClick={(e) => handleClick(e, "desc", 2)}>
                    {lang.sort.desc.name}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {!data && (
          <div className="wait">{!loader && <AiOutlineLoading3Quarters />}</div>
        )}
        {data &&
          (data.length > 0 ? (
            <div className="grid_items_wrapper">{makeGrid(components)}</div>
          ) : (
            <div className="no_added_internet_channels">
              <AiOutlineFileExcel />
              <p>{lang.no_now_on_tv}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  loader: state.loader,
  param: state.channelsParams,
});

export default connect(mapStateToProps, { setLoader })(NowOnTV);
