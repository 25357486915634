import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { utility } from '../../helpers/Utility'
import './InfoTab.css'
import {MdClose , MdNavigateNext, MdNavigateBefore} from 'react-icons/md'

const InfoTab = (props) => {
  const [images, setImages] = useState(null)
  const [position, setPosition] = useState(0)

  const nextPosition = () => {
    if(position + 1 < images.length){
      setPosition(p => p+1)
    }else setPosition(0)
  }

  const prevPosition = () => {
    if(position - 1 >= 0){
      setPosition(p => p-1)
    }else setPosition(images.length - 1)
  }

  useEffect(() => {
    utility
      .axiosInstance()
      .get(`${utility.baseUrl}guides-new`, { type: props.type })
      .then((res) => {
        setImages(res.data[0].images)
        localStorage.setItem("showed_general_banner",true)
      })
      .catch((err) => console.log(err))
    // eslint-disable-next-line
  }, [])

  let image = null

  if (images) {
    image = <img src={images[position].url} alt="Info" />
  }

  return (
    <div className="InfoTab">
      {image}
      <button className="close" onClick={props.close}><MdClose /></button>
      <div className="buttons">
        <button onClick={prevPosition}><MdNavigateBefore />{props.lang.news.previous_btn}</button>
        <button onClick={nextPosition}>{props.lang.news.next_btn}<MdNavigateNext /></button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    lang: state.selectedLanguage,
  }
}

export default connect(mapStateToProps)(InfoTab)
