import React, { useState } from "react";
import PinInput from "react-pin-input";
import { connect, useSelector } from "react-redux";
import { utility } from "../../helpers/Utility";
import { setPinCodeModal } from "../../redux/actions";

const moment = require("moment-timezone");
const timezone = moment.tz.guess();

const PINcode = ({ setPinCodeModal }) => {
  const [numbers, setNumbers] = useState("");
  const [error, setError] = useState("");

  const lang = useSelector((state) => state.selectedLanguage);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (numbers === localStorage.pincode) {
      let now = moment.tz(new Date(), utility.timezone).tz(timezone).format();
      setError("");
      setPinCodeModal("success");
      localStorage.setItem("all_locked", true);
      localStorage.setItem("is_locked_time", now);
    } else setError(lang?.settings.parental.wrong_password);
  };

  return (
    <div className="enter_pin_wrapper" onClick={() => setPinCodeModal(null)}>
      <form
        onSubmit={handleSubmit}
        onClick={(e) => e.stopPropagation()}
        className="enter_pin_holder"
      >
        <h2>{lang?.settings.parental.password}</h2>
        <div>
          <PinInput
            length={4}
            focus={true}
            secret
            initialValue=""
            onChange={(value) => setNumbers(value)}
            type="numeric"
            style={{ padding: "10px" }}
          />
        </div>
        <p>{error}</p>
        <button onClick={handleSubmit} value={numbers}>
          {lang?.settings.parental.save_btn}
        </button>
      </form>
    </div>
  );
};

export default connect(null, { setPinCodeModal })(PINcode);
