import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { Services } from "../../../services/Services";
import {
  // setInternetChannels,
  setFavoritesChannels,
  setPlayerVisible,
  setM3u8ActiveChannel,
  setEpgs,
  setChannels,
  setGuidePosition,
  setAddedMoviesPage,
  setAddedMovies,
  setPinCodeModal,
  // setFavoriteMovies,
} from "../../../redux/actions";
import Track from "../Track/Track";
import "../InternetTvChannels/InternetTvChannels.css";
import Loader from "../../../components/PaginationLoader/PaginationLoader";
import PlayerLoader from "../../../components/VideoPlayer/Player/Loader";

import { Link } from "react-router-dom";
import { paginationOnEndReach } from "../../../helpers/paginationOnEndReach";
import SingleMovie from "./SingleMovie";

let per_page = 50;

const AddedMovies = ({
  lang,
  activeChannel,
  setEpgs,
  setM3u8ActiveChannel,
  setPlayerVisible,
  guidePosition,
}) => {
  const dispatch = useDispatch();

  const channels = useSelector((state) => state.addedMovies);
  const page = useSelector((state) => state.addedMoviesPage);
  const wrapperRef = useRef();

  // to ignore first render when we already have channels fetched in store
  const [isMount, setIsMount] = useState(true);

  const [lastPage, setLastPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [firstLoader, setFirstLoader] = useState(false);

  const [chosenChannel, setChosenChannel] = useState();
  const [addedCategories, setAddedCategories] = useState([]);

  const [searchedChannels, setSearchedChannels] = useState([]);
  const [searchActive, setSearchAcitve] = useState(false);

  const setMovies = (data) => {
    dispatch(setAddedMovies(data));
  };

  const fetchAPI = () => {
    if (page === 1) {
      setFirstLoader(true);
    }
    setLoading(true);
    Services.addedMoviesPagination(per_page, page).then((res) => {
      setMovies(channels.concat(res.data.data));
      setLastPage(res.data.meta.last_page);
      setLoading(false);
      setFirstLoader(false);
    });
  };

  const getMoreChannels = () => {
    if (page + 1 <= lastPage && !loading) {
      dispatch(setAddedMoviesPage(page + 1));
    }
  };

  useEffect(() => {
    if (channels.length === 0) {
      setIsMount(false);
    } else if (isMount) {
      setIsMount(false);
      return;
    }
    fetchAPI();
    // eslint-disable-next-line
  }, [page]);

  const handleSearch = (e) => {
    if (e.target.value.length > 2) {
      setSearchAcitve(true);
      const channelName = e.target.value.toLowerCase();
      Services.addedMoviesSearch(channelName).then((res) => {
        setSearchedChannels(res.data.channels);
      });
    } else {
      setSearchAcitve(false);
    }
  };

  const addToFavorite = (e, item, callback) => {
    e.stopPropagation();
    let obj = {};
    obj = { m3u_channel_id: item.id };
    Services.addFavorite(obj).then(() => {
      callback && callback();
    });
  };

  const removeToFavorite = (e, item, callback) => {
    e.stopPropagation();
    let obj = {};
    obj = { m3u_channel_id: item.id };
    Services.removeFavorite(obj).then(() => {
      callback && callback();
    });
  };

  const handleOpenCategories = (e, channel) => {
    e.stopPropagation();
    setChosenChannel(channel);
    setM3u8ActiveChannel(channel);
    Services.getChannelCategories(channel.id).then((res) => {
      setAddedCategories(res.data);
    });
  };

  const addCategory = (category_id) => {
    Services.addCategory({ channel_id: chosenChannel.id, category_id }).then(
      () => {
        Services.getChannelCategories(chosenChannel.id).then((res) => {
          setAddedCategories(res.data);
        });
      }
    );
  };

  const removeCategory = (category_id) => {
    Services.removeCategory({ channel_id: chosenChannel.id, category_id }).then(
      () => {
        Services.getChannelCategories(chosenChannel.id).then((res) => {
          setAddedCategories(res.data);
        });
      }
    );
  };

  const handleCategoryClick = (item) => {
    if (item.is_added) {
      removeCategory(item.id);
    } else {
      addCategory(item.id);
    }
  };

  const handleClick = (src, poster) => {
    const channel = {};
    channel.tutorial = src;
    channel.stream_icon = poster;
    channel.is_movie = true;
    const channels = [];
    const epgs = [];
    dispatch(setPlayerVisible({ channel, channels, epgs }));
  };

  const activeClass = (item) =>
    activeChannel === item ? "item movie active" : "item movie";

  const langauge = lang.internet_channels.submenu;

  return (
    <div className="internet_channels light_bg relative">
      <Track />
      {firstLoader ? (
        <PlayerLoader />
      ) : channels.length > 0 ? (
        <>
          <div className="flex internet_channels_holder">
            <div className="channels_menu wide">
              <div className="top_labels">
                <h3>{langauge.added_movies_label}</h3>
                <h3>{lang.internet_channels.channels.add_to_category}</h3>
              </div>
              <div className="channels_menu_holder">
                <div className="input_holder">
                  <input
                    type="text"
                    placeholder={lang.search.placeholder}
                    onChange={handleSearch}
                  />
                </div>
                <div
                  className="items"
                  style={{
                    overflowY:
                      guidePosition === 8 || guidePosition === 9
                        ? "visible"
                        : "scroll",
                    paddingBottom: 60,
                  }}
                  ref={wrapperRef}
                  onScroll={(e) =>
                    !searchActive && paginationOnEndReach(e, getMoreChannels)
                  }
                >
                  {searchActive
                    ? searchedChannels.map((item, index) => (
                        <SingleMovie
                          key={index}
                          item={item}
                          activeClass={activeClass}
                          handleClick={handleClick}
                          removeToFavorite={removeToFavorite}
                          addToFavorite={addToFavorite}
                          handleOpenCategories={handleOpenCategories}
                        />
                      ))
                    : channels.map((item, index) => (
                        <SingleMovie
                          key={index}
                          item={item}
                          activeClass={activeClass}
                          handleClick={handleClick}
                          removeToFavorite={removeToFavorite}
                          addToFavorite={addToFavorite}
                          handleOpenCategories={handleOpenCategories}
                        />
                      ))}
                  {loading && (
                    <div style={{ position: "relative", height: 60 }}>
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {chosenChannel && (
              <div className="add_to_category">
                <div className="category_top">
                  <img
                    src={chosenChannel.stream_icon}
                    alt="Channel"
                    className="channelIcon"
                  />
                  <FaTimes
                    onClick={() => {
                      setChosenChannel(null);
                      setM3u8ActiveChannel(null);
                    }}
                    className="red"
                  />
                </div>
                <div className="categories">
                  {addedCategories.map((item) => (
                    <div
                      className="single_category"
                      key={item.id}
                      onClick={() => handleCategoryClick(item)}
                    >
                      <img
                        src={
                          item.is_added
                            ? item.icon_src_favorite
                            : item.icon_src_active
                        }
                        alt="Category"
                      />
                      <p className={item.is_added ? "active" : ""}>
                        {item.category_name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="no_added_internet_channels">
          <AiOutlineFileExcel />
          <p>{lang.internet_channels.movies.no_movies_label}</p>
          <Link to="/internetTV/addM3U8list">
            {lang.internet_channels.movies.add_movies_btn}
          </Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  // channels: state.internetChannels,
  active_channel: state.m3u8ActiveChannel,
  activeChannel: state.m3u8ActiveChannel,
  guidePosition: state.guidePosition,
  pincode: state.pinCodeModal,
});

export default connect(mapStateToProps, {
  // setInternetChannels,
  setChannels,
  setEpgs,
  setM3u8ActiveChannel,
  setFavoritesChannels,
  setPlayerVisible,
  setGuidePosition,
  setPinCodeModal,
})(AddedMovies);
