import React, { useEffect, useState } from "react";
import "./LoginMobile.css";
import { useSelector } from "react-redux";
import googlePlayIcon from "../../../../images/googlePlayIcon.png";
import appleStoreIcon from "../../../../images/appleStoreIcon.png";
import loginLogo from "../../../../images/login_logo.png";
import { Link } from "react-router-dom";
import { Services } from "../../../../services/Services";

const LoginMobile = () => {
  const lang = useSelector((state) => state.selectedLanguage);
  const [androidLink, setAndroidLink] = useState();
  const [iosLink, setIosLink] = useState();

  useEffect(() => {
    Services.getAppInfo().then((res) => {
      res.data.mobile_google_store &&
        setAndroidLink(res.data.mobile_google_store);
      res.data.mobile_ios_store && setIosLink(res.data.mobile_ios_store);
    });
  }, []);

  return (
    <div className="LoginMobile">
      <Link to="/tv">
        <img src={loginLogo} alt="Logo" className="logo" />
      </Link>
      <h1>{lang.login && lang.login.use_mobile_app_label}</h1>

      <div className="store-buttons">
        {androidLink && (
          <a href={androidLink}>
            <img src={googlePlayIcon} alt="Android app" />
          </a>
        )}
        {iosLink && (
          <a href={iosLink}>
            <img src={appleStoreIcon} alt="IOS app" />
          </a>
        )}
      </div>
    </div>
  );
};

export default LoginMobile;
