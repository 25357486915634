// import dependencies
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// import assets
import root_logo from "../../../../images/root_logo.png";
import { Services } from "../../../../services/Services";
import { setSelectedLanguage } from "../../../../redux/actions";

// import css
import "./Nav.css";

const Nav = () => {
  const dispatch = useDispatch();
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState({});

  useEffect(() => {
    Services.allLanguages().then((res) => {
      setLanguages(res.data.languages);

      if (localStorage.locale) {
        const found = res.data.languages.find(
          (elem) => elem.id === parseInt(localStorage.locale)
        );
        setLanguage(found.id);
      } else {
        setLanguage(res.data.languages[0]);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    Services.getDemoLanguage().then((res) =>
      dispatch(setSelectedLanguage(res.data.language))
    );
    // eslint-disable-next-line
  }, [language]);

  const changeLanguage = (event) => {
    const value = event.target.value;
    setLanguage(value);
    localStorage.setItem("locale", value);
  };

  return (
    <div className="Nav">
      <img src={root_logo} alt="Logo" />

      <div className="language-select-container">
        <select
          className="language-select"
          onChange={changeLanguage}
          value={language}
        >
          {languages.map((elem) => (
            <option key={elem.id} value={elem.id}>
              {elem.name}
            </option>
          ))}
        </select>
      </div>

      <div className="Border" />
    </div>
  );
};

export default Nav;
