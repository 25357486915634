import React from 'react';

const AboutEpg = ({ channel }) => 
  <div className='video_player_info'>
    <div className='video_player_info_img'>
      <img src={channel.stream_icon} alt='' />
    </div>
    <h3>{channel.name}</h3>
  </div>

export default AboutEpg;
