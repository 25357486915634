import React from "react";
import { AiOutlineEye, AiOutlineFileExcel } from "react-icons/ai";
import { RiPlayCircleLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import "./MoviesSection.css";

const MoviesSection = ({ handleClick, setChosenMovie, result, fav }) => {
  const lang = useSelector((state) => state.selectedLanguage);
  const loader = useSelector((state) => state.loader);
  return (
    <div className="movies-section">
      {fav
        ? !loader &&
          (result.length > 0 ? (
            result.map(
              (item, index) =>
                item.is_favorite && (
                  <div
                    key={index}
                    className="single-movie"
                    onClick={() => setChosenMovie(item)}
                  >
                    <div className="img-holder">
                      <img src={item.poster} alt="Movie" />
                      <div className="img-overlay" />
                    </div>
                    <h2>{item.name}</h2>
                    <p>{item.release_date.substring(0, 4)}</p>
                    {item.is_watched && (
                      <div className="watched">
                        <AiOutlineEye />{" "}
                        <span>{lang?.movies?.watched_label}</span>
                      </div>
                    )}
                    <div
                      className="play-movie"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick(item.src, item.poster);
                      }}
                    >
                      <RiPlayCircleLine />
                    </div>
                  </div>
                )
            )
          ) : (
            <div className="no_added_internet_channels">
              <AiOutlineFileExcel />
              <p>{lang?.favorites?.vod.no_movies_label}</p>
            </div>
          ))
        : result.map((item, index) => (
            <div
              key={index}
              className="single-movie"
              onClick={() => setChosenMovie(item)}
            >
              <div className="img-holder">
                <img src={item.poster} alt="Movie" />
                <div className="img-overlay" />
              </div>
              <h2>{item.name}</h2>
              <p>{item.release_date.substring(0, 4)}</p>
              {item.is_watched && (
                <div className="watched">
                  <AiOutlineEye /> <span>{lang?.movies?.watched_label}</span>
                </div>
              )}
              <div
                className="play-movie"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(item.src, item.poster);
                }}
              >
                <RiPlayCircleLine />
              </div>
            </div>
          ))}
    </div>
  );
};

export default MoviesSection;
