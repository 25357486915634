import React from "react";
import "./PaginationLoader.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const PaginationLoader = () => {
  return (
    <div className="PaginationLoader">
      <AiOutlineLoading3Quarters />
    </div>
  );
};

export default PaginationLoader;
