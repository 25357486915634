import React from 'react';
import { connect } from 'react-redux';
import { FaCheck } from 'react-icons/fa';

import {withRouter} from 'react-router-dom'

const SuccessPopup = ({ closePopup, lang , history}) => {
  const language = lang.internet_channels.add_m3u8_list;
  const handleSubmit = () => {
    closePopup();
    // window.location.href = '/internetTV/addM3U8list';
    history.push('/internetTV/m3u8lists')
  };
  
  return (
    <div className='mini_popup' onClick={closePopup}>
      <div className='mini_popup_holder' onClick={e => e.stopPropagation()}>
        <FaCheck />
        <p>{language.success_text}</p>
        <button onClick={handleSubmit}>{language.success_btn}</button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage
})

export default connect(mapStateToProps)(withRouter(SuccessPopup));
