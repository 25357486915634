import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import RemovedPin from "./RemovedPin";
import { setCategories } from "../../../redux/actions";
import { Services } from "../../../services/Services";

const RemovedPopup = ({ close, lockedCategories, setCategories }) => {
  const [popup, setPopup] = useState(false);
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    let params = {
      filter: "",
      category: "",
      sort: "",
      favorite: "",
    };
    if (localStorage.jwtToken) {
      Services.getCategories(params).then((res) => {
        setCategories(res.data);
        let newArr = res.data.filter((a) =>
          lockedCategories.some((b) => a.id === b)
        );
        setLocalData(newArr);
      });
    } else {
      Services.getDemoCategories(params).then((res) => {
        setCategories(res.data);
        let newArr = res.data.filter((a) =>
          lockedCategories.some((b) => a.id === b)
        );
        setLocalData(newArr);
      });
    }
  }, [setCategories, setLocalData, lockedCategories]);

  const handleClick = (item) => {
    setPopup(item);
  };

  return (
    <>
      <div className="categoriesPopup_wrapper" onClick={close}>
        <div
          className="categoriesPopup_holder"
          onClick={(e) => e.stopPropagation()}
        >
          {localData.length ? (
            localData.map((item) => (
              <div
                className="item"
                key={item.id}
                onClick={() => handleClick(item)}
              >
                {item.category_name}
              </div>
            ))
          ) : (
            <p className="all_locked">List is empty.</p>
          )}
        </div>
      </div>
      {popup ? (
        <RemovedPin
          data={popup}
          closeParent={close}
          closePopup={() => setPopup(false)}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { setCategories })(RemovedPopup);
