import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { Services } from "../../../services/Services";
import { setCarouselData } from "../../../redux/actions";
import NowOnTVSlider from "../NowOnTVSlider/NowOnTVSlider";
import "./Carousel.css";

const Carousel = ({ data, opacity, setCarouselData }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (localStorage.jwtToken) {
      Services.getBanners().then((res) => setCarouselData(res.data));
    } else {
      Services.getDemoBanners().then((res) => setCarouselData(res.data));
    }
  }, [setCarouselData]);

  const slideLeft = () => {
    if (0 < activeIndex) setActiveIndex(activeIndex - 1);
    else setActiveIndex(data.length - 1);
  };

  const slideRight = () => {
    if (activeIndex < data.length - 1) setActiveIndex(activeIndex + 1);
    else setActiveIndex(0);
  };

  const classes = (i) =>
    activeIndex === i
      ? `carousel_item${i} carousel_item active_slider`
      : `carousel_item${i} carousel_item`;

  return (
    <div className="carousel_wrapper">
      <div className="carousel_holder" style={{ opacity }}>
        {data.length
          ? data.map((item, index) => (
              <div
                key={index}
                className={classes(index)}
                style={{ background: `url(${item.url})` }}
              >
                <div>
                  <h2>{item.text}</h2>
                  <p>{item.second_text}</p>
                  {item.epg !== null ? (
                    <button onClick={() => this.handleClick(item)}>
                      Gledaj
                    </button>
                  ) : null}
                </div>
              </div>
            ))
          : null}
        <div className="carousel_arrows">
          <div className="carousel_arrow_left" onClick={slideLeft}>
            <IoIosArrowDropleftCircle />
          </div>
          <div className="carousel_arrow_right" onClick={slideRight}>
            <IoIosArrowDroprightCircle />
          </div>
        </div>
      </div>
      <NowOnTVSlider />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.carouselData,
});

export default connect(mapStateToProps, { setCarouselData })(Carousel);
