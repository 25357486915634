import React, { useState } from "react";
import SettingsMenu from "../SettingsMenu/SettingsMenu";
import { connect } from "react-redux";
import CategoriesPopup from "./CategoriesPopup";
import RemovedPopup from "./RemovedPopup";
import { Services } from "../../../services/Services";
import { FaEye } from "react-icons/fa";

const PinCode = ({ selectedLanguage }) => {
  const [oldPIN, setOldPIN] = useState("");
  const [newPIN, setNewPIN] = useState("");
  const [rePIN, setRePIN] = useState("");
  const [error, setError] = useState("");
  const [categoriesPopup, setCategoriesPopup] = useState(false);
  const [removedPopup, setRemovedPopup] = useState(false);
  const [toggleInput1, setToggleInput1] = useState(false);
  const [toggleInput2, setToggleInput2] = useState(false);
  const [toggleInput3, setToggleInput3] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (newPIN === rePIN) {
      let data = {
        old_pincode: oldPIN,
        new_pincode: newPIN,
      };
      Services.setPincode(data)
        .then((res) => {
          if (res.data.error) {
            setError(res.data.error);
          } else {
            setError(selectedLanguage.settings.change_pin.success_message);
            localStorage.setItem("pincode", newPIN);
            window.location.href = "/settings/changepincode";
          }
          removeError();
        })
        .catch((err) => {
          setError(err.response.data.error);
        });
    } else {
      setError(selectedLanguage.settings.change_pin.pin_not_match);
      removeError();
    }
  };

  const removeError = () => {
    setTimeout(() => {
      setError("");
    }, 1500);
  };

  // console.log(selectedLanguage.settings)
  return (
    <div className="min_height flex white">
      <SettingsMenu />
      <div className="settings_content pincode">
        <h3>
          {selectedLanguage.settings &&
            selectedLanguage.settings !== null &&
            selectedLanguage.settings.submenu.parental}
        </h3>
        <form onSubmit={onSubmit}>
          <div className="input_holder">
            <input
              type={toggleInput1 ? "text" : "password"}
              maxLength={4}
              placeholder={
                selectedLanguage.settings &&
                selectedLanguage.settings !== null &&
                selectedLanguage.settings.change_pin.old_pin_placeholder
              }
              onChange={(e) => setOldPIN(e.target.value)}
              required
            />
            <FaEye onClick={() => setToggleInput1(!toggleInput1)} />
          </div>
          <div className="input_holder">
            <input
              type={toggleInput2 ? "text" : "password"}
              maxLength={4}
              placeholder={
                selectedLanguage.settings &&
                selectedLanguage.settings !== null &&
                selectedLanguage.settings.change_pin.new_pin_placeholder
              }
              onChange={(e) => setNewPIN(e.target.value)}
              required
            />
            <FaEye onClick={() => setToggleInput2(!toggleInput2)} />
          </div>
          <div className="input_holder">
            <input
              type={toggleInput3 ? "text" : "password"}
              maxLength={4}
              placeholder={
                selectedLanguage.settings &&
                selectedLanguage.settings !== null &&
                selectedLanguage.settings.change_pin
                  .new_pin_confirmation_placeholder
              }
              onChange={(e) => setRePIN(e.target.value)}
              required
            />
            <FaEye onClick={() => setToggleInput3(!toggleInput3)} />
          </div>
          <p>
            {selectedLanguage.settings &&
              selectedLanguage.settings !== null &&
              selectedLanguage.settings.change_pin.description}
            <span>
              {" "}
              {selectedLanguage.settings &&
                selectedLanguage.settings !== null &&
                selectedLanguage.settings.parental.default_pin_label}
            </span>
          </p>
          <button className="settings_btn">OK</button>
        </form>
        {error !== "" ? (
          <div className="pincode_popup">
            <p>{error}</p>
          </div>
        ) : null}
        <div className="add_pin_code">
          <button className="add" onClick={() => setCategoriesPopup(true)}>
            {selectedLanguage.settings &&
              selectedLanguage.settings !== null &&
              selectedLanguage.settings.parental.add}
          </button>
          <button className="remove" onClick={() => setRemovedPopup(true)}>
            {selectedLanguage.settings &&
              selectedLanguage.settings !== null &&
              selectedLanguage.settings.parental.remove}
          </button>
        </div>
        {categoriesPopup ? (
          <CategoriesPopup close={() => setCategoriesPopup(false)} />
        ) : null}
        {removedPopup ? (
          <RemovedPopup close={() => setRemovedPopup(false)} />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(PinCode);
