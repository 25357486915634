import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { IoIosList } from "react-icons/io";
import { BsChevronDown } from "react-icons/bs";
import { FiGrid } from "react-icons/fi";
import {
  setCategories,
  setRadioCategories,
  setCategoryRadioName,
  setRadioCategoryId,
  setCategoryName,
  setChannelsParams,
  setLoader,
  setChannels,
  setChannelsCountries,
} from "../../../redux/actions";
import { Services } from "../../../services/Services";
import "./Track.css";
import internettvIcon from "../../../images/internettvCategory.png";

const Track = ({
  grid,
  setGrid,
  lang,
  categories,
  setCategories,
  radioCategories,
  setRadioCategories,
  categoryName,
  categoryRadioName,
  setCategoryRadioName,
  setRadioCategoryId,
  setCategoryName,
  setChannelsParams,
  setLoader,
  setChannels,
  setChannelsCountries,
  setChosenCountry,
  setChosenCountryChannels,
  chosenCountryChannels,
}) => {
  const [status, setStatus] = useState(false);
  const [imageSrc, setImageSrc] = useState();
  const language = lang.channels && lang.channels.submenu;

  useEffect(() => {
    if (localStorage.jwtToken) {
      Services.getCategories({
        filter: "",
        category: "",
        sort: "",
        favorite: "",
      }).then((res) => setCategories(res.data));
    } else {
      Services.getDemoCategories({
        filter: "",
        category: "",
        sort: "",
        favorite: "",
      }).then((res) => setCategories(res.data));
    }
  }, [setCategories]);

  useEffect(() => {
    if (localStorage.jwtToken) {
      Services.getRadioCategories({ category: "", favorite: "" }).then((res) =>
        setRadioCategories(res.data.categories)
      );
    } else {
      Services.getDemoRadioCategories({
        category: "",
        favorite: "",
      }).then((res) => setRadioCategories(res.data.categories));
    }
  }, [setRadioCategories]);

  const fetchChannels = useCallback((param) => {
    if (localStorage.jwtToken) {
      Services.getChannels(param)
        .then((res) => {
          setChannels(res.data);
          if (window.location.pathname === "/channels/epgs") setLoader(false);
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    } else {
      Services.getDemoChannels(param)
        .then((res) => {
          setChannels(res.data);
          if (window.location.pathname === "/channels/epgs") setLoader(false);
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    }
    // eslint-disable-next-line
  }, []);

  const isActiveRoute = () =>
    window.location.pathname === "/channels/radios" ? true : false;

  const activeClass = (path) =>
    window.location.pathname === `/channels/${path}` ? "active_nav" : "";

  const activeGrid = (id) =>
    grid === id
      ? "channels_track_list_icon active_nav"
      : "channels_track_list_icon";

  const handleClick = (item) => {
    setChannelsCountries([]);
    setChosenCountry && setChosenCountry(null);
    setChosenCountryChannels && setChosenCountryChannels(null);
    setImageSrc(item.icon_src);
    if (window.location.pathname === "/channels/radios") {
      setCategoryRadioName(item.category_name);
      setRadioCategoryId(item.id);
    } else {
      setCategoryName(item.category_name);
      setLoader(true);
      let param = {
        filter: "",
        category: item.id,
        sort: "",
        favorite: "",
        epgs: 1,
      };
      setChannelsParams(param);
      fetchChannels(param);
    }
    setGrid && setGrid(1);
    setStatus(false);
  };

  const handleReset = () => {
    setChannelsCountries([]);
    setChosenCountry && setChosenCountry(null);
    setImageSrc(null);
    setChosenCountryChannels && setChosenCountryChannels(null);
    if (window.location.pathname === "/channels/radios") {
      setCategoryRadioName("All");
      setRadioCategoryId("");
    } else {
      setCategoryName("All");
      setLoader(true);
      let param = { filter: "", category: "", sort: "", favorite: "", epgs: 1 };
      setChannelsParams(param);
      fetchChannels(param);
    }
    setGrid && setGrid(1);
    setStatus(false);
  };

  const getCountries = () => {
    setLoader(true);
    setCategoryName("Internet Tv");
    setImageSrc(internettvIcon);
    Services.getInternetChannelsFilters().then((res) => {
      setChannelsCountries(res.data.countries);
      setLoader(false);
      res.data.countries.length > 0 &&
        setChosenCountry &&
        setChosenCountry(res.data.countries[0]);
      setGrid && setGrid(0);
    });
    setStatus(false);
  };

  useEffect(() => {
    if (
      window.location.pathname === "/channels/epgs" &&
      categoryName !== "All"
    ) {
      setChannelsCountries([]);
      setChosenCountry(null);
      setImageSrc(null);
      setChosenCountryChannels(null);
      setCategoryName("All");
      let param = { filter: "", category: "", sort: "", favorite: "", epgs: 1 };
      setChannelsParams(param);
      fetchChannels(param);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="channels_track">
        {/* <div />
        <p className="categorie_name">
          {isActiveRoute() ? categoryRadioName : categoryName}
        </p>
        <div
          className="channels_track_menu_icon"
          onClick={() => setStatus(!status)}
        >
          <span>{isActiveRoute() ? <IoMdRadio /> : <MdLaptopMac />}</span>
        </div> */}
        <div className={"categories_dropdown" + (status ? " active" : "")}>
          <div
            className="current_category"
            onClick={() => setStatus((p) => !p)}
          >
            <div>
              {imageSrc ? (
                <img src={imageSrc} alt="Category" className="category_logo" />
              ) : (
                <FiGrid />
              )}
              <p>
                {lang?.channels?.category_label}:{" "}
                {isActiveRoute()
                  ? categoryRadioName === "All"
                    ? lang?.category_menu?.all
                    : categoryRadioName
                  : categoryName === "All"
                  ? lang?.category_menu?.all
                  : categoryName}
              </p>
            </div>
            <BsChevronDown />
          </div>
          <div className={"all_categories" + (status ? " active" : "")}>
            {isActiveRoute()
              ? categoryRadioName !== "All" && (
                  <div className="single_category" onClick={handleReset}>
                    <div className="img_holder">
                      <FiGrid />
                    </div>
                    <p>{lang.category_menu.all}</p>
                  </div>
                )
              : categoryName !== "All" && (
                  <div className="single_category" onClick={handleReset}>
                    <div className="img_holder">
                      <FiGrid />
                    </div>
                    <p>{lang.category_menu.all}</p>
                  </div>
                )}
            {window.location.pathname === "/channels/epgs" &&
              (isActiveRoute()
                ? categoryRadioName !== "Internet Tv" && (
                    <div className="single_category" onClick={() => {}}>
                      <div className="img_holder">
                        <img src={internettvIcon} alt="Internet tv" />
                      </div>
                      <p>{lang?.category_menu?.internet_tv_category}</p>
                    </div>
                  )
                : categoryName !== "Internet Tv" && (
                    <div className="single_category" onClick={getCountries}>
                      <div className="img_holder">
                        <img src={internettvIcon} alt="Internet tv" />
                      </div>
                      <p>{lang?.category_menu?.internet_tv_category}</p>
                    </div>
                  ))}
            {isActiveRoute()
              ? radioCategories.map(
                  (item, index) =>
                    item.category_name !== categoryRadioName && (
                      <div
                        key={index}
                        className="single_category"
                        onClick={() => handleClick(item)}
                      >
                        <div className="img_holder">
                          <img src={item.icon_src} alt="" />
                        </div>
                        <p>{item.category_name}</p>
                      </div>
                    )
                )
              : categories.map(
                  (item, index) =>
                    item.category_name !== categoryName && (
                      <div
                        key={index}
                        className="single_category"
                        onClick={() => handleClick(item)}
                      >
                        <div className="img_holder">
                          <img src={item.icon_src} alt="" />
                        </div>
                        <p>{item.category_name}</p>
                      </div>
                    )
                )}
          </div>
        </div>
        <div className="channels_track_buttons">
          {localStorage.jwtToken && (
            <Link
              to="/channels/nowontv"
              // onClick={handleReset}
              className={activeClass("nowontv")}
            >
              {language.now_on_tv}
            </Link>
          )}
          <Link
            to="/channels/epgs"
            onClick={handleReset}
            className={activeClass("epgs")}
          >
            {language && language.channels}
          </Link>
          <Link to="/channels/radios" className={activeClass("radios")}>
            {language && language.radios}
          </Link>
        </div>
        {!chosenCountryChannels && (
          <div className="channels_track_list_icons">
            <div className={activeGrid(0)} onClick={() => setGrid(0)}>
              <IoIosList />
            </div>
            <div className={activeGrid(1)} onClick={() => setGrid(1)}>
              <FiGrid />
            </div>
          </div>
        )}
      </div>
      {/* {isActiveRoute() ? (
        <Categories
          activeCategories={radioCategories}
          status={status}
          close={() => setStatus(false)}
          data={{ name: "All" }}
        />
      ) : (
        <Categories
          activeCategories={categories}
          status={status}
          close={() => setStatus(false)}
          data={{ name: "All" }}
        />
      )} */}
    </>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  categories: state.categories,
  radioCategories: state.radioCategories,
  categoryName: state.categoryName,
  categoryRadioName: state.categoryRadioName,
});

export default connect(mapStateToProps, {
  setCategories,
  setRadioCategories,
  setCategoryRadioName,
  setRadioCategoryId,
  setCategoryName,
  setChannelsParams,
  setLoader,
  setChannels,
  setChannelsCountries,
})(Track);
