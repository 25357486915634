import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { FaLock, FaStar, FaRegStar, FaPlay } from "react-icons/fa";
import { utility } from "../../../../helpers/Utility";
import { Services } from "../../../../services/Services";
import { defaultSetup } from "../../../../helpers/locked";
import {
  setFavoritesChannels,
  setTVchannelActiveChannel,
  setLoader,
  setPlayerVisible,
  setPinCodeModal,
  setChannels,
  setEpgs,
} from "../../../../redux/actions";
import { ReactComponent as CatchUpIcon } from "../../../../images/catchUp.svg";
import "./Menu.css";

const moment = require("moment-timezone");
const timezone = moment.tz.guess();

const Menu = ({
  channels,
  lang,
  favorites,
  channelsParams,
  pincode,
  locked,
  activeChannel,
  setActiveEpgs,
  setChannels,
  setEpgs,
  setLoader,
  setPinCodeModal,
  setFavoritesChannels,
  setTVchannelActiveChannel,
  setPlayerVisible,
  countries,
  chosenCountry,
  setChosenCountry,
}) => {
  const [result, setResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isLockedChannel, setIsLockedChannel] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [openLiveTry, setOpenLiveTry] = useState(false);
  const [localFavorites, setLocalFavorites] = useState(favorites);

  useEffect(() => {
    return () => {
      setTVchannelActiveChannel([]);
    };
    // eslint-disable-next-line
  }, []);

  const fetchFavorite = useCallback(() => {
    if (localStorage.jwtToken) {
      Services.getFavorites({ favorite: "" }).then((res) => {
        setFavoritesChannels(res.data.channels);
        setLocalFavorites(res.data.channels);
      });
    }
  }, [setFavoritesChannels]);

  useEffect(() => {
    fetchFavorite();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchValue.length === 0) {
      if (chosenCountry) {
        setResult(countries);
      } else {
        setResult(channels);
      }
    }
  }, [searchValue, channels, countries, chosenCountry]);

  useEffect(() => {
    setActiveItem(null);
  }, [channelsParams]);

  useEffect(() => {
    defaultSetup(
      // locked,
      channels,
      setTVchannelActiveChannel,
      setActiveEpgs,
      activeItem,
      setActiveItem
    );
    // eslint-disable-next-line
  }, [channels]);

  const activeClass = (item) =>
    activeChannel.id === item.id ? "item active" : "item";

  const activeCountryClass = (item) =>
    chosenCountry && chosenCountry.id === item.id ? "item active" : "item";

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (chosenCountry) {
      setResult(
        countries.filter(
          (country) =>
            country.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
            -1
        )
      );
    } else {
      setResult(
        channels.filter(
          (channel) =>
            channel.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
            -1
        )
      );
    }
  };

  // const fetchChannels = () => {
  //   let param = { filter: "", category: "", sort: "", favorite: "", epgs: 1 };
  //   if (localStorage.jwtToken) {
  //     Services.getChannels(param).then((res) => {
  //       setChannels(res.data);
  //       setLoader(false);
  //     });
  //   } else {
  //     Services.getDemoChannels(param).then((res) => {
  //       setChannels(res.data);
  //       setLoader(false);
  //     });
  //   }
  // };

  const toggleFavorite = (e, item, type) => {
    // if (item.stream_path) setLoader(true);
    e.stopPropagation();
    let obj = {};
    if (type === "addFavorite") obj = { channel_id: item.id };
    else obj = { m3u_channel_id: item.id };
    Services[type](obj).then(() => {
      // fetchFavorite();
      // if (item.stream_path) fetchChannels();
      if (type === "addFavorite") {
        setLocalFavorites((p) => [...p, item]);
      } else {
        const arr = localFavorites.filter((elem) => elem.id !== item.id);
        setLocalFavorites(arr);
      }
    });
  };

  useEffect(() => {
    if (pincode === "success") {
      if (openLiveTry) {
        openLive(isLockedChannel);
      } else {
        setTVchannelActiveChannel(isLockedChannel);
      }
    }
    // eslint-disable-next-line
  }, [pincode, setTVchannelActiveChannel, isLockedChannel]);

  const handleOpenLive = (channel) => {
    if (channel.locked) {
      setPinCodeModal("opened");
      setIsLockedChannel(channel);
      setOpenLiveTry(true);
    } else {
      openLive(channel);
    }
  };

  const openLive = (channel) => {
    setOpenLiveTry(false);
    setPlayerVisible({ channels, channel, epg: channel.current_epg });
    setTVchannelActiveChannel(channel);
    setActiveItem(channel);
    if (localStorage.jwtToken) {
      Services.getEpgs({ channel_id: channel.id }).then((res) =>
        setEpgs(res.data)
      );
    } else {
      Services.getDemoEpgs({ channel_id: channel.id }).then((res) =>
        setEpgs(res.data)
      );
    }
  };

  const handleClick = (channel) => {
    let now = moment.tz(new Date(), utility.timezone).tz(timezone).format();
    let limitTime = moment
      .tz(localStorage.is_locked_time, utility.timezone)
      .add(1, "hour")
      .tz(timezone)
      .format();

    const allLocked = localStorage.getItem("all_locked");

    if (channel.locked && allLocked === "false") {
      setPinCodeModal("opened");
      setIsLockedChannel(channel);
    } else {
      // if (channel.stream_path) {
      //   setPlayerVisible({ channels, channel, epg: channel.current_epg });
      //   setTVchannelActiveChannel(channel);
      //   setActiveItem(channel);
      //   setEpgs([]);
      // } else {
      if (localStorage.is_locked_time && limitTime < now) {
        localStorage.removeItem("is_locked_time");
        localStorage.setItem("all_locked", false);
      } else {
        if (localStorage.all_locked === "true") {
          if (activeChannel === channel) {
            setPlayerVisible({ channels, channel, epg: channel.current_epg });
            if (localStorage.jwtToken) {
              Services.getEpgs({ channel_id: channel.id }).then((res) =>
                setEpgs(res.data)
              );
            } else {
              Services.getDemoEpgs({ channel_id: channel.id }).then((res) =>
                setEpgs(res.data)
              );
            }
          } else {
            setTVchannelActiveChannel(channel);
            setActiveItem(channel);
          }
        } else {
          if (activeChannel === channel) {
            setPlayerVisible({ channels, channel, epg: channel.current_epg });
            if (localStorage.jwtToken) {
              Services.getEpgs({ channel_id: channel.id }).then((res) =>
                setEpgs(res.data)
              );
            } else {
              Services.getDemoEpgs({ channel_id: channel.id }).then((res) =>
                setEpgs(res.data)
              );
            }
          } else {
            isLocked(channel);
          }
        }
      }
      // }
    }
  };

  const isLocked = (channel) => {
    if (channel.locked) {
      setPinCodeModal("opened");
      setIsLockedChannel(channel);
      return true;
    } else {
      setTVchannelActiveChannel(channel);
      setActiveItem(channel);
      return false;
    }
  };

  return (
    <div className="channels_menu">
      <div className="channels_menu_holder">
        <div className="input_holder">
          <input
            type="text"
            placeholder={lang.search && lang.search.placeholder}
            onChange={handleSearch}
          />
        </div>
        <div className="items">
          {countries && countries.length > 0
            ? result.map((item, index) => (
                <div
                  key={`${item.id}-c-${index}`}
                  onClick={() => setChosenCountry(item)}
                  className={activeCountryClass(item)}
                >
                  <div className="left">
                    {item.flag && <img src={item.flag} alt="img" />}
                    <p>{item.name}</p>
                  </div>
                </div>
              ))
            : result.length > 0 &&
              result.map((item, index) => (
                <div
                  key={`${item.id}-${index}`}
                  onClick={() => handleClick(item)}
                  className={activeClass(item)}
                >
                  <div className="left">
                    {item.stream_icon && (
                      <img src={item.stream_icon} alt="img" />
                    )}
                    <p>
                      {item.name.length > 22
                        ? item.name.substring(0, 22) + "..."
                        : item.name}
                    </p>
                  </div>
                  <div className="right">
                    {item.tv_archive_duration > 0 && (
                      <CatchUpIcon className="history_svg" />
                    )}
                    {item.locked && <FaLock className="lock" />}
                    {/* {!item.locked && ( */}
                    <div
                      className="play-live"
                      onClick={() => handleOpenLive(item)}
                    >
                      <FaPlay className="play" />
                    </div>
                    {/* )} */}

                    {localStorage.jwtToken &&
                      (localFavorites.find((el) => el.id === item.id) ? (
                        <FaStar
                          onClick={(e) =>
                            toggleFavorite(e, item, "removeFavorite")
                          }
                          className="yellow"
                        />
                      ) : (
                        <FaRegStar
                          onClick={(e) =>
                            toggleFavorite(e, item, "addFavorite")
                          }
                        />
                      ))}
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  channels: state.channels,
  favorites: state.favoritesChannels,
  locked: state.lockedCategories,
  pincode: state.pinCodeModal,
  activeChannel: state.tvChannelsActiveChannel,
  channelsParams: state.channelsParams,
});

export default connect(mapStateToProps, {
  setFavoritesChannels,
  setPinCodeModal,
  setChannels,
  setLoader,
  setTVchannelActiveChannel,
  setEpgs,
  setPlayerVisible,
})(Menu);
