import React, { useState } from "react";
import "./SingleMovie.css";
import { RiPlayCircleLine, RiHeartLine } from "react-icons/ri";
import { ReactComponent as AddToFav } from "../../../images/addToFavorites.svg";
import { AiOutlineEye, AiOutlineClose } from "react-icons/ai";
import { Services } from "../../../services/Services";

const SingleMovie = ({
  item,
  close,
  handleClick,
  watchedLabel,
  favLabel,
  addToFavLabel,
	movies,
}) => {
  const [isWatched, setIsWatched] = useState(item?.is_watched);
  const [isFavorite, setIsFavorite] = useState(item?.is_favorite);

  const toggleWatched = () => {
    Services.toggleWatchedMovie(item.id, isWatched ? false : true).then(
      () => {
        setIsWatched(isWatched ? false : true);
				movies.find((elem) => elem.id === item.id).is_watched = isWatched ? false : true;
      }
    );
	};
	
	const toggleFavorite = () => {
		if (isFavorite) {
			Services.removeFavorite({ vod_id: item.id }).then(() => {
				setIsFavorite(false);
				movies.find((elem) => elem.id === item.id).is_favorite = false;
			})
		} else {
			Services.addFavorite({ vod_id: item.id }).then(() => {
				setIsFavorite(true);
				movies.find((elem) => elem.id === item.id).is_favorite = true;
			})
		}
	}

  return (
    <div className="SingleMovie" onClick={close}>
      <div className="movieContent" onClick={(e) => e.stopPropagation()}>
        <div className="movieTop">
          <img src={item.poster} alt="Movie" />
          <div
            className="play-single-movie"
            onClick={() => handleClick(item.src, item.poster)}
          >
            <RiPlayCircleLine />
          </div>
          {isWatched && (
            <div className="watched-single">
              <AiOutlineEye /> <span>{watchedLabel}</span>
            </div>
          )}
          <div className="close-movie" onClick={close}>
            <AiOutlineClose />
          </div>
          <h2>
            {item.name} ({item.release_date.substring(0, 4)})
          </h2>
        </div>
        <div className="movieButtons">
          <button className={isFavorite ? " active" : ""} onClick={toggleFavorite}>
            {isFavorite ? <RiHeartLine /> : <AddToFav />}
            <p>{isFavorite ? favLabel : addToFavLabel}</p>
          </button>
          <button
            className={isWatched ? " active" : ""}
            onClick={toggleWatched}
          >
            <AiOutlineEye />
            <p>{watchedLabel}</p>
          </button>
        </div>
        <div className="movieDescription">{item.plot}</div>
      </div>
    </div>
  );
};

export default SingleMovie;
