import React from 'react';
import { connect } from 'react-redux';

const ErrorPopup = ({ closePopup, lang }) => {
  const language = lang.internet_channels.add_m3u8_list;
  return (
    <div className='mini_popup' onClick={closePopup}>
      <div className='mini_popup_holder' onClick={e => e.stopPropagation()}>
        <h2>{language.error_title}</h2>
        <p>{language.error_text}</p>
        <button onClick={closePopup}>{language.success_btn}</button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage
});

export default connect(mapStateToProps)(ErrorPopup);
