// import dependencies
import React from "react";
import ReactPlayer from "react-player";
// import { FaExpand } from "react-icons/fa";

// import components
import AdBanner from "../../AdBanner/AdBanner";
import FloatingText from "../../FloatingText/FloatingText";

// import css
import "./VideoPlayer.css";

const VideoPlayer = ({
  url,
  playing = false,
  channels,
  activeChannel,
  playChannel,
  fullScreenMode
}) => {
  // methods
  // const toggleFullScreen = (event) => {
    // console.log(window.screen);
    // console.log(window.screen.orientation);
    // const orientation = window.screen.orientation;
    // console.log("orientation:", orientation); 
    // window.screen.orientation.lock('landscape');
  // };

  return (
    <div className={fullScreenMode ? "FullScreenPlayerContainer" : "PlayerContainer"}>
      <ReactPlayer
        url={url}
        playing={playing}
        // controls={fullScreenMode}
        controls={false}
        width='100%'
        height='100%'
        className="Player"
        config={{
          file: {
            attributes: {
              style: {
                width: "100%",
                height: "100%",
                objectFit: "cover",
              },
            },
          },
        }}
      />

      <AdBanner />

      {
        fullScreenMode ? (
          <>
            <div className="SideBarChannels">
              {channels.map((item, index) =>
                <div
                  key={index.toString()}
                  onClick={playChannel(item)}
                  className={activeChannel.id === item.id ? "ChannelItem Active" : "ChannelItem"}
                >
                    {item.stream_icon ? (
                      <img src={item.stream_icon} alt="ChannelImage" />
                    ) : null}
                </div>
              )}
            </div>
          </>
        ) : (
          null
        ) 
      }

      <FloatingText />

      {/* <div onClick={toggleFullScreen} className="ButtonContainer">
        <FaExpand />
    </div>*/}
    </div>
  )
};

export default VideoPlayer;