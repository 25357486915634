import React, { useState, useEffect } from "react";
import { ReactComponent as ReloadIcon } from "../../images/reload.svg";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import "./TermsContitionsModal.css";
const TermsContitionsModal = ({
  termsAndConditions,
  getIp,
  activeLang,
  confirmButton,
  setIsTermsModalOpen,
  logout
}) => {

  const [infoData, setInfoData] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setInfoData(termsAndConditions.text_sections);
  }, [termsAndConditions]);

  const signOut = () => {
    logout()
    .then((res) => {
      // console.log('res ', res)
    })
    .catch((err) => {
      console.log("error ", err);
    });

    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <div className='toc'>
      <div className='bg' />
      <div className='TermsContitionsModal'>
        <div className='TermsContitionsModal-body'>
          {infoData && infoData.length > 1
            ? infoData
                .filter((obj) => obj.position === page)
                .map((element, index) => {
                  return (
                    <div
                      key={element.position}
                      dangerouslySetInnerHTML={{ __html: element.text }}
                    />
                  );
                })
            : infoData.map((element) => (
                <div
                  key={element.position}
                  dangerouslySetInnerHTML={{ __html: element.text }}
                />
              ))}
        </div>
        <div className='TermsContitionsModal-footer'>
          <div
            className='modal-decline'
            onClick={() =>
              confirmButton ? signOut() : setIsTermsModalOpen(false)
            }>
            <ReloadIcon /> {activeLang.guide.decline_btn}
          </div>
          {infoData && infoData.length > 1 && (
            <>
              <div className='middleBtns'>
                <div
                  className='btn-control'
                  onClick={() => {
                    if (page > 1) {
                      setPage((p) => p - 1);
                    }
                  }}>
                  <AiOutlineCaretLeft />
                </div>
                <div className='current-page'>
                  {page + " of " + infoData.length}
                </div>
                <div
                  className='btn-control'
                  onClick={() => {
                    if (page < infoData.length) {
                      setPage((p) => p + 1);
                    }
                  }}>
                  <AiOutlineCaretRight />
                </div>
              </div>
            </>
          )}

          {confirmButton && (
            <div
              className='modal-accept'
              onClick={() => {
                getIp();
              }}>
              {activeLang.guide.accept_btn}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TermsContitionsModal;
