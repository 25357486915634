import React, { Component } from "react";
import "./RadioPlayer.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { connect } from "react-redux";
import RadioPlayerSlider from "./RadioPlayerSlider";
import ReactPlayer from "react-player";
import Play from "../../images/player_icons/play.png";
import Pause from "../../images/player_icons/pause.png";
import Volume from "../../images/player_icons/volume.png";
import Mute from "../../images/player_icons/mute.png";
import { MdClose } from "react-icons/md";

var timeout = null;
class RadioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeData: this.props.data,
      activeSource: this.props.data.stream_source,
      hidden: false,
      playIcon: false,
      volume: 1,
      volumeTrack: false,
      rangeStatus: false,
      muteVolume: false,
      loader: true,
    };
  }

  componentWillUnmount() {
    document.querySelector("body").removeAttribute("style");
  }

  UNSAFE_componentWillReceiveProps(prevProps) {
    if (!this.state.loader) this.setState({ loader: true });

    this.setState({
      activeData: prevProps.data,
      activeSource: prevProps.data.stream_source,
      loader: false,
    });
  }

  successState = () => this.setState({ loader: false });

  setActiveData = (data) => this.setState({ activeData: data });

  changeSource = (item) => this.setState({ activeSource: item });

  onMouseMove = () => {
    this.setState({
      hidden: false,
    });
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      this.setState({
        hidden: true,
      });
    }, 3000);
  };

  onMouseOverCapture = () => {
    this.setState({
      hidden: false,
    });
    clearTimeout(timeout);
  };

  // Controls
  onStart = () => this.setState({ playIcon: false });

  onPause = () => this.setState({ playIcon: true });

  playToggle = () => {
    let videoTag = document.querySelector(".radio_player video");
    let audioTag = document.querySelector(".radio_player audio");
    if (this.state.playIcon) {
      this.onStart();
      if (videoTag !== null) {
        videoTag.play();
      } else {
        audioTag.play();
      }
    } else {
      this.onPause();
      if (videoTag !== null) {
        videoTag.pause();
      } else {
        audioTag.pause();
      }
    }
  };

  changeVolume = (e) => {
    e.stopPropagation();
    this.setState({ volume: e.target.value });
  };

  muteVolume = () => this.setState({ volume: 0, muteVolume: true });

  maxVolume = () => this.setState({ volume: 1, muteVolume: false });

  volumeToMax = () => this.setState({ volume: 1 });

  volumeMute = () => this.setState({ volume: 0 });

  setHiddenToSliderMove = () => this.setState({ hidden: false });

  styles = () => {
    if (this.state.hidden) {
      if (this.props.isActive) {
        return "radio_player hidden_player fadeout";
      } else {
        return "radio_player fadeout";
      }
    } else {
      if (this.props.isActive) {
        return "radio_player hidden_player";
      } else {
        return "radio_player";
      }
    }
  };

  closeRadioPlayer = () => {
    this.props.closeFullScreen();
  };

  render() {
    const { setIndex, setActive } = this.props;
    const { playIcon, volume, loader, activeData, activeSource } = this.state;

    return (
      <div className={this.styles()} onMouseMove={this.onMouseMove}>
        <div className="radio_player_holder">
          <ReactPlayer
            ref={(player) => (this.player = player)}
            key={activeSource}
            url={activeSource}
            playing
            onReady={this.successState}
            onStart={this.onStart}
            onPause={this.onPause}
            volume={volume}
          />

          <div onClick={this.closeRadioPlayer} className="close-player-icon">
            <MdClose size={50} />
          </div>

          <div
            className="left_slider"
            onMouseOverCapture={this.onMouseOverCapture}
          >
            <RadioPlayerSlider
              data={activeData}
              setData={this.setActiveData}
              setIndex={setIndex}
              setActive={setActive}
              changeSource={this.changeSource}
            />
          </div>

          {loader ? (
            <div className="player_loader" onClick={(e) => e.stopPropagation()}>
              <AiOutlineLoading3Quarters />
            </div>
          ) : null}

          <div className="radio_player_center_info">
            <div
              className="radio_player_center_info_img"
              style={{ background: `url(${activeData.stream_icon})` }}
            />
            <h5>You are listening</h5>
            <p>{activeData.name}</p>
          </div>

          <div className="radio_player_controls">
            <div className="radio_player_mini_info">
              <div className="radio_player_mini_info_img">
                <img src={activeData.stream_icon} alt="stream_icon" />
              </div>
              <h3>{activeData.name}</h3>
            </div>
            <div className="volume_holder">
              {volume > 0 ? (
                <img src={Volume} alt="volume" onClick={this.volumeMute} />
              ) : (
                <img src={Mute} alt="volume" onClick={this.volumeToMax} />
              )}
              <div className="range_holder">
                <div className="range_wrap">
                  <input
                    type="range"
                    min={0}
                    max={1}
                    value={volume}
                    step={0.1}
                    onClick={(e) => e.stopPropagation()}
                    onChange={this.changeVolume}
                  />
                </div>
              </div>
            </div>
          </div>
          {playIcon ? (
            <img
              src={Play}
              alt="volume"
              className="play"
              onClick={this.playToggle}
            />
          ) : (
            <img
              src={Pause}
              alt="volume"
              className="play"
              onClick={this.playToggle}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(RadioPlayer);
