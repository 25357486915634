import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { FaLock } from "react-icons/fa";
import { utility } from "../../../helpers/Utility";
import { Services } from "../../../services/Services";
import {
  setPlayerVisible,
  setTVchannelActiveChannel,
  setLeftSliderPin,
  setMenuSelectedChannel,
  setM3u8ActiveChannel,
  setEpgs,
  setInternetTvChannels,
  setInternetTvPage,
} from "../../../redux/actions";
// import ArrowImg from "../../../images/arrow.png";
import "./LeftSliderStyle.css";
import { scrollIntoView } from "../../../helpers/scrollIntoView";
import PaginationLoader from "../../PaginationLoader/PaginationLoader";
import { paginationOnEndReach } from "../../../helpers/paginationOnEndReach";
import { ReactComponent as CatchUpIcon } from "../../../images/catchUp.svg";

const moment = require("moment-timezone");
const timezone = moment.tz.guess();

let per_page = 50;

const LeftSlider = ({
  data,
  epgs,
  locked,
  setPlayerVisible,
  fullscreen,
  setM3u8ActiveChannel,
  setTVchannelActiveChannel,
  pincode,
  setLeftSliderPin,
  setEpgs,
}) => {
  const dispatch = useDispatch();
  const channelsFromStore = useSelector((state) => state.internetTvChannels);
  const page = useSelector((state) => state.internettvPage);
  const [lastPage, setLastPage] = useState(10);
  const [loading, setLoading] = useState(false);
  // to ignore first render when we already have channels fetched in store
  const [isMount, setIsMount] = useState(true);
  // to center only on player opening, not on pagination
  const [centered, setCentered] = useState(false);

  const { channel, fromInternetTv } = data;
  const channels = fromInternetTv ? channelsFromStore : data.channels;
  const [activePosition, setActivePosition] = useState(0);
  const [isLockedChannel, setIsLockedChannel] = useState(null);
  const currentElemRef = useRef(null);
  const sliderHolderRef = useRef(null);

  const getMoreChannels = () => {
    if (page + 1 <= lastPage && !loading) {
      dispatch(setInternetTvPage(page + 1));
    }
  };

  const handlePaginationScroll = (e) => {
    if (fromInternetTv) {
      paginationOnEndReach(e, getMoreChannels);
    }
  };

  const setInternetChannels = (data) => {
    dispatch(setInternetTvChannels(data));
  };

  const fetchAPI = () => {
    setLoading(true);
    Services.m3u8channelsPagination(per_page, page).then((res) => {
      setInternetChannels(channels.concat(res.data.data));
      setLastPage(res.data.meta.last_page);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (channels.length === 0) {
      setIsMount(false);
    } else if (isMount) {
      setIsMount(false);
      return;
    }
    fetchAPI();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (fullscreen) {
      let itemsNumber = Math.floor(window.innerHeight / 140);
      let limit = (channels.length - itemsNumber) * 140;
      if (activePosition < 0) {
        if (activePosition > -limit) setActivePosition(activePosition + 140);
      }
    }
    // eslint-disable-next-line
  }, [fullscreen, channels, activePosition]);

  useEffect(() => {
    if (channels && currentElemRef.current && !centered) {
      scrollIntoView(currentElemRef.current);
      setCentered(true);
    }
    // eslint-disable-next-line
  }, [channels]);

  useEffect(() => {
    if (pincode === "success") {
      setPlayerVisible({
        channels,
        channel: isLockedChannel,
        epgs,
        epg: isLockedChannel.current_epg,
      });
      setTVchannelActiveChannel(isLockedChannel);
      if (localStorage.jwtToken) {
        Services.getEpgs({ channel_id: isLockedChannel.id }).then((res) =>
          setEpgs(res.data)
        );
      } else {
        Services.getDemoEpgs({ channel_id: isLockedChannel.id }).then((res) =>
          setEpgs(res.data)
        );
      }
    }
    // eslint-disable-next-line
  }, [
    pincode,
    setTVchannelActiveChannel,
    isLockedChannel,
    setPlayerVisible,
    channels,
    epgs,
  ]);

  const handleScroll = (e) => {
    if (e.deltaY.toString().includes("-")) slideUp();
    else slideDown(e);
  };

  const slideUp = () => {
    // e.stopPropagation();
    if (activePosition === 40) setActivePosition(0);
    if (activePosition < 0) setActivePosition(activePosition + 140);
  };

  const slideDown = () => {
    // e.stopPropagation();
    let itemsNumber = Math.floor(window.innerHeight / 140);
    let limit = (channels.length - itemsNumber) * 140;
    if (activePosition > -limit + 70) {
      if (activePosition === -limit + 140)
        setActivePosition(activePosition - 100);
      else setActivePosition(activePosition - 140);
    }
  };

  const classes = (id) => (id === channel.id ? "item active" : "item");

  const handleClick = (item) => {
    let now = moment.tz(new Date(), utility.timezone).tz(timezone).format();
    let limitTime = moment
      .tz(localStorage.is_locked_time, utility.timezone)
      .add(1, "hour")
      .tz(timezone)
      .format();
    const allLocked = localStorage.getItem("all_locked");

    if (channel.locked && allLocked === "false") {
      setLeftSliderPin("opened");
      setIsLockedChannel(channel);
    } else {
      // if (item.stream_path) {
      //   setM3u8ActiveChannel(item);
      //   openPlayer(item);
      //   setEpgs([]);
      // } else {
      if (localStorage.is_locked_time && limitTime < now) {
        localStorage.removeItem("is_locked_time");
        localStorage.setItem("all_locked", false);
      } else {
        if (localStorage.all_locked === "true") {
          openPlayer(item);
          if (localStorage.jwtToken) {
            Services.getEpgs({ channel_id: item.id }).then((res) =>
              setEpgs(res.data)
            );
          } else {
            Services.getDemoEpgs({ channel_id: item.id }).then((res) =>
              setEpgs(res.data)
            );
          }
        } else isLocked(item);
      }
      // }
    }
  };

  const isLocked = (item) => {
    if (item.locked) {
      setLeftSliderPin("opened");
      setIsLockedChannel(item);
    } else {
      openPlayer(item);
      if (localStorage.jwtToken) {
        Services.getEpgs({ channel_id: item.id }).then((res) =>
          setEpgs(res.data)
        );
      } else {
        Services.getDemoEpgs({ channel_id: item.id }).then((res) =>
          setEpgs(res.data)
        );
      }
    }
  };

  const openPlayer = (item) => {
    if (window.location.pathname === "/channels/epgs")
      setTVchannelActiveChannel(item);
    setPlayerVisible({ channels, channel: item, epgs, epg: item.current_epg });
  };

  return (
    <>
      <div
        className="left_slider"
        onWheel={handleScroll}
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        onScroll={(e) => handlePaginationScroll(e)}
      >
        <div
          ref={sliderHolderRef}
          className="left_slider_holder"
          style={{ transform: `translateY(${activePosition}px)` }}
        >
          {channels.map((item, index) => (
            <div
              id={channel.id === item.id ? "currentChannel" : ""}
              key={`${item.id}-${index}`}
              ref={channel.id === item.id ? currentElemRef : null}
              onClick={() => handleClick(item)}
              className={classes(item.id)}
            >
              {item.stream_icon && <img src={item.stream_icon} alt="icon" />}
              <div className="right">
                {item.tv_archive_duration > 0 && (
                  <CatchUpIcon className="left-slider-catchup" />
                )}
                {locked && locked.find((el) => el === item.category_id) && (
                  <FaLock />
                )}
              </div>
            </div>
          ))}
          {loading && (
            <div style={{ position: "relative", height: 60 }}>
              <PaginationLoader />
            </div>
          )}
        </div>
      </div>
      {/* <div className="arrow top" onClick={ slideUp}>
        <img src={ArrowImg} alt="ArrowImg" />
      </div>
      <div className="arrow bottom" onClick={slideDown}>
        <img src={ArrowImg} alt="ArrowImg" />
      </div> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  locked: state.lockedCategories,
  pincode: state.leftSliderPin,
  epgs: state.epgs,
});

export default connect(mapStateToProps, {
  setPlayerVisible,
  setMenuSelectedChannel,
  setM3u8ActiveChannel,
  setTVchannelActiveChannel,
  setLeftSliderPin,
  setEpgs,
})(LeftSlider);
