// import dependencies
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// import components
import BottomNav from "../../../../components/Unauthorized/Mobile/BottomNav/BottomNav";
import Channels from "../../../../components/Unauthorized/Mobile/Channels/Channels";
import Nav from "../../../../components/Unauthorized/Mobile/Nav/Nav";
import VideoPlayer from "../../../../components/Unauthorized/Mobile/VideoPlayer/VideoPlayer";

// import services
import { Services } from "../../../../services/Services";

// import css
import "./Home.css";

const Home = ({ channels }) => {
  // state
  const [activeChannel, setActiveChannel] = useState(null);
  const [channelsEpgs, setChannelsEpgs] = useState([]);
  const [currentEpg, setCurrentEpg] = useState(null);
  const [playerUrl, setPlayerUrl] = useState("");
  const [playing, setPlaying] = useState(false);
  // { angle: 0 | 90, type: portrait-primary | landscape-primary }
  const [orientation, setOrientation] = useState(0);
  const [activeCategory, setActiveCategory] = useState({});

  useEffect(() => {
    const initChannel = channels[0];

    if (initChannel) {
      setActiveChannel(initChannel);
      setCurrentEpg(initChannel.current_epg);
      setPlayerUrl(initChannel.src);

      Services.getDemoEpgs({ channel_id: initChannel.id }).then((res) => {
        setChannelsEpgs(res.data);
      });
    }
  }, [channels]);

  useEffect(() => {}, [activeCategory]);

  useEffect(() => {
    if ("onorientationchange" in window) {
      window.addEventListener("orientationchange", handleOrientationChange);
    } else {
      window.addEventListener("resize", handleResizeChange);
    }

    return () => {
      if ("onorientationchange" in window) {
        window.removeEventListener(
          "orientationchange",
          handleOrientationChange
        );
      } else {
        window.removeEventListener("resize", handleResizeChange);
      }
    };
  }, []);

  const handleOrientationChange = (event) => {
    const { angle } = event.target.screen.orientation;
    setOrientation(angle);
  };

  const handleResizeChange = (event) => {
    if (window.innerHeight < window.innerWidth) {
      setOrientation(90);
    } else {
      setOrientation(0);
    }
  };

  const playChannel = (channel) => () => {
    if (playing && channel.id === activeChannel.id) {
      setActiveChannel(null);
      setCurrentEpg(channel.current_epg);
      setPlaying(false);
      setPlayerUrl(channel.src);
    } else {
      setActiveChannel(channel);
      setCurrentEpg(channel.current_epg);
      setPlaying(true);
      setPlayerUrl(channel.src);

      Services.getDemoEpgs({ channel_id: channel.id }).then((res) => {
        setChannelsEpgs(res.data);
      });
    }
  };

  const playEpg = (epg) => () => {
    if (epg.src) {
      setCurrentEpg(epg);
      setPlaying(true);
      setPlayerUrl(epg.src);
    }
  };

  return (
    <div className="Home">
      <Nav />

      <VideoPlayer
        url={playerUrl}
        playing={playing}
        fullScreenMode={orientation !== 0 && playing}
        channels={channels}
        activeChannel={activeChannel}
        playChannel={playChannel}
      />

      <Channels
        channels={channels}
        channelsEpgs={channelsEpgs}
        activeChannel={activeChannel}
        currentEpg={currentEpg}
        playChannel={playChannel}
        playEpg={playEpg}
        activeCategory={activeCategory}
        hidden={orientation !== 0 && playing}
      />

      <BottomNav
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        hidden={orientation !== 0 && playing}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  channels: state.channels,
});

export default connect(mapStateToProps)(Home);
