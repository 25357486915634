import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "./Carousel/Carousel";
import RadioSlider from "./RadioSlider/RadioSlider";
import InfoTab from "../../components/InfoTab/InfoTab";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Services } from "../../services/Services";
import { setSelectedLanguage } from "../../redux/actions";

const Home = () => {
  const dispatch = useDispatch();
  const [opacity, setOpacity] = useState(1);
  const [displayModal, setDisplayModal] = useState(true);
  const [showedGeneralBanner] = useState(
    localStorage.getItem("showed_general_banner")
  );

  useEffect(() => {
    if (lg.length === 0 && !localStorage.jwtToken) {
      Services.getDemoLanguage().then((res) =>
        dispatch(setSelectedLanguage(res.data.language))
      );
    }
    // eslint-disable-next-line
  }, []);

  const lg = useSelector((state) => state.selectedLanguage);

  const onWheel = (e) => {
    if (e.deltaY > 0) {
      if (opacity > 0.7) setOpacity(opacity - 0.1);
    } else {
      if (opacity < 1) setOpacity(opacity + 0.1);
    }
  };

  let infoTab = null;

  if (
    localStorage.getItem("show_guide_banner") === "true" &&
    showedGeneralBanner === "false" &&
    displayModal
  ) {
    infoTab = <InfoTab close={() => setDisplayModal(false)} type="General" />;
  }

  const content = localStorage.jwtToken ? (
    <div className="home_screen" onWheel={onWheel}>
      {infoTab}
      <Carousel opacity={opacity} />
      <RadioSlider />
    </div>
  ) : (
    <>
      <Navbar />
      <div className="content">
        <div className="home_screen" onWheel={onWheel}>
          {infoTab}
          <Carousel opacity={opacity} />
          <RadioSlider />
        </div>
      </div>
      <Footer />
    </>
  );

  return content;
};

export default Home;
