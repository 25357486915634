import React, { Component } from "react";
import ArrowImg from "../../images/arrow.png";
import NoLogo from "../../images/nologo.png";
import { connect } from "react-redux";

class RadioPlayerSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
      activePosition: 0,
    };
  }

  UNSAFE_componentWillReceiveProps(prevProps, prevState) {
    if (prevProps.fullscren) {
      let itemsNumber = Math.floor(window.innerHeight / 140);
      let limit = (this.props.radioSliderData.length - itemsNumber) * 140;
      if (this.state.activePosition < 0) {
        if (this.state.activePosition > -limit) {
          this.setState({ activePosition: this.state.activePosition + 140 });
        }
      }
    }
  }

  handleScroll = (e) => {
    let result = e.deltaY;
    let str = result.toString();

    if (str.includes("-")) {
      this.slideUp();
    } else {
      this.slideDown();
    }
  };

  slideUp = () => {
    if (this.state.activePosition === 40) {
      this.setState({ activePosition: 0 });
    }

    if (this.state.activePosition < 0) {
      this.setState({ activePosition: this.state.activePosition + 140 });
    }
  };

  slideDown = () => {
    let itemsNumber = Math.floor(window.innerHeight / 140);
    let limit = (this.props.radioSliderData.length - itemsNumber) * 140;
    if (this.state.activePosition > -limit + 70) {
      if (this.state.activePosition === -limit + 140) {
        this.setState({ activePosition: this.state.activePosition - 100 });
      } else {
        this.setState({ activePosition: this.state.activePosition - 140 });
      }
    }
  };

  handleClick = (item) => {
    this.props.setData(item);
    this.props.setIndex(item.id);
    this.props.setActive(item);
    this.props.changeSource(item.stream_source);
  };

  render() {
    const { radioSliderData, data } = this.props;

    return (
      <div
        className="left_slider"
        onWheel={this.handleScroll}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="left_slider_holder"
          style={{ transform: `translateY(${this.state.activePosition}px)` }}
        >
          {radioSliderData.map((item) => (
            <div
              onClick={() => this.handleClick(item)}
              className={data.id === item.id ? "item active" : "item"}
              key={item.id}
            >
              {item.stream_icon === null ||
              item.stream_icon === "" ||
              item.stream_icon === undefined ? (
                <img src={NoLogo} alt="icon" />
              ) : (
                <img src={item.stream_icon} alt="icon" />
              )}
            </div>
          ))}
        </div>
        <div className="arrow top" onClick={this.slideUp}>
          <img src={ArrowImg} alt="ArrowImg" />
        </div>
        <div className="arrow bottom" onClick={this.slideDown}>
          <img src={ArrowImg} alt="ArrowImg" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(RadioPlayerSlider);
