import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { Services } from "../../../services/Services";

import { connect } from "react-redux";
import { setGuidePosition } from "../../../redux/actions";
import { useEffect } from "react";

const BySearch = ({
  language,
  guidePosition,
  selectedLanguage,
  setGuidePosition,
}) => {
  const [searchedText, setSearchedText] = useState("");
  const [results, setResults] = useState([]);
  const [added, setAdded] = useState([]);

  useEffect(() => {
    if (guidePosition === 6) {
      setSearchedText("Arena Sports");
      fetchChannels("Arena Sports");
    }
  }, [guidePosition]);

  const fetchChannels = (value) => {
    Services.searchInternetChannels({ term: value, limit: 100 }).then((res) => {
      setResults(res.data.channels);
      setAdded(res.data.selected_channels_ids);
    });
  };

  const handleChange = (e) => {
    setSearchedText(e.target.value);
    fetchChannels(e.target.value);
  };

  const toggleClick = (id) => {
    let params = { channels_ids: [id] };
    if (added.includes(id))
      Services.internetChannelsRemove(params).then(() =>
        fetchChannels(searchedText)
      );
    else
      Services.internetChannelsAdd(params).then(() =>
        fetchChannels(searchedText)
      );
  };

  const addChannel = (id) => {
    let params = { channels_ids: [id] };
    if (!added.includes(id))
      Services.internetChannelsAdd(params).then(() => fetchChannels());
  };

  const deactiveGuideTootltips = () => {
    setGuidePosition(0);
    localStorage.setItem("showed_internettv_info", true);
  };

  return (
    <>
      <label>{language.search_by_name_label}</label>
      <input type="text" onChange={handleChange} value={searchedText} />
      <div className="byName_items">
        {results.length ? (
          results.map((item, index) => (
            <div className="item" key={item.id}>
              <div
                className="img"
                style={{ background: `url(${item.stream_icon})` }}
              />
              <p title={item.name}>
                {item.name.length > 20
                  ? item.name.substring(0, 20) + "..."
                  : item.name}
              </p>
              <div className="checkbox" onClick={() => toggleClick(item.id)}>
                {added.find((el) => el === item.id) ? <FaCheck /> : null}
                {index === 0 && guidePosition === 6 ? (
                  <div className="tooltip third">
                    <div className="triangle"></div>
                    <p>
                      {
                        selectedLanguage.guide.internet_channels.add_channels
                          .select_channel_info
                      }
                    </p>
                    <div className="buttons">
                      <button
                        className="skip"
                        onClick={() => deactiveGuideTootltips()}
                      >
                        {selectedLanguage.guide.internet_channels.skip_btn}
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          addChannel(item.id);
                          setGuidePosition(7);
                        }}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ))
        ) : (
          <p>No Result!</p>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: state.countries,
    guidePosition: state.guidePosition,
    selectedLanguage: state.selectedLanguage,
  };
};

const mapDispatchToProps = { setGuidePosition };

export default connect(mapStateToProps, mapDispatchToProps)(BySearch);
