// import dependencies
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// import components
import BottomNav from "../../../../components/Unauthorized/Mobile/BottomNav/BottomNav";
import Nav from "../../../../components/Unauthorized/Mobile/Nav/Nav";
import NewsFeed from "../../../../components/Unauthorized/Mobile/NewsFeed/NewsFeed";

// import services
import { Services } from "../../../../services/Services";

// import actions
import { setNewsFeed } from "../../../../redux/actions";

// import css
import "./News.css";

const News = ({ lang, newsFeed, setNewsFeed }) => {
  // state
  const [countryId, setCountryId] = useState(0);
  const [countries, setCountries] = useState([]);
  const [feedFilters, setFeedFilters] = useState([]);

  useEffect(() => {
    // console.log("News.js render lang.id:", lang.id);
    Services.demo_news_countries().then((res) => {
      const countriesAll = [
        {
          id: 0,
          name: lang.news && lang.news.country_filter_label,
          flag: null,
        },
      ];
      countriesAll.push(...res.data);
      setCountries(countriesAll);

      Services.demo_news_filter(countriesAll[0].id).then((res2) => {
        const newsAll = [
          { id: 0, name: lang.news && lang.news.news_filter_label },
        ];
        newsAll.push(...res2.data);
        setFeedFilters(newsAll);

        Services.demo_news_feed(countriesAll[0].id, newsAll[0].id).then((res3) => {
          setNewsFeed(res3.data);
        });
      });
    });
  }, [lang, setNewsFeed]);

  const handleCountry = (event) => {
    const value = parseInt(event.target.value);

    setCountryId(value);

    if (value !== 0) {
      Services.demo_news_filter(value).then((res2) => {
        const newsAll = [
          { id: 0, name: lang.news && lang.news.news_filter_label },
        ];
        newsAll.push(...res2.data);
        setFeedFilters(newsAll);
      });

      Services.demo_news_feed(value, 0).then((res) => {
        setNewsFeed(res.data);
      });
    } else {
      Services.demo_news_feed(0, 0).then(
        (res3) => {
          setNewsFeed(res3.data);
        }
      );
    }
  };

  const handleFilter = (event) => {
    const value = event.target.value;

    Services.demo_news_feed(countryId, value).then(
      (res3) => {
        setNewsFeed(res3.data);
      }
    );
  };

  return (
    <div className="news-container">
      <Nav />

        <div className="select-header">
          <div className="select-container">
            <select onChange={handleCountry} className="selector">
              {countries.length && countries.map((item) => (
                <option hidden={item && item.id === 0} key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="select-container">
            <select onChange={handleFilter} className="selector">
              {feedFilters.length && feedFilters.map((item) => (
                  <option hidden={item && item.id === 0} key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <NewsFeed newsFeed={newsFeed} />

      <BottomNav />
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  newsFeed: state.newsFeed,
});

export default connect(mapStateToProps, { setNewsFeed })(News);