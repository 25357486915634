import React, { useState } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";

const SingleMovie = ({
  item,
  activeClass,
  handleClick,
  removeToFavorite,
  addToFavorite,
  handleOpenCategories,
}) => {
  const [isFavorite, setIsFavorite] = useState(item?.favorite);
  return (
    <div className={activeClass(item)} onClick={() => handleClick(item)}>
      <div className="left">
        <div className="channel_info">
          {item.stream_icon ? <img src={item.stream_icon} alt="img" /> : null}
          <p>
            {item.name && item.name.length > 18
              ? item.name.substring(0, 18) + "..."
              : item.name}
          </p>
        </div>
        <div className="channel_icons">
          {isFavorite ? (
            <FaStar
              className="yellow"
              onClick={(e) =>
                removeToFavorite(e, item, () => setIsFavorite(false))
              }
            />
          ) : (
            <FaRegStar
              onClick={(e) => addToFavorite(e, item, () => setIsFavorite(true))}
            />
          )}
        </div>
      </div>
      <div className="right">
        <div
          className="add_to_category_btn"
          onClick={(e) => handleOpenCategories(e, item)}
        >
          <MdAddCircle />
        </div>
      </div>
    </div>
  );
};

export default SingleMovie;
