import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FiGrid } from "react-icons/fi";
import { FaSistrix, FaAngleDown } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";
import "./MoviesTrack.css";

function useOutsideAlerter(ref, actiopn) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        actiopn();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [ref]);
}

const MoviesTrack = ({
  chosenCategory,
  handleCategoryChange,
  handleReset,
  handleSearch,
  sortValue,
  setSortValue,
  searchValue,
  sortMovies,
}) => {
  const moviesCategories = useSelector((state) => state.moviesCategories);
  const [imageSrc, setImageSrc] = useState(null);
  const [status, setStatus] = useState(false);
  const lang = useSelector((state) => state.selectedLanguage);
  const [sortVisible, setSortVisible] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideAlerter(dropdownRef, () => setSortVisible(false));

  return (
    <div className="MoviesTrack">
      <div
        className={"categories_dropdown" + (status ? " active" : "")}
        style={{ zIndex: 20 }}
      >
        <div
          className="current_category"
          onClick={() => {
            moviesCategories.length > 0 && setStatus((p) => !p);
          }}
        >
          <div>
            {imageSrc ? (
              <img src={imageSrc} alt="Category" className="category_logo" />
            ) : (
              <FiGrid />
            )}
            <p>
              {lang?.channels?.category_label}:{" "}
              {chosenCategory
                ? chosenCategory.category_name
                : lang.category_menu.all}
            </p>
          </div>
          <BsChevronDown />
        </div>
        <div className={"all_categories" + (status ? " active" : "")}>
          {chosenCategory && (
            <div
              className="single_category"
              onClick={() => {
                handleReset();
                setImageSrc(null);
                setStatus(false);
              }}
            >
              <div className="img_holder">
                <FiGrid />
              </div>
              <p>{lang.category_menu.all}</p>
            </div>
          )}
          {moviesCategories.map(
            (item, index) =>
              item !== chosenCategory && (
                <div
                  key={index}
                  className="single_category"
                  onClick={() => {
                    handleCategoryChange(item);
                    setStatus(false);
                  }}
                >
                  <div className="img_holder">
                    <img src={item.icon_src} alt="" />
                  </div>
                  <p>{item.category_name}</p>
                </div>
              )
          )}
        </div>
      </div>
      <div className="movies-track-right">
        <div className="input-container">
          <FaSistrix className="search_icon" />
          <input
            placeholder={lang.search && lang.search.placeholder}
            type="text"
            onChange={handleSearch}
            value={searchValue}
          />
        </div>
        <div
          className="dropdown"
          ref={dropdownRef}
          onClick={() => setSortVisible(!sortVisible)}
        >
          <p>{sortValue}</p>
          <FaAngleDown className={sortVisible && "rotate"} />
          {sortVisible && (
            <div className="dropdown_options">
              <p
                onClick={() => {
                  sortMovies(-1);
                  setSortVisible(false);
                }}
              >
                {lang.sort.placeholder}
              </p>
              <p
                onClick={() => {
                  sortMovies(0);
                  setSortVisible(false);
                }}
              >
                {lang.sort.asc.name}
              </p>
              <p
                onClick={() => {
                  sortMovies(1);
                  setSortVisible(false);
                }}
              >
                {lang.sort.desc.name}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MoviesTrack;
