import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// Home
import Home from "./views/HomeScreen/Home";
import Search from "./views/Search/Search";
// Channels
import NowOnTV from "./views/Channels/NowOnTV/NowOnTV";
import TVchannels from "./views/Channels/TVchannels/TVchannels";
import Radio from "./views/Channels/Radio/Radio";
// Internet
import InternetTvChannels from "./views/InternetScreen/InternetTvChannels/InternetTvChannels";
import M3U8lists from "./views/InternetScreen/M3U8lists/M3U8lists";
import AddTVchannels from "./views/InternetScreen/AddTVchannels/AddTVchannels";
import AddM3U8list from "./views/InternetScreen/AddM3U8list/AddM3U8list";
// News
import News from "./views/News/News";
// Favorites
import ChannelsFavorites from "./views/FavoriteScreen/ChannelsFavorites";
import RadiosFavorites from "./views/FavoriteScreen/RadiosFavorites";
import MoviesFavorites from "./views/FavoriteScreen/MoviesFavorites";
// Settings
import Account from "./views/Settings/Account/Account";
import Languages from "./views/Settings/Languages/Languages";
import PinCode from "./views/Settings/PinCode/PinCode";
import Contact from "./views/Settings/Contact/Contact";
import Help from "./views/Settings/Help/Help.jsx";
import Movies from "./views/Movies/Movies";
import AddedMovies from "./views/InternetScreen/AddedMovies/AddedMovies";

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/search" component={Search} />
    <Route path="/channels/epgs" component={TVchannels} />
    <Route path="/channels/nowontv" component={NowOnTV} />
    <Route path="/channels/radios" component={Radio} />
    <Route path="/movies" component={Movies} />
    <Route path="/internetTV/addedMovies" component={AddedMovies} />
    <Route path="/internetTV/addTVchannels" component={AddTVchannels} />
    <Route path="/internetTV/m3u8" component={InternetTvChannels} />
    <Route path="/internetTV/addM3U8list" component={AddM3U8list} />
    <Route path="/internetTV/m3u8lists" component={M3U8lists} />
    <Route path="/news" component={News} />
    <Route path="/favorites/channel" component={ChannelsFavorites} />
    <Route path="/favorites/radios" component={RadiosFavorites} />
    <Route path="/favorites/movie" component={MoviesFavorites} />
    {/* <Route path='/settings/summary' component={Summary} /> */}
    <Route path="/settings/account" component={Account} />
    <Route path="/settings/languages" component={Languages} />
    <Route path="/settings/changepincode" component={PinCode} />
    <Route path="/settings/contact" component={Contact} />
    <Route path="/settings/help" component={Help} />
    <Redirect to="/" />
  </Switch>
);

export default Routes;
