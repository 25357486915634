// import dependencies
import React, { useEffect, useRef, useState } from "react";
import {
  AiFillCaretDown,
  AiFillCaretUp,
  AiOutlineReload,
} from "react-icons/ai";
import { FaRegPlayCircle } from "react-icons/fa";

// import helpers
import { handleTime } from "../../../../helpers/handleTime";
import { scrollIntoView } from "../../../../helpers/scrollIntoView";

// import css
import "./Channels.css";

const Channels = ({
  channels,
  channelsEpgs,
  activeChannel,
  currentEpg,
  playChannel,
  playEpg,
  activeCategory,
  hidden,
}) => {
  // state
  const [epgsDisplayed, setEpgsDisplayed] = useState(false);

  // refs
  const currentEpgRef = useRef(null);

  useEffect(() => {
    if (epgsDisplayed && currentEpgRef.current) {
      // currentEpgRef.current.scrollIntoView({ block: 'center' }); // behavior: 'smooth',
      scrollIntoView(currentEpgRef.current);
    }
  }, [epgsDisplayed]);

  const getChannelEpgs = () => {
    setEpgsDisplayed((p) => !p);
  };

  return (
    <div className={hidden ? "Hidden" : "Channels"}>
      <div className="currentEpg" onClick={getChannelEpgs}>
        {currentEpg ? (
          <>
            <div className="openEpgs">
              {epgsDisplayed ? <AiFillCaretUp /> : <AiFillCaretDown />}
              <p>EPG</p>
            </div>

            <div className="epgInfo">
              <div className="epgTime">
                {handleTime(currentEpg.start)} - {handleTime(currentEpg.end)}
              </div>
              <div className="epgName">{currentEpg.title}</div>
            </div>
          </>
        ) : (
          <>
            {activeChannel ? (
              <div className="openEpgs">
                {epgsDisplayed ? <AiFillCaretUp /> : <AiFillCaretDown />}
                <p>EPG</p>
              </div>
            ) : null}

            <div className="epgInfo">
              <p className="epgName">
                {activeChannel ? activeChannel.name : null}
              </p>
            </div>
          </>
        )}
      </div>

      {!epgsDisplayed ? (
        <div className="listOfChannels">
          {channels.map(
            (item, index) =>
              (item.category_id === parseInt(activeCategory) ||
                parseInt(activeCategory) === -1) && (
                <div
                  key={index.toString()}
                  onClick={playChannel(item)}
                  className="channel"
                >
                  <div className="channelInfo">
                    {item.stream_icon ? (
                      <img src={item.stream_icon} alt="Channel" />
                    ) : null}
                    <p>{item.name}</p>
                  </div>

                  <div className="channelButtons">
                    {item.tv_archive ? (
                      <AiOutlineReload className="catch-up" />
                    ) : null}

                    {activeChannel && item.id === activeChannel.id ? (
                      <FaRegPlayCircle />
                    ) : (
                      <FaRegPlayCircle className="LowOpacity" />
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      ) : (
        <div className="listOfEpgs">
          {channelsEpgs.map((item, index) => (
            <div
              key={index.toString()}
              ref={
                currentEpg && item.id === currentEpg.id ? currentEpgRef : null
              }
              onClick={playEpg(item)}
              className="singleEpg"
            >
              <div
                className={
                  "time" +
                  (currentEpg && item.id === currentEpg.id ? " active" : "")
                }
              >
                {handleTime(item.start)} - {handleTime(item.end)}
              </div>

              <div
                className={
                  "name" +
                  (currentEpg && item.id === currentEpg.id ? " active" : "")
                }
              >
                {item.title}
              </div>

              {currentEpg && item.id === currentEpg.id ? (
                <FaRegPlayCircle />
              ) : item.has_archive ? (
                <AiOutlineReload className="catch-up" />
              ) : null}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Channels;
