import React from "react";
import "./FloatingText.css";

const FloatingText = (props) => {
  return (
    <div className="FloatingTextContainer">
      <p>
        Ovo je test verzija na kome se nalazi odredjen broj kanala. Za više
        kanala kao i veći broj funkcionalnosti (catch-up, M3U8 liste, preko
        10.000 internet TV kanala, ...) posetite naš web sajt i registrujte se ili
        pozovite naš korisnički servis +436677985748
      </p>
    </div>
  );
};

export default FloatingText;
