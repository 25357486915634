import React, { useState, useEffect } from "react";
import "./CategoriesPopup.css";
import { connect } from "react-redux";
import EnterPin from "./EnterPin";
import { setCategories } from "../../../redux/actions";
import { Services } from "../../../services/Services";

const CategoriesPopup = ({ close, lockedCategories, setCategories }) => {
  const [popup, setPopup] = useState(false);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    let params = {
      filter: "",
      category: "",
      sort: "",
      favorite: "",
    };
    if (localStorage.jwtToken) {
      Services.getCategories(params).then((res) => {
        setCategories(res.data);
        let newArr = res.data.filter(
          (a) => !lockedCategories.some((b) => a.id === b)
        );
        setFiltered(newArr);
      });
    } else {
      Services.getDemoCategories(params).then((res) => {
        setCategories(res.data);
        let newArr = res.data.filter(
          (a) => !lockedCategories.some((b) => a.id === b)
        );
        setFiltered(newArr);
      });
    }
  }, [setCategories, setFiltered, lockedCategories]);

  const handleClick = (item) => {
    setPopup(item);
  };

  return (
    <>
      <div className="categoriesPopup_wrapper" onClick={close}>
        <div
          className="categoriesPopup_holder"
          onClick={(e) => e.stopPropagation()}
        >
          {filtered.length ? (
            filtered.map((item) => (
              <div
                key={item.id}
                className="item"
                onClick={() => handleClick(item)}
              >
                {item.category_name}
              </div>
            ))
          ) : (
            <p className="all_locked">All categories is locked.</p>
          )}
        </div>
      </div>
      {popup ? (
        <EnterPin
          data={popup}
          closeParent={close}
          closePopup={() => setPopup(false)}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { setCategories })(CategoriesPopup);
