import React, { useEffect, useState } from "react";
import Track from "./Track";
import { useSelector, useDispatch } from "react-redux";
import { Services } from "../../services/Services";
import { setLoader, setPlayerVisible, setFavoriteMovies } from "../../redux/actions";
import MoviesSection from "../../components/MoviesSection/MoviesSection";
import SingleMovie from "../Movies/SingleMovie/SingleMovie";

const MoviesFavorites = () => {
  const dispatch = useDispatch();
  const movies = useSelector((state) => state.moviesFavorites);
  const lang = useSelector((state) => state.selectedLanguage);
  const [chosenMovie, setChosenMovie] = useState();

  useEffect(() => {
    getAllMovies();
    // eslint-disable-next-line
  }, []);

  const getAllMovies = () => {
    dispatch(setLoader(true));
    Services.getFavorites()
      .then((res) => {
        dispatch(setFavoriteMovies(res.data.vods));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log("ERROR FAVORITE MOVIES", err);
        dispatch(setLoader(false));
      });
  };

  const handleClick = (src, poster) => {
    const channel = {};
    channel.tutorial = src;
    channel.stream_icon = poster;
    channel.is_movie = true;
    const channels = [];
    const epgs = [];
    dispatch(setPlayerVisible({ channel, channels, epgs }));
  };

  return (
    <div className="Movies">
      {chosenMovie && (
        <SingleMovie
          item={chosenMovie}
          close={() => setChosenMovie(null)}
          handleClick={handleClick}
          watchedLabel={lang?.movies?.watched_label}
          favLabel={lang?.internet_channels?.channels.favorite}
          addToFavLabel={lang?.internet_channels?.channels.add_to_favorite}
					movies={movies}
					fav
        />
      )}
      <Track />
      <MoviesSection
        handleClick={handleClick}
        setChosenMovie={setChosenMovie}
				result={movies}
				fav
      />
    </div>
  );
};

export default MoviesFavorites;
