import React, { useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { utility } from '../../helpers/Utility';
import { Services } from '../../services/Services';
import { setFavoritesChannels, setPlayerVisible, setPinCodeModal, setEpgs } from '../../redux/actions';
import PlayImg from '../../images/play.png';

const moment = require("moment-timezone");
const timezone = moment.tz.guess();

const FavoritePopup = ({ data, close, setFavoritesChannels, setPlayerVisible, setPinCodeModal, pincode, setEpgs }) => {
  const { channel } = data;
  useEffect(() => {
    if (pincode === 'success') openPlayer();
    // eslint-disable-next-line
	}, [pincode]);
	
	const lang = useSelector((state) => state.selectedLanguage);

  const fetchEpgs = useCallback(() => {
    Services.getEpgs({ channel_id: channel.id }).then(res => setEpgs(res.data));
    // eslint-disable-next-line
  }, [channel]);

  const removeFavorite = () => Services.removeFavorite({ channel_id: channel.id }).then(() => {
    Services.getFavorites({ favorite: '' }).then((res) => {
      setFavoritesChannels(res.data.channels);
      close();
    });
  });

  const handleClick = () => {
    let now = moment.tz(new Date(), utility.timezone).tz(timezone).format();
    let limitTime = moment.tz(localStorage.is_locked_time, utility.timezone).add(1, "hour").tz(timezone).format();
    if (localStorage.is_locked_time && limitTime < now) {
      localStorage.removeItem("is_locked_time");
      localStorage.setItem('all_locked', false);
      isLocked();
    } else {
       if(localStorage.all_locked === "true") openPlayer();
       else isLocked();
    }
  };

  const isLocked = () => {
    if(channel.locked) setPinCodeModal('opened');
    else openPlayer()
  };

  const openPlayer = () => {
    setPlayerVisible(data);
    fetchEpgs();
    close();
  };

  return (
    <div className='info_popup_wrapper favorite_modal' onClick={close}>
      <div className='info_popup_holder' onClick={(e) => e.stopPropagation()}>
        <div className='info_popup_img_holder'>
          <img src={PlayImg} alt='playbutton' onClick={handleClick} />
          <div className='times_icon' onClick={close} />
          <h2>{channel.name}</h2>
        </div>
        <div className='info_popup_body'>
          <div className='info_popup_body_img_holder'>
            <img src={channel.stream_icon} alt={channel.name} />
          </div>
          <div className='info_popup_text_btn'>
            <p></p>
          </div>
          <button className='active_nav' onClick={removeFavorite}>
            {lang?.internet_channels?.channels.favorite}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  locked: state.lockedCategories,
  pincode: state.pinCodeModal
})

export default connect(mapStateToProps, { setFavoritesChannels, setPlayerVisible, setPinCodeModal, setEpgs })(FavoritePopup);
