import React, { useState, useEffect } from "react";
import "./TVchannels.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { FaAngleDown, FaTimes } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { fetchData } from "../../../helpers/next_prev";
import Track from "../Track/Track";
import Menu from "./Menu/Menu";
import Grid from "./Grid/Grid";
import List from "./List/List";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { Services } from "../../../services/Services";
import { setSelectedLanguage } from "../../../redux/actions";

const TVchannels = ({ channels, activeChannel, lang }) => {
  const dispatch = useDispatch();
  const langauge = lang.channels && lang.channels.now_on_tv.filter;

  const [activeEpgs, setActiveEpgs] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [grid, setGrid] = useState(1);
  const [selectValue, setSelectValue] = useState("");
  const [selectVisible, setSelectVisible] = useState("");
  const [sortValue, setSortValue] = useState(
    lang.sort && lang.sort.placeholder
  );
  const [sortVisible, setSortVisible] = useState("");

  const langModalVisible = useSelector((state) => state.langModalVisible);

  const countries = useSelector((state) => state.channelsCountries);
  const [chosenCountry, setChosenCountry] = useState();
  const [chosenCountryChannels, setChosenCountryChannels] = useState(null);
  const [chosenAddToCategoryChannel, setChosenAddToCategoryChannel] =
    useState();
  const [addedCategories, setAddedCategories] = useState([]);

  useEffect(() => {
    if (!localStorage.jwtToken) {
      setSortValue(lang.sort.placeholder);
    }
  }, [lang]);

  useEffect(() => {
    if (!localStorage.jwtToken) {
      Services.getDemoLanguage().then((res) =>
        dispatch(setSelectedLanguage(res.data.language))
      );
    }
    // eslint-disable-next-line
  }, [langModalVisible]);

  useEffect(() => {
    if (langauge) setSelectValue(langauge.now);
  }, [langauge]);

  useEffect(() => {
    if (activeChannel.id && langauge)
      fetchData(
        selectValue,
        langauge,
        activeChannel,
        setActiveEpgs,
        setIsVisible
      );
  }, [selectValue, langauge, activeChannel]);

  useEffect(() => {
    if (chosenCountry) {
      Services.m3u8channelsForCountry(chosenCountry.id).then((res) => {
        setChosenCountryChannels(res.data.channels);
        console.log("chosen channels", res.data.channels);
      });
    }
  }, [chosenCountry]);

  const closeDropdowns = () => {
    if (selectVisible) setSelectVisible(false);
    if (sortVisible) setSortVisible(false);
  };

  const handleOpenCategories = (item) => {
    setChosenAddToCategoryChannel(item);
    Services.getChannelCategories(item.id).then((res) => {
      setAddedCategories(res.data);
    });
  };

  const handleCategoryClick = (item) => {
    if (item.is_added) {
      removeCategory(item.id);
    } else {
      addCategory(item.id);
    }
  };

  const addCategory = (category_id) => {
    Services.addCategory({
      channel_id: chosenAddToCategoryChannel.id,
      category_id,
    }).then(() => {
      Services.getChannelCategories(chosenAddToCategoryChannel.id).then(
        (res) => {
          setAddedCategories(res.data);
        }
      );
    });
  };

  const removeCategory = (category_id) => {
    Services.removeCategory({
      channel_id: chosenAddToCategoryChannel.id,
      category_id,
    }).then(() => {
      Services.getChannelCategories(chosenAddToCategoryChannel.id).then(
        (res) => {
          setAddedCategories(res.data);
        }
      );
    });
  };

  const content = localStorage.jwtToken ? (
    <div onClick={closeDropdowns}>
      <Track
        grid={grid}
        setGrid={setGrid}
        setChosenCountry={setChosenCountry}
        setChosenCountryChannels={setChosenCountryChannels}
        chosenCountryChannels={chosenCountryChannels}
      />
      <div className="tvchannels_style flex light_bg relative">
        <div className="channels_menu">
          <Menu
            setActiveEpgs={setActiveEpgs}
            countries={countries}
            chosenCountry={chosenCountry}
            setChosenCountry={setChosenCountry}
          />
        </div>
        {grid === 0 ? (
          <div className="epglists_wrapper">
            {isVisible ? (
              <div className="list_view_flex">
                <List
                  epgs={activeEpgs}
                  chosenCountryChannels={chosenCountryChannels}
                  lang={lang.sort}
                  noCountryChannels={lang?.channels?.no_channels_label}
                  openAddToCategories={(item) => handleOpenCategories(item)}
                />
                {chosenAddToCategoryChannel && (
                  <div className="add_to_category">
                    <div className="category_top">
                      <img
                        src={chosenAddToCategoryChannel.stream_icon}
                        alt="Channel"
                        className="channelIcon"
                      />
                      <FaTimes
                        onClick={() => {
                          setChosenAddToCategoryChannel(null);
                        }}
                        className="red"
                      />
                    </div>
                    <div className="categories">
                      {addedCategories.map((item) => (
                        <div
                          className="single_category"
                          key={item.id}
                          onClick={() => handleCategoryClick(item)}
                        >
                          <img
                            src={
                              item.is_added
                                ? item.icon_src_favorite
                                : item.icon_src_active
                            }
                            alt="Category"
                          />
                          <p className={item.is_added ? "active" : ""}>
                            {item.category_name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="wait">
                <AiOutlineLoading3Quarters />
              </div>
            )}
          </div>
        ) : (
          <div className="channels_stream_wrap">
            {!chosenCountry ? (
              <div className="channels_stream_wrapper_dropdowns">
                <div
                  className="dropdown"
                  onClick={() => setSelectVisible(!selectVisible)}
                >
                  <p>{selectValue ? selectValue : langauge && langauge.now}</p>
                  <FaAngleDown className={selectVisible && "rotate"} />
                  {selectVisible && (
                    <div className="dropdown_options">
                      <p onClick={(e) => setSelectValue(e.target.textContent)}>
                        {langauge.previous}
                      </p>
                      <p onClick={(e) => setSelectValue(e.target.textContent)}>
                        {langauge.now}
                      </p>
                      <p onClick={(e) => setSelectValue(e.target.textContent)}>
                        {langauge.next}
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="dropdown"
                  onClick={() => setSortVisible(!sortVisible)}
                >
                  <p>{sortValue}</p>
                  <FaAngleDown className={sortVisible && "rotate"} />
                  {sortVisible && (
                    <div className="dropdown_options">
                      <p onClick={(e) => setSortValue(e.target.textContent)}>
                        {lang.sort.placeholder}
                      </p>
                      <p onClick={(e) => setSortValue(e.target.textContent)}>
                        {lang.sort.asc.name}
                      </p>
                      <p onClick={(e) => setSortValue(e.target.textContent)}>
                        {lang.sort.desc.name}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div style={{ height: 70 }} />
            )}
            {isVisible ? (
              <Grid
                epgs={activeEpgs}
                sortValue={sortValue}
                now={langauge.now}
                lang={lang.sort}
                noCountryChannels={lang?.channels?.no_channels_label}
                selectValue={selectValue}
                chosenCountryChannels={chosenCountryChannels}
              />
            ) : (
              <div className="wait">
                <AiOutlineLoading3Quarters />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <>
      <Navbar />
      <div className="content">
        <div onClick={closeDropdowns}>
          <Track grid={grid} setGrid={setGrid} />
          <div className="tvchannels_style flex light_bg relative">
            <div className="channels_menu">
              <Menu setActiveEpgs={setActiveEpgs} />
            </div>
            {grid === 0 ? (
              <div className="epglists_wrapper">
                {isVisible ? (
                  <List epgs={activeEpgs} />
                ) : (
                  <div className="wait">
                    <AiOutlineLoading3Quarters />
                  </div>
                )}
              </div>
            ) : (
              <div className="channels_stream_wrap">
                <div className="channels_stream_wrapper_dropdowns">
                  <div
                    className="dropdown"
                    onClick={() => setSelectVisible(!selectVisible)}
                  >
                    <p>
                      {selectValue ? selectValue : langauge && langauge.now}
                    </p>
                    <FaAngleDown className={selectVisible && "rotate"} />
                    {selectVisible && (
                      <div className="dropdown_options">
                        <p
                          onClick={(e) => setSelectValue(e.target.textContent)}
                        >
                          {langauge.previous}
                        </p>
                        <p
                          onClick={(e) => setSelectValue(e.target.textContent)}
                        >
                          {langauge.now}
                        </p>
                        <p
                          onClick={(e) => setSelectValue(e.target.textContent)}
                        >
                          {langauge.next}
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    className="dropdown"
                    onClick={() => setSortVisible(!sortVisible)}
                  >
                    <p>{sortValue}</p>
                    <FaAngleDown className={sortVisible && "rotate"} />
                    {sortVisible && (
                      <div className="dropdown_options">
                        <p onClick={(e) => setSortValue(e.target.textContent)}>
                          {lang.sort.placeholder}
                        </p>
                        <p onClick={(e) => setSortValue(e.target.textContent)}>
                          {lang.sort.asc.name}
                        </p>
                        <p onClick={(e) => setSortValue(e.target.textContent)}>
                          {lang.sort.desc.name}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {isVisible ? (
                  <Grid
                    epgs={activeEpgs}
                    sortValue={sortValue}
                    now={langauge.now}
                    lang={lang.sort}
                    selectValue={selectValue}
                  />
                ) : (
                  <div className="wait">
                    <AiOutlineLoading3Quarters />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>{" "}
    </>
  );

  return content;
};

const mapStateToProps = (state) => ({
  channels: state.channels,
  activeChannel: state.tvChannelsActiveChannel,
  lang: state.selectedLanguage,
});

export default connect(mapStateToProps)(TVchannels);
