import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import { play_or_prev, isCatchup } from "../../../helpers/icons";
import { utility } from "../../../helpers/Utility";
import { setPlayerVisible, setInfoModal } from "../../../redux/actions";
import Play from "../../../images/play.png";
import Rewind from "../../../images/rewind.png";
import Catchup from "../../../images/player_icons/restart1.png";
import Marker from "../../../images/marker.png";
import InfoImg from "../../../images/info.png";
import "./BottomSlider.css";

var moment = require("moment-timezone");
var timezone = moment.tz.guess();

const BottomSlider = ({
  data,
  epgs,
  setPlayerVisible,
  setInfoModal,
  nextEpisode,
  lang,
}) => {
  // const [currentEpg, setCurrentEpg] = useState(0);
  const inputEl = useRef(null);
  const { channels, channel, epg } = data;

  useEffect(() => {
    console.log("change");
  }, [data, epgs]);

  useEffect(() => {
    if (nextEpisode) {
      handleClick(nextEpisode);
    }
    // eslint-disable-next-line
  }, [nextEpisode]);

  // useEffect(() => {
  //   if (channel.current_epg)
  //     setCurrentEpg(
  //       epgs.map((item) => item.id).indexOf(channel.current_epg.id)
  //     );
  // }, [channels, channel, epgs]);

  useEffect(() => {
    new Swiper(".swiper-container", {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      speed: 200,
      spaceBetween: 10,
      direction: "horizontal",
      followFinger: true,
      freeMode: true,
      // shortSwipes: true,
      // mousewheel: {
      //   releaseOnEdges: true,
      // },
      initialSlide: epgs
        .map((item) => item && item.id)
        .indexOf(channel.current_epg && channel.current_epg.id),
      breakpoints: {
        500: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
        1150: {
          slidesPerView: 5,
          slidesPerGroup: 5,
        },
        1500: {
          slidesPerView: 6,
          slidesPerGroup: 6,
        },
      },
    });
  }, [epgs, channel]);

  const renderIcons = (epg) => {
    let icons = play_or_prev(epg, channel);
    let catchup = isCatchup(channel);

    if (icons === 1) {
      return (
        <>
          <img src={Play} onClick={() => handleClick(epg, false)} alt='' />
          {catchup && (
            <img src={Catchup} onClick={() => handleClick(epg, true)} alt='' />
          )}
        </>
      );
    } else if (icons === 0)
      return (
        <img src={Rewind} onClick={() => handleClick(epg, false)} alt='' />
      );
  };

  const renderMarker = (epg) => {
    if (play_or_prev(epg, channel) === 1)
      return (
        <div className='marker_center_wrap'>
          <div className='holder'>
            <img src={Marker} alt='' className='marker_center' />
            <div className='line' />
          </div>
        </div>
      );
  };

  const renderImage = (item) => {
    if (item.epg_img) {
      return (
        <div className='image'>
          <img src={item.epg_img} />
        </div>
      );
    } else {
      return (
        <div className='no_image'>
          <img src={channel.stream_icon} />
        </div>
      );
    }
  };

  const handleClick = (epg, fromStart) =>
    setPlayerVisible({ channels, channel, epg, fromStart });

  return (
    <div className='bottom_slider_relative'>
      <div className='bottom_slider'>
        <div className='swiper-container'>
          <div className='swiper-wrapper' ref={inputEl}>
            {epgs.map((item, index) => (
              <div
                key={index}
                id={`bottom_slider_${index}`}
                className={
                  epg && epg.id === item.id
                    ? "bottom_slide active swiper-slide"
                    : "bottom_slide swiper-slide"
                }>
                <div className='bottom_slide_holder'>
                  {renderImage(item)}
                  <div className='images'>
                    {renderIcons(item)}
                    <img
                      src={InfoImg}
                      alt='info'
                      onClick={() => setInfoModal({ channel, epg: item })}
                    />
                  </div>
                  {renderMarker(item)}
                  <h3>
                    {item.title.length > 25
                      ? item.title.substring(0, 25) + "..."
                      : item.title}
                  </h3>
                  <p>
                    {moment
                      .tz(item.start, utility.timezone)
                      .tz(timezone)
                      .calendar(null, {
                        sameDay: "[" + lang.channels.epg_today_label + "]",
                        nextDay: "[" + lang.channels.epg_tomorrow_label + "]",
                        nextWeek: "DD/MM/YYYY",
                        lastDay: "[" + lang.channels.epg_yesterday_label + "]",
                        lastWeek: "DD/MM/YYYY",
                        sameElse: "DD/MM/YYYY",
                      })}{" "}
                    |{" "}
                    {moment
                      .tz(item.start, utility.timezone)
                      .tz(timezone)
                      .format("HH:mm")}{" "}
                    -{" "}
                    {moment
                      .tz(item.end, utility.timezone)
                      .tz(timezone)
                      .format("HH:mm")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='swiper-button-prev' />
      <div className='swiper-button-next' />
    </div>
  );
};

const mapStateToProps = (state) => ({
  epgs: state.epgs,
  lang: state.selectedLanguage,
});

export default connect(mapStateToProps, { setPlayerVisible, setInfoModal })(
  React.memo(BottomSlider)
);
