// import dependencies
import moment from 'moment-timezone';

// config
const EUROPE_LONDON = 'UTC';
const timezone = moment.tz.guess();
const cutText = (text, from, to) => text.substring(from, to);

export const handleTime = time =>
  cutText(moment.tz(time, EUROPE_LONDON).tz(timezone).format(), 11, 16);
