// import dependencies
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaRegClock } from "react-icons/fa";

// import actions
import { setNewsItem } from "../../../../redux/actions";

// import css
import "./NewsFeed.css";

const NewsFeed = ({ newsFeed = [] }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const readMore = item => () => {
    localStorage.setItem("news_item", JSON.stringify(item));
    dispatch(setNewsItem(item));
    history.push("/news/item");
  };

  return (
    <div className="news-feed-container">
      {
        newsFeed.map((item, index) => (
          <div
            key={`news-${index}`}
            className="news-item"
            onClick={readMore(item)}
          >
            {item && item.icon && <img src={item.icon} className="news-item-image" alt="news" />}
            {item && item.title && <p className="news-item-title">{item.title}</p>}
            <div className="news-item-row">
              <div className="news-item-info">
                <FaRegClock />
                {item && item.date && <p className="news-item-date">{item.date}</p>}
              </div>

              {item && item.logo && <img src={item.logo} className="news-item-logo" alt="newspaper" />}
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default NewsFeed;