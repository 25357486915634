import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { FaAngleRight } from "react-icons/fa";
import { Services } from "../../services/Services";
import { utility } from "../../helpers/Utility";
import { play_or_prev } from "../../helpers/icons";
import {
  setInfoModal,
  setPlayerVisible,
  setEpgs,
  setPinCodeModal,
} from "../../redux/actions";
import Play from "../../images/play.png";
import Rewind from "../../images/rewind.png";
import NoImg from "../../images/mini_logo.png";
import "./Grid.css";

const moment = require("moment-timezone");
const timezone = moment.tz.guess();

const Grid = ({
  item,
  play_btn,
  channels,
  setInfoModal,
  setPlayerVisible,
  setEpgs,
  setPinCodeModal,
  lockedChannel,
  setLockedChannel,
  openModalTry,
  setOpenModalTry,
}) => {
  const { channel, epg } = item;

  const pincode = useSelector((state) => state.pinCodeModal);
  const renderIcons = () => {
    let icons = play_or_prev(epg, channel);

    if (icons === 1)
      return (
        <img src={Play} onClick={handleClick} className='play_icon' alt='' />
      );
    else if (icons === 0)
      return (
        <img src={Rewind} onClick={handleClick} className='play_icon' alt='' />
      );
  };

  // const bg = () =>
  //   epg.epg_img
  //     ? { background: `url(${epg.epg_img})` }
  //     : { background: `url(${NoImg})` };

  const renderImage = (item) => {
    if (item.epg_img) {
      return (
        <div className='image'>
          <img src={item.epg_img} />
        </div>
      );
    } else {
      return (
        <div className='no_image'>
          <img src={channel.stream_icon} />
        </div>
      );
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (channel.locked) {
      setPinCodeModal("opened");
      setLockedChannel(item);
    } else {
      playChannel();
    }
  };

  const playChannel = () => {
    const itemToPlay = lockedChannel ? lockedChannel : item;
    setPlayerVisible({
      channels,
      channel: itemToPlay.channel,
      epg: itemToPlay?.epg,
    });
    if (localStorage.jwtToken) {
      Services.getEpgs({ channel_id: itemToPlay?.channel.id }).then((res) =>
        setEpgs(res.data)
      );
    } else {
      Services.getDemoEpgs({ channel_id: itemToPlay?.channel.id }).then((res) =>
        setEpgs(res.data)
      );
    }
  };

  const handleModalOpen = () => {
    if (channel.locked) {
      setPinCodeModal("opened");
      setLockedChannel(item);
      setOpenModalTry(true);
    } else {
      openModal();
    }
  };

  const openModal = () => {
    const itemToOpen = lockedChannel ? lockedChannel : item;
    setInfoModal({ channel: itemToOpen.channel, epg: itemToOpen.epg });
    setOpenModalTry(false);
    if (localStorage.jwtToken) {
      Services.getEpgs({ channel_id: itemToOpen?.channel.id }).then((res) =>
        setEpgs(res.data)
      );
    } else {
      Services.getDemoEpgs({ channel_id: itemToOpen?.channel.id }).then((res) =>
        setEpgs(res.data)
      );
    }
  };

  useEffect(() => {
    if (pincode === "success") {
      if (openModalTry) {
        openModal();
      } else {
        playChannel();
      }
    }
    // eslint-disable-next-line
  }, [pincode]);

  return (
    <div className='grid_item'>
      <div
        className='grid_item_top'
        onClick={handleModalOpen}
        // style={bg()}
      >
        {renderImage(epg)}
        {play_btn ? (
          <img src={Play} onClick={handleClick} alt='' className='play_icon' />
        ) : (
          renderIcons()
        )}
      </div>
      <div className='grid_item_bottom'>
        <div className='proggres_bar'>
          <div
            style={{ width: utility.progressBarWidth(epg) }}
            className='buffer'
          />
        </div>
        <h2 className='epg_title'>
          {epg.title.length > 10
            ? epg.title.substring(0, 10) + "..."
            : epg.title}
        </h2>
        <h3 className='epg_date'>
          {moment.tz(epg.start, utility.timezone).tz(timezone).format("HH:mm")}-
          {moment.tz(epg.end, utility.timezone).tz(timezone).format("HH:mm")}
        </h3>
        {channel.stream_icon && (
          <img src={channel.stream_icon} alt='' className='stream_icon' />
        )}
        <div className='epg_desc'>
          <p>
            {epg.description && epg.description.length > 50
              ? epg.description.substring(0, 50) + "..."
              : epg.description}
          </p>
          <span>
            <FaAngleRight onClick={openModal} />
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  channels: state.channels,
});

export default connect(mapStateToProps, {
  setInfoModal,
  setPlayerVisible,
  setEpgs,
  setPinCodeModal,
})(Grid);
